<mat-toolbar class="main-header mat-elevation-z4" color="primary">
  <ng-container *ngIf="(isLoggedIn)">
    <mat-icon style="cursor:pointer" (click)="drawer.toggle()" aria-label="Side nav toggle icon">menu</mat-icon>
    <mat-toolbar style="width: 200px;background-color: white;padding: 0;cursor:pointer;" class="secondary-theme">
      <img class="col-12" (error)="onImageError()" [src]="logo" alt="logo" routerLink='' />
    </mat-toolbar>
  </ng-container>
  <img *ngIf="(!isLoggedIn)" class="col-xs-3 col-md-2 fs-margin-1 img-fluid h-100" src="assets/img/logo.png"
    alt="logo" />

  <div class="nav-links">
    <button class="home-button" type="button" *ngIf="(isLoggedIn)" aria-label="Go Home" mat-icon-button routerLink=''>
      <mat-icon style="color: #ffa000" aria-hidden="false" aria-label="Example home icon"
        fontIcon="home"></mat-icon></button>
    <ul class="row top_nav_link">
      <li *ngIf='isLoggedIn'>
        <button mat-button [matMenuTriggerFor]="menu_app">
          <span class="account_icon account_circle">{{getName()}}</span>
        </button>
        <mat-menu #menu_app="matMenu">
          <button mat-menu-item (click)='logout()'>
            <mat-icon>exit_to_app</mat-icon>
            Sign out
          </button>
          <button mat-menu-item routerLink='/changepwd'>
            <mat-icon>lock</mat-icon>
            Change Password
          </button>

        </mat-menu>
      </li>
    </ul>
  </div>
</mat-toolbar>
<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav vertical-nav secondary-theme" fixedInViewport
    [attr.role]="((isHandset$ | async) || !isLoggedIn) ? 'dialog' : 'navigation'"
    [mode]="((isHandset$ | async) || !isLoggedIn) ? 'over' : 'side'"
    [opened]="((isHandset$ | async) || !isLoggedIn) === false">
    <div class="mat-card">
      <mat-accordion>
        <ng-container *ngFor='let category of menu; let parentIndex = index;'>
          <mat-expansion-panel *ngIf="category.enabled" [expanded]="togFunc(parentIndex)" hideToggle="false"
            (opened)="panelOpenState[parentIndex] = true" (closed)="panelOpenState[parentIndex] = false"
            class="mat-elevation-z0">
            <mat-expansion-panel-header class="mat-header">

              <mat-panel-title>
                <div class="nav-heading">
                  <mat-icon class="dashboard-menu-icon">
                    {{ category.icon }}
                  </mat-icon>
                  <span class="menu-title">{{ category.header }}</span>
                  <div *ngIf="displayCopyrighrt(parentIndex)">

                  </div>
                </div>
              </mat-panel-title>

            </mat-expansion-panel-header>
            <mat-nav-list>
              <mat-list-item routerLinkActive="active"
                *ngFor="let subCat of category.child | accessRules : category.header; let childIndex = index;">
                <a [routerLink]="[subCat.route]" [queryParams]="{ reportName: subCat.key }" matLine
                  id="nav-{{parentIndex}}-{{childIndex}}" (click)="navigate(subCat)">{{ subCat.name }}
                  <button *ngIf="!subCat.authorised && ts(subCat.name)" mat-raised-button color="primary"
                    class="btn btn-primary" (click)="login(subCat.authorise_url)" style="left: 20px;">
                    authorize
                  </button>
                  <span *ngIf="subCat.authorised && ts(subCat.name)"
                    style="color: green; font-size: 20px; float: right; font-weight: bold;">
                    &#x2713;
                  </span>
                </a>
              </mat-list-item>
            </mat-nav-list>
          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>
    </div>
    <footer class="h-50" *ngIf=" displayFlag">
      <p class="copyright">Copyright © {{year}} Flowsmart.</p>
    </footer>
  </mat-sidenav>

  <mat-sidenav-content>

    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>