import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { AuthenticationService } from "../services/authentication.service";
import { Router } from "@angular/router";
import { LoaderService } from "../services/loader.service";
import { map, catchError, finalize } from "rxjs/operators";
/**
 * This interceptor automatically adds the token header needed by our backend API if such token is present
 * in the current state of the application.
 */
@Injectable({
  providedIn: "root",
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    public loaderService: LoaderService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // add auth header with jwt if user is logged in and request is to api url
    if (
      ![
        "reports/sales/visualize/filters",
        "reports/sales/visualize",
        "reports/sales/visualize/apply/filter",
        "reports/sales/scorecard/apply/filter",
        "reports/sales/scorecard",
        "reports/chartjs/Buffer_Trend_Tech",
        "reports/grid/Chronic_Items",
        "reports/chartjs/Inventory_Health",
        "reports/get/Admin",
        "flowsmart-cms/berrylush/nav/Admin.json",
        "/material/forecast/result",
        "/material/forecast/scenario-status"
      ].some((val) => req.url.match(val))
    ) {
      this.loaderService.show();
    }
    const currentUser = this.authenticationService.getTokenDetails();
    const isLoggedIn = currentUser && currentUser.token;
    if (isLoggedIn) {
      if (!req.url.includes("storage.googleapis.com")) {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${currentUser.token}`,
          },
        });
      }
    }
    if (isLoggedIn == undefined) {
      this.authenticationService.logout();
      this.router.navigate(["/login"]);
    }
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.loaderService.hide();
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this.loaderService.hide();
        if (error && error.status == 401) {
          this.loaderService.hide();
          this.authenticationService.logout();
          this.router.navigate(["/login"]);
        }
        return throwError(error);
      })
    );
  }
}
