<div class="filters d-flex">
  <ng-container *ngIf="isDateFilter" [formGroup]="dateRangeForm">
    <mat-form-field>
      <mat-label>Enter a date range</mat-label>
      <div style="display: flex; justify-content: space-between'">
        <mat-date-range-input [max]="endDate" [rangePicker]="picker">
          <input formControlName="startDate" matStartDate placeholder="Start date" />
          <input formControlName="endDate" matEndDate placeholder="End date" />
        </mat-date-range-input>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-datepicker-toggle style="width: 1.5rem; margin: -10px 0" matIconSuffix
          [for]="picker"></mat-datepicker-toggle>
      </div>
      <!-- <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint> -->
    </mat-form-field>
    <!-- <mat-form-field>
      <mat-label>Frequency</mat-label>
      <mat-select #matSelect formControlName="frequency">
        <mat-option value="DAY">DAY</mat-option>
        <mat-option value="WEEK">WEEK</mat-option>
        <mat-option value="MONTH">MONTH</mat-option>
      </mat-select>
    </mat-form-field> -->
  </ng-container>
  <mat-form-field [formGroup]="filterGroup" *ngFor="let filter of filterDataList">
    <mat-label>{{filter.label}}</mat-label>
    <mat-select [formControlName]="filter.code" #matSelect [multiple]="filter.condition == 'OR' ? true : false"
      panelWidth="auto">
      <mat-select-filter *ngIf="filter.values?.length>5" [array]="filter.values"
        (filteredReturn)="filteredList[filter.code]=$event">
      </mat-select-filter>
      <mat-icon class="close-button" (click)="matSelect.close()" color="primary">close</mat-icon>
      <mat-option (click)="toggleSelectAll(filter.code)" [value]="0">All</mat-option>
      <mat-option *ngFor="let variable of filteredList[filter.code]" value="{{ variable }}"
        (click)="selectSingle(filter.code)">
        {{variable}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div><button (click)="applyFilters()" class="mat-button mat-primary mat-raised-button">
    <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="filter_alt"></mat-icon>
    Apply Filters
  </button></div>