<div class="fs-section-block">
  <!-- <span class="mat-headline">Availability Monitoring</span>
  <p class="fs-hint-text">Maintaining System Health and Efficiency</p> -->
  <app-header-content-help-text [Heading]="'Availability Monitoring'" [helpText]="'Maintaining System Health and Efficiency'"></app-header-content-help-text>
  <app-dashboard-chartjs-grids-organsim *ngIf="dashboardReports"
    [dashboardCharts]="dashboardReports"></app-dashboard-chartjs-grids-organsim>
</div>
<div class="fs-section-block">
  <app-chatjs-organism reportName="Buffer_Trend_Tech"></app-chatjs-organism>
</div>
