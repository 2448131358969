<div class="fs-padding">
  <div class="fs-section-block fs-margin-0 header-background ">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <span class="mat-headline">Product Promotions Planner</span>
        <p class="fs-hint-text">Curated product selection for discounted sales to free up capital</p>
      </div>
    </div>
  </div>
</div>

<div *ngIf="dynamic_headers?.length>0 && data">
  <div class="tool-bar">
  <mat-slide-toggle [(ngModel)]="isChecked" (change)="showAllData()">Show all products</mat-slide-toggle>
  </div>
  <app-syncfusion-grid reportName="promotions-planner" [dynamic_headers]="dynamic_headers" [count]="count" [data]="data" [freezeColumns]="4"
    *ngIf="tableDataFetched" (dataEmitForPOPUP)="handlePOPUP($event)"></app-syncfusion-grid>
</div>

<app-popup-reuse-organsim *ngIf="fieldData && headerData" [showPOPUP]="visiblePOPUP" [fieldData]="fieldData"
  [headerData]="headerData" (POPUPFeedback)="visiblePOPUP=$event"></app-popup-reuse-organsim>

