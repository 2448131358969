import { Injectable } from "@angular/core";
import { api } from "../properties/endpoints";

@Injectable({
  providedIn: "root",
})
export class GetLogoService {
  clientLogoEndPointURL: string;
  constructor() {
    let baseEndPoint = "https://storage.googleapis.com/flowsmart-logo/";
    let api_endpoint = api.host;
    this.clientLogoEndPointURL =
      baseEndPoint +
      api_endpoint
        .replace(".flowsmart.ai", "")
        .substring(api_endpoint.lastIndexOf("/") + 1)
        .replace("api", "")
        .replace(".", "")
        .concat(".jpg");
  }
}
