export const ChangePwd = [
  {
      identifier: 'currentpassword',
      label: 'Current Password',
      dataType: 'Encrypt',
      optional: false
  },
  {
      identifier: 'newpassword',
      label: 'New Password',
      dataType: 'Encrypt',
      optional: false
  },
  {
      identifier: 'confirmnewpassword',
      label: 'Confirm New Password',
      dataType: 'Encrypt',
      optional: false
  }
];
