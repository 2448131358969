import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FilterUiService } from 'src/app/services/filter-ui.service';
import { FilterBayService } from 'src/app/services/filter-bay.service';
import { DatePipe } from "@angular/common";


@Component({
  selector: 'app-material-master-filter-molecule',
  templateUrl: './material-master-filter-molecule.component.html',
  styleUrls: ['./material-master-filter-molecule.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MaterialMasterFilterMoleculeComponent implements OnInit, OnChanges {
  filterGroup: FormGroup;
  @Input() isDateFilter: boolean = false;
  @Input() defaultDateRange: {startDate: String,endDate: String} = null;
  @Input() changesDetected: boolean;
  @Input() productTypes: [];
  @Input() analyticsFilter: boolean = false;
  @Output() emitAppliedFilters = new EventEmitter<{}>();
  filterDataList
  endDate = new Date()
  addFilters: any;
  public datePipe = new DatePipe("en-US");
  dateRangeForm;
  public filteredList = {}
  public openState = {}

  selectedOptions: { [key: string]: string[] } = {};
  constructor(private filterService: FilterUiService, private filterBayService: FilterBayService) { }
  ngOnChanges(changes: SimpleChanges): void {
    this.filterBayService.getMaterialMasterFilters.subscribe(data => {
      this.closeFilter(data)
    })
  }
  ngOnInit(): void {
   this.dateRangeForm = !this.defaultDateRange ? new FormGroup({
      startDate: new FormControl(null),
      endDate: new FormControl(null)
    }):new FormGroup({
      startDate: new FormControl(this.defaultDateRange.startDate),
      endDate: new FormControl(this.defaultDateRange.endDate)
      }) ;
    const fieldControl = {}
    this.filterService.getFilters(this.productTypes, this.analyticsFilter).subscribe(res => {
      this.filterDataList = res['filterDataList'].filter(filter => {
        return filter.values && filter.values.length > 0;
      });
      this.filterDataList.map(field => {
        const control = field.code;
        this.filteredList[field.code] = field.values.slice();
        fieldControl[control] = new FormControl();
      })
    }, (error) => { }, () => {
      this.filterGroup = new FormGroup(fieldControl);

    })
  }
  closeFilter(i) {
    if (i === "DateRange") {
      const dateRange = ["startDate", "endDate"];
      dateRange.filter((date) => {
        const control = this.dateRangeForm.get(date);
        control.patchValue(null);
      });
      for (const key in this.addFilters) {
        if (key === i) {
          delete this.addFilters[key];
        }
      }
      this.applyFilters();
    } else {
      const getControlByname = this.filterGroup.get(i);
      if (getControlByname.value != null && getControlByname.value.length > 0) {
        this.filterGroup.get(i).patchValue(null);
      }
      this.applyFilters();
    }

  }
  applyFilters() {
    const buildPayload: any = {filters: {}};
    for (const key in this.filterGroup.value) {
      if (this.filterGroup.value[key] && this.filterGroup.value[key].length > 0) {
        buildPayload.filters[key] = this.selectedOptions[key]
      }
    }
    const startDate =
      this.dateRangeForm.get("startDate").value != null
        ? this.datePipe.transform(
          new Date(this.dateRangeForm.get("startDate").value),
          "yyyy-MM-dd"
        )
        : null;
    const endDate =
      this.dateRangeForm.get("endDate").value != null
        ? this.datePipe.transform(
          new Date(this.dateRangeForm.get("endDate").value),
          "yyyy-MM-dd"
        )
        : null;
    if (startDate != null) {
      buildPayload.startDate = startDate;
    }
    if (endDate != null) {
      buildPayload.endDate = endDate;
    }
    if (startDate != null && endDate == null) {
      buildPayload.DateRange = `from ${startDate}`;
    } else if (startDate == null && endDate != null) {
      buildPayload.DateRange = `till ${endDate}`;
    } else if (startDate != null && endDate != null) {
      buildPayload.DateRange = `${startDate} - ${endDate}`;
    }

    this.emitAppliedFilters.emit(buildPayload);
  }
  toggleSelectAll(filterCode: string) {
    const allOptions = this.filterGroup.get(filterCode).value.filter((v) => v !== 0) || [];

    const allOptionValues: string[] =
      this.filterDataList.find((filter) => filter.code === filterCode)
        ?.values || [];
    const convertToString = allOptionValues.map(value => value.toString());
    if (allOptions.length === convertToString.length) {
      this.filterGroup.get(filterCode).setValue([]);
    } else {
      this.filterGroup.get(filterCode).setValue([...convertToString, 0]);
    }
    this.selectedOptions[filterCode] = this.filterGroup
      .get(filterCode)
      .value.filter((v) => v != 0);
  }
  selectSingle(filterCode) {
    const getFilterValues = this.filterGroup
      .get(filterCode)
      .value.filter((ignoreZero) => ignoreZero !== 0);
    const allOptionValues =
      this.filterDataList.find((filter) => filter.code === filterCode)
        ?.values || [];
    if (allOptionValues.length == getFilterValues.length) {
      this.filterGroup
        .get(filterCode)
        .setValue([...this.filterGroup.get(filterCode).value, 0]);
    } else {
      this.filterGroup
        .get(filterCode)
        .setValue([
          ...this.filterGroup.get(filterCode).value.filter((v) => v != 0),
        ]);
    }
    this.selectedOptions[filterCode] = this.filterGroup
      .get(filterCode)
      .value.filter((v) => v != 0);
  }
}
