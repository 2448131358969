import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FilterBayService } from 'src/app/services/filter-bay.service';

@Component({
  selector: 'app-sales-visualization-molecule',
  templateUrl: './sales-visualization-molecule.component.html',
  styleUrls: ['./sales-visualization-molecule.component.scss']
})
export class SalesVisualizationMoleculeComponent implements OnInit, OnChanges {
  visualizationData: any;
  addFlag: boolean;
  formData: any;
  dateRange: any;
  length: number;
  filterResponse: boolean;
  filterOnScrorCard: boolean
  getFilters: any;
  allFilters: any;
  errorInVisualization: boolean;
  errorInScoreCard: boolean;
  @Input() changesFromParent
  passDataToScoreCard: {};
  constructor(private filterbayservice: FilterBayService) { }
  ngOnChanges() {
    if (this.changesFromParent) {
      this.visualizationData = null;
      this.filterResponse = false;
      this.changesFromParent.frequency = "DAY";
      this.filterbayservice.applyFilterOnVisualizer(this.changesFromParent).subscribe(res => {
        if (res['chartJSResponseData'] != null && res['chartJSResponseData'] != "") {
          this.visualizationData = res['chartJSResponseData']
          const options = {
            "scales": {
              "y": {
                "type": "linear",
                "position": "left",
                "title": {
                  "display": true,
                  "text": "Revenue/GMV",
                  "font": {
                    "weight": "bold"
                  }
                }
              },
              "secondary-y-axis": {
                "type": "linear",
                "position": "right",
                "title": {
                  "display": true,
                  "text": "Qty Sold",
                  "font": {
                    "weight": "bold"
                  }
                }
              },
            }
          }
          this.visualizationData.options = options;
          this.visualizationData.yAxisID = "secondary-y-axis";
          this.visualizationData.data.datasets.map((item) => {
            if (item.type == 'bar') {
              item.yAxisID = "secondary-y-axis";
            }
          })
          setTimeout(() => {
            this.filterResponse = true;
          })
        } else {
          this.filterResponse = true;
        }
      }, (error) => {
        this.filterResponse = true;
        this.errorInVisualization = true;
      });
    }

  }
  ngOnInit(): void {
    this.filterResponse = false;
    if (!this.changesFromParent) {
      this.changesFromParent = { frequency: "DAY" };
    } else {
      if (!this.changesFromParent.frequency) {
        this.changesFromParent.frequency = "DAY";
      }
    }
    this.filterbayservice.applyFilterOnVisualizer(this.changesFromParent).subscribe(res => {
      if (res) {
        this.visualizationData = res['chartJSResponseData'];
        const options = {
          "scales": {
            "y": {
              "type": "linear",
              "position": "left",
              "title": {
                "display": true,
                "text": "Revenue/GMV",
                "font": {
                  "weight": "bold"
                }
              }
            },
            "secondary-y-axis": {
              "type": "linear",
              "position": "right",
              "title": {
                "display": true,
                "text": "Qty Sold",
                "font": {
                  "weight": "bold"
                }
              }
            },
          }
        }
        this.visualizationData.options = options;
        this.visualizationData.yAxisID = "secondary-y-axis";
        this.visualizationData.data.datasets.map((item) => {
          if (item.type == 'bar') {
            item.yAxisID = "secondary-y-axis";
          }
        })
        const dateRangeResult = [res['startDateRange'], res['endDateRange']]
        this.dateRange = dateRangeResult;
        this.length = Object.keys(this.visualizationData).length
        this.filterResponse = true;
      }
    })
  }

}
