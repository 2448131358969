import { Component, OnInit } from '@angular/core';
import { Subject , timer , Subscription } from 'rxjs';
import {map} from 'rxjs/operators';
import { LoaderService } from '../../../services/loader.service';


@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit  {
  color = 'loadColor';
  background = '#FFA000';
  mode = 'determinate';
  width = 0;
  value = 50;
  isLoading: Subject<boolean> = this.loaderService.isLoading;
  subscription = Subscription.EMPTY


  constructor(private loaderService: LoaderService) { }

  ngOnInit() {
    this.isLoading.subscribe(isLoading => {
      if (isLoading) {
        // Show the progress bar and start loading
        this.width = 0; // Set the initial value to 0
        this.startLoading(); // Start loading
      } else {
        // this.width= 0;
        // Hide the progress bar and stop loading
        this.subscription.unsubscribe();
      }
    });
  }

  startLoading() {
    let counter = 0;
    this.subscription =  timer(0, 1000).pipe(
      map(() => {
        counter+=100;
        return counter;
      })
    ).subscribe(value => {
      this.width = value;
    });

  }
}
