export const TabContent = {
  "SKU Hierarchy": [
    "priority1",
    "priority2",
    "priority3",
    "priority4",
    "targetSafetyStock",
    "minTargetSafetyStock"
  ],
  "Category": [
    "Buyer",
    "Norm-Location"
  ],
  "Replenishment": [
    "packSize",
    "replenishmentLeadTimeDays",
    "retentionPercentage",
    "replinshmentLocations"
  ],
  "Buffer Management": [
    "dynamicManagement",
    "redCheckPeriod",
    "greenCheckPeriod"
  ],
  "Phase In Phase Out": [
    "Phaseout",
    "autoRampUp",
    "PhaseInTransition"
  ],
  "Seasonality": [
    "seasonTargetSafetyStock",
    "seasonStartDate",
    "seasonEndDate",
    "rampUpDate"
  ]

}
