// animations.ts
import { trigger, transition, style, animate , keyframes } from '@angular/animations';

export const chatbotAnimation = trigger('chatbotAnimation', [
//   transition(':enter', [
//     style({ transform: 'translateX(100%)' }),
//     animate('300ms ease-out', style({ transform: 'translateX(0)' }))
//   ]),
//   transition(':leave', [
//     animate('300ms ease-in', style({ transform: 'translateX(100%)' }))
//   ])
transition(':enter', [
    animate('600ms ease-out', keyframes([
      style({ opacity: 0, transform: 'scale(0.5) translateX(100%)', offset: 0 }),
      style({ opacity: 0.5, transform: 'scale(0.8) translateX(-10px)', offset: 0.3 }),
      style({ opacity: 1, transform: 'scale(1) translateX(0)', offset: 1.0 })
    ]))
  ]),
  transition(':leave', [
    animate('400ms ease-in', keyframes([
      style({ opacity: 1, transform: 'scale(1) translateX(0)', offset: 0 }),
      style({ opacity: 0.5, transform: 'scale(0.8) translateX(-10px)', offset: 0.7 }),
      style({ opacity: 0, transform: 'scale(0.5) translateX(100%)', offset: 1.0 })
    ]))
  ])
]);
