import { Component, Input, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/authentication.service";
import { DynamicReviewService } from "src/app/services/dynamic-review.service";
import { FormControlService } from "src/app/services/form-control.service";
import { ListingGridService } from "src/app/services/listing-grid.service";
import { SyncfusionService } from "src/app/services/syncfusion.service";
import { dynamicNormReview } from "src/app/properties/dynamic-review-headers";
import { ClickStreamAnalyticsService, EventType } from "src/app/services/clickstream-analytics-service";


@Component({
  selector: "app-detail-page-accordian-molecule",
  templateUrl: "./detail-page-accordian-molecule.component.html",
  styleUrls: ["./detail-page-accordian-molecule.component.scss"],
})
export class DetailPageAccordianMoleculeComponent implements OnInit {
  @Input() code;
  // @Input() headerField;
  @Input() data;
  panelOpenState = false;
  expansionPannelROS = "rateOfSale";
  expansionPannelBPR = "productAvailability";
  expansionPannelSET = "stockTrend";
  displayName: any;
  gridData: any;
  dynamicNormForm: any;
  page: any;
  dynamicHeaders: any;
  constructor(
    private dynamicReviewServices: DynamicReviewService,
    private formControlService: FormControlService,
    private authenticationservice: AuthenticationService,
    private snack: MatSnackBar,
    private syncfusionService: SyncfusionService,
    private route: ActivatedRoute,
    private listinggridservice: ListingGridService,
    private router: Router,
    private clickStream: ClickStreamAnalyticsService
  ) {
    this.route.params.subscribe((params) => {
      if (params && params.code) {
        this.code = params.code;
        this.page = params.page;
        this.listinggridservice
          .getdynamicNormByCode(params.code)
          .subscribe((res) => {
            if (res) {
              this.displayName = res["displayName"];
              this.dynamicHeaders = dynamicNormReview;
              this.gridData = res["items"];
            }
            this.dynamicNormForm = this.formControlsForTextFields();
          });
      }
    });
  }
  ngOnInit(): void {

  }

  triggerOpenEvent(tab) {
    this.clickStream.publishGAEvent(EventType.TAB_ACTION,{
      section: tab
    });
  }

  actionEvent(event) {
    const user = this.authenticationservice.getUserName();
    let payload: {};
    payload = {
      id: event.id,
      updatedBy: user,
      status: event.status.toUpperCase(),
    };
    if (event.status === "MODIFIED") {
      payload = {
        id: event.id,
        updatedBy: user,
        newNormValue: event.newNormValue,
        status: event.status.toUpperCase(),
      };
    }
    this.dynamicReviewServices.updateSuggestions(payload).subscribe(
      (res) => {
        if (res) {
          const message = `${payload["status"]} successfully`;
          this.snack.open(message, "close", {
            duration: 4000,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
        }
      },
      (error) => {
        const message = `Operation failed please try again later`;
        this.snack.open(message, "close", {
          duration: 4000,
          horizontalPosition: "center",
          verticalPosition: "top",
        });
      },
      () => {
        this.gridData = this.gridData.filter((item) => item.id != event.id);
      }
    );
  }

  private formControlsForTextFields() {
    const updatedData = this.gridData.map((item) => ({
      ...item,
      controlType: "String",
      value: item.customNormValue,
      identifier: item.id,
    }));
    const result = this.formControlService.toFormGroup(updatedData);
    return result;
  }
}
