import { Component } from '@angular/core';
import { SCMManagementService } from '../../../../services/scm-management.service'
import { FormDataConstructorService } from 'src/app/services/form-data-constructor.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-scm-management-form-organism',
  templateUrl: './scm-management-form-organism.component.html',
  styleUrls: ['./scm-management-form-organism.component.scss']
})
export class ScmManagementFormOrganismComponent {
  formData: any;
  addFlag: any = true;
  mode: any;
  SCMid: string;
  constructor(private SCMService: SCMManagementService, private snack: MatSnackBar,
    private formService: FormDataConstructorService, private route: ActivatedRoute,
    private router: Router) {
    this.SCMService.getSCMForm().subscribe((res: any) => {
      let metaData = res.metaData;
      this.route.queryParams.subscribe(params => {
        if (params && params.code) {
          this.addFlag = false;
          let processedMetaData = [];
          this.SCMService.getSCMByCode(params.code).subscribe((data: any) => {
            if (data) {
              this.SCMid = data.id;
              this.addFlag = false;
              const processedAttrs = this.processAttrData(data);
              processedMetaData = this.processMetaData(metaData, processedAttrs);
              this.formData = this.formService.formConstructor(processedMetaData);
            }
          });
        } else {
          this.SCMid = null;
          this.addFlag = true;
          this.formData = this.formService.formConstructor(metaData);
        }
      });
    });
  }

  ngOnInit(): void {
  }

  processAttrData(data) {
    data.dynamicAttrs && data.dynamicAttrs.map((each) => {
      const key = each.attributeName;
      const val = each.attributeValue;
      data[key] = val;
    });

    return data;
  }

  processMetaData(metaData, data) {
    const processedMetaData = [];
    Object.keys(data).map((singleKey) => {
      metaData.map((eachMetaData) => {
        if (eachMetaData && singleKey === eachMetaData.identifier) {
          if (eachMetaData.dynamic) {
            data && data.dynamicAttrs.map((dynamicAttr) => {
              if (singleKey === dynamicAttr.attributeName) {
                eachMetaData['id'] = dynamicAttr.id;
              }
            })
          }
          eachMetaData.value = data[singleKey];
          eachMetaData.isReadOnly = false;
          processedMetaData.push(eachMetaData);
        }
      });
    });
    return processedMetaData;
  }
  submitForm(formValues) {
    this.SCMService.saveMaterial(formValues, this.formData, this.addFlag, this.SCMid).subscribe((response) => {
      const message = this.addFlag ? 'Added successfully' : 'changes saved successfully';
      this.snack.open(message, 'close', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      this.router.navigate(['/WorkOrders']);
    });
  }
}
