import { SelectionModel } from "@angular/cdk/collections";
import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CachingService } from "src/app/services/caching.service";

@Component({
  selector: "app-caching-molecule",
  templateUrl: "./caching-molecule.component.html",
  styleUrls: ["./caching-molecule.component.scss"],
})
export class CachingMoleculeComponent implements OnInit {
  caches: any;
  selectAllChecked = false;
  displayedColumns: string[] = [
    "itemsCached",
    "hitCount",
    "missCount",
    "loadSuccessCount",
    "loadFailureCount",
    "totalLoadTime",
    "evictionCount",
  ];
  selection: SelectionModel<any>;
  constructor(
    private cachingService: CachingService,
    private snack: MatSnackBar
  ) {}
  ngOnInit(): void {
    const initialSelection = [];
    const allowMultiSelect = true;
    this.selection = new SelectionModel<any>(
      allowMultiSelect,
      initialSelection
    );
    this.cachingService.getCacheList().subscribe((res) => {
      this.caches = res;
    });
  }

  selectAll() {
    this.caches.forEach((row) => this.selection.select(row.name));
  }
  deleteAll() {
    this.cachingService.clearAllCache().subscribe(
      (res) => {
        if (res) {
          const msg = `${res}`;
          this.snack.open(msg, "close", {
            duration: 4000,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
          this.ngOnInit();
        }
      },
      (error) => {}
    );
  }

  clearSelected() {
    const cacheList = this.selection.selected;
    this.cachingService.clearCacheUsingNames(cacheList).subscribe((res) => {
      if (res) {
        const msg = `${res}`;
        this.snack.open(msg, "close", {
          duration: 4000,
          horizontalPosition: "center",
          verticalPosition: "top",
        });

        this.ngOnInit();
      }
    });
  }
}
