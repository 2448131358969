import { Component, ElementRef, ViewChild, HostListener } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { menu } from "../../properties/menu";
import { CommonUtilityService } from "../../services/common-utility-service";
import { Router } from "@angular/router";
import { EmitterService } from "../../services/emitter.service";
import { AuthenticationService } from "../../services/authentication.service";
import { MatSidenav } from "@angular/material/sidenav";
import { UserService } from "../../services/user.service";
import { ChangeDetectorRef, OnDestroy } from "@angular/core";
import { GetLogoService } from "src/app/services/get-logo.service";

@Component({
  selector: "app-my-nav-template",
  templateUrl: "./my-nav-template.component.html",
  styleUrls: ["./my-nav-template.component.scss"],
})
export class MyNavTemplateComponent {
  @ViewChild("drawer") public drawer: MatSidenav;
  @ViewChild("drawerInnerContainer", { static: false })
  drawerInnerContainer: ElementRef;
  panelOpenState: boolean[] = [];
  menu: any;
  currentUser: any;
  isLoggedIn: boolean;
  displayMenu: any;
  mobileView: boolean = false;
  year: any = new Date().getFullYear();
  displayFlag: boolean;
  sessionTimeoutId: any;
  logo: any;
  togFunc(parentIndex) {
    if (parentIndex === 0) {
      return true;
    }
    return false;
  }
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );
  previous: any = menu;
  refreshMenu: boolean = false;
  ts(check) {
    if (check === "Amazon" || check === "Flipkart") {
      return true;
    }
    return false;
  }
  constructor(
    private getLogoService: GetLogoService,
    private breakpointObserver: BreakpointObserver,
    private utilService: CommonUtilityService,
    private el: ElementRef,
    private router: Router,
    private emitter: EmitterService,
    private authenticationService: AuthenticationService,
    private UserService: UserService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog
  ) {
    this.logo = getLogoService.clientLogoEndPointURL;
    this.emitter.isLoggedInCheck.subscribe((data) =>
      data ? this.checkAuthenticated() : ""
    );
    this.checkAuthenticated();
  }
  logout() {
    this.authenticationService.logout();
    this.checkAuthenticated();
    this.router.navigate(["/login"]);
    location.reload();
  }

  @HostListener("window:mousemove", ["$event"])
  @HostListener("window:click", ["$event"])
  @HostListener("window:keydown", ["$event"])
  onUserInteraction(event: Event) {
    this.sessionTimeout();
  }

  sessionTimeout() {
    if (this.isLoggedIn) {
      const twentyFiveMinutes = 25 * 60 * 1000;
      // const twentyFiveMinutes = 10 * 1000;
      if (this.sessionTimeoutId) {
        clearTimeout(this.sessionTimeoutId);
      }
      this.sessionTimeoutId = setTimeout(() => {
        this.logout();
      }, twentyFiveMinutes);
    }
  }

  login(url) {
    const a = window.open(`${url}`, "_blank");
  }
  onImageError() {
    this.logo = "assets/img/logo.png";
  }
  getName() {
    let name = this.authenticationService.getUserName();
    let names = name?.split(" ");
    return names
      .map((value) => {
        return value.charAt(0);
      })
      .slice(0, 2)
      .join("")
      .toUpperCase();
  }
  checkAuthenticated() {
    this.isLoggedIn = this.authenticationService.isLoggedIn;
    // this.isLoggedIn = this.authenticationService._isLoggedIn ? true : false;
    let getRole = setInterval(() => {
      if (this.authenticationService.getRole()) {
        clearInterval(getRole);
        this.isLoggedIn ? this.loadMenu() : "";
      }
    }, 100);
  }
  loadMenu() {
    if (this.menu) {
      return;
    }
    this.menu = menu;
    this.authenticationService.getRoleDetail().subscribe((data) => {
      let dataKey = data && Object.keys(data).reverse();
      data &&
        dataKey.map((i) => {
          let dataValue = data[i];
          if (typeof dataValue === "object" && dataValue !== null) {
            this.reportMenu(i, dataValue, data.role);
          }
        });
      const revenueReport = {
        header: "Revenue Dashboard",
        icon: "dashboard",
        child: [
          {
            name: "Business Score Card",
            route: "sales-analyser",
            entity: "dynamicnorm",
          },
          {
            name: "Product Analyser",
            route: "products-analyser",
            entity: "dynamicnorm",
          },
        ],
      };
      this.menu.unshift(revenueReport);
      this.accessRulesMapping();
      if (data.role === "Admin") {
        this.UserService.sendOptionReq().subscribe(
          (rs) => {
            this.UserService.getMarketplace().subscribe((res) => {
              if (res) {
                this.marketPlce(res["marketPlaceAuthDataList"]);
              }
              this.accessRulesMapping();
            });
          },
          (er) => {}
        );
      }
    });
  }
  displayCopyrighrt(length) {
    if (this.menu.length - 1 == length) {
      this.displayFlag = true;
    }
  }

  marketPlce(value) {
    let market =
      value &&
      value.map((i) => {
        let submenu = {
          name: i.name,
          role: "Admin",
          entity: "marketplace",
          data: "",
          key: i.name,
          authorise_url: i.authorise_url,
          authorised: i.authorised,
        };
        return submenu;
      });
    let mrkt = {
      header: "Market Place",
      entity: "marketplace",
      child: market,
      icon: "extension",
    };
    this.menu.push(mrkt);
  }
  reportMenu(key, value, r) {
    if (key === "dashboardReports") {
      return;
    }
    let icon = key === "historicalReports" ? "article" : "insert_chart";
    let currentReport = {};
    if (key === "historicalTrends") {
      icon = "trending_up";
      currentReport =
        value &&
        value.map((i) => {
          let submenu = {
            name: i.replace(/_/g, " "),
            route: "/chartjs",
            role: r,
            entity: "reports",
            data: "",
            key: i,
          };
          return submenu;
        });
    } else {
      currentReport =
        value &&
        value.map((i) => {
          let routePath = "/report";
          if (key === "currentReports") {
            routePath = "/grid";
          }
          let submenu = {
            name: i.replace(/_/g, " "),
            route: routePath,
            role: r,
            entity: "reports",
            data: "",
            key: i,
          };
          return submenu;
        });
    }
    let report1 = {
      header: key.replace(/([A-Z])/g, " $1").trim(),
      entity: "reports",
      child: currentReport,
      icon: icon,
    };
    this.menu.unshift(report1);
  }
  accessRulesMapping() {
    const accessRules = this.utilService.getCookie("accessRules")
      ? JSON.parse(this.utilService.getCookie("accessRules")).accessRules
      : "";
    const userId = this.authenticationService.getUserId();
    if (accessRules) {
      this.menu.map((m) => {
        m.enabled = false;
        m.child.forEach((child) => {
          if (child.entity === "reports") {
            if (child.user && child.user != userId) {
              return;
            }
            accessRules.map((each) => {
              if (each.entity === "reports" || each.entity === "marketplace") {
                if (child.role == each.role) {
                  child.rules = { read: true };
                  m.enabled = true;
                }
              }
            });
          } else {
            accessRules.map((each) => {
              if (child.entity === each.entity) {
                child.rules = each;
                if (each.create) {
                  m.enabled = true;
                }
              }
            });
          }
        });
      });
    }
  }
  navigate(cat) {
    this.isHandset$.subscribe((data) => {
      this.mobileView = data;
    });
    this.mobileView ? this.drawer.toggle() : "";
    if (cat && (cat.entity === "reports" || cat.route === "grid")) {
      this.router.navigate([cat.route], {
        queryParams: { reportName: cat.key },
      });
    } else {
      this.router.navigate([cat.route, { data: cat.data ? cat.data : null }]);
    }
  }
}
