import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
} from "@angular/core";
import { ClickStreamAnalyticsService, EventType } from "src/app/services/clickstream-analytics-service";

@Component({
  selector: "app-pu-accordian",
  templateUrl: "./pu-accordian.component.html",
  styleUrls: ["./pu-accordian.component.scss"],
})
export class PuAccordianComponent implements AfterViewInit, OnInit {
  @Input() data;
  @Input() headerField;
  panelOpenState = false;
  expansionPannelROS = "rateOfSale";
  expansionPannelBPR = "productAvailability";
  expansionPannelSET = "stockTrend";

  constructor(private el: ElementRef, private renderer: Renderer2,private clickStream: ClickStreamAnalyticsService) {}
  ngOnInit(): void {
    if (!this.headerField.decisionAttr) {
      this.headerField.decisionAttr = this.expansionPannelROS;
    }
  }
  ngAfterViewInit(): void {
    this.addOverflowToPanelBodies();
  }

  triggerOpenEvent(tab) {
    this.clickStream.publishGAEvent(EventType.TAB_ACTION,{
      section: tab
    });
  }
  
  private addOverflowToPanelBodies() {
    const panelBodies = this.el.nativeElement.getElementsByClassName(
      "mat-expansion-panel-body"
    );
    for (let i = 0; i < panelBodies.length; i++) {
      this.renderer.setStyle(panelBodies[i], "overflow", "auto");
    }
  }
}
