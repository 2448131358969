import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FilterUiService } from 'src/app/services/filter-ui.service';
import { subHeadings } from "src/app/properties/map_sub_heading";
import { SyncfusionService } from 'src/app/services/syncfusion.service';
import { AppSettingService } from 'src/app/services/app-setting.service';
import { SyncFusionExternalFilterBaseComponent } from '../sync-fusion-external-filter-organism/sync-fusion-external-filter-organism.component';

@Component({
  selector: 'app-production-intent-report-organism',
  templateUrl: './production-intent-report-organism.component.html',
  styleUrls: ['./production-intent-report-organism.component.scss']
})
export class ProductionIntentReportOrganismComponent extends SyncFusionExternalFilterBaseComponent {
  
  constructor(protected filterServices: FilterUiService, protected route: ActivatedRoute, protected syncfusion: SyncfusionService,
    protected settingsService: AppSettingService) {
    super(filterServices,route,syncfusion,settingsService);
    this.reloadPage();
  }

  

}
