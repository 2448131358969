import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http"
import { api } from "../properties/endpoints"

@Injectable({
  providedIn: 'root'
})
export class DynamicReviewService {

  constructor(private http: HttpClient) { }

  getDynamicReviewData() {
    const url = `${api.host}/suggestion/ros-dn/grid/all`;
    return this.http.get(url);
  }
  updateSuggestions(payload: {}) {
    const url = `${api.host}/suggestion/ros-dn/update`;
    return this.http.post(url, payload);
  }
}
