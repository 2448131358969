import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { FilterBayService } from "src/app/services/filter-bay.service";
import { CommonUtilityService } from "src/app/services/common-utility-service";

@Component({
  selector: "app-sales-breakdown-molecule",
  templateUrl: "./sales-breakdown-molecule.component.html",
  styleUrls: ["./sales-breakdown-molecule.component.scss"],
})
export class SalesBreakdownMoleculeComponent implements OnInit, OnChanges {
  visualizationDataList: any[];
  tableData: any;
  tableItems: {
    identifier: string,
    revenue: string,
    gross_margin: number,
    quantity_sold: number
  }[];
  addFlag: boolean;
  formData: any;
  dateRange: any;
  length: number;
  loadComplete: boolean;
  pivotFields: any[];
  selectedPivot: any;
  error: boolean;
  @Input() changesFromParent;
  passDataToScoreCard: {};
  showTableView: boolean = false;
  constructor(private filterbayservice: FilterBayService, private commonUtilityService: CommonUtilityService) { }
  ngOnChanges() {
    if (this.changesFromParent) {
      this.fetchAndProcessInformation();
    }
  }
  onOptionSelection($event) {
    this.selectedPivot = {
      code: $event.value,
      name: this.captialize($event.value),
    };
    this.fetchAndProcessInformation();
  }
  ngOnInit(): void {
    this.loadComplete = false;
    this.filterbayservice.getFilters().subscribe(
      (res) => {
        if (res) {
          let filterDataList = res["filterDataList"];
          if (!filterDataList) {
            this.loadComplete = true;
            this.error = true;
            this.visualizationDataList = [];
            return;
          }
          const group: any = {};
          this.pivotFields = [];
          filterDataList.forEach((value) => {
            this.pivotFields.push({
              code: value["code"],
              name: this.captialize(value.code),
            });
          });
          this.selectedPivot = this.pivotFields[0];
          this.fetchAndProcessInformation();
        }
      },
      (error) => {
        this.loadComplete = true;
        this.error = true;
        this.visualizationDataList = [];
      }
    );
  }

  captialize(input) {
    const caps = input[0].toUpperCase() + input.slice(1);
    return caps.replace(/_/g, " ");
  }

  setTableView(flag){
    this.showTableView=flag;
  }

  fetchAndProcessInformation(): void {
    this.visualizationDataList = [];
    let payload = this.changesFromParent;
    if (!payload) {
      payload = {};
    }
    this.loadComplete = false;
    payload.groupBy = this.selectedPivot?.code;
    this.filterbayservice.getSalesBreakdown(payload).subscribe(
      (res) => {
        if (res && res["chartJSResponseData"]) {
          let visualizationData = res["chartJSResponseData"];
          let datasets = visualizationData.data.datasets;
          let labels = visualizationData.data.labels;
          /*datasets.forEach((element) => {
            this.visualizationDataList.push({
              data: {
                datasets: [element],
                labels: labels,
              },
              displayName: `By ${element.label}`,
              type: visualizationData.type,
              options: {
                cutout: "80%",
                radius: 150,
              },
            });
          });*/
          if (datasets.length > 0) {
            this.visualizationDataList.push({
              data: {
                datasets: [
                  {
                    data: datasets[0].data.slice(0, 15),
                    label: datasets[0].label.slice(0, 15),
                    backgroundColor: this.commonUtilityService.generateRandomColorsFromBaseColor("#ffa000", 15)//datasets[0].data.length)

                  }
                ],
                labels: labels.slice(0, 15),
              },
              options: {
                //indexAxis: 'y', used to change to horizontal bar
                locale: "en-IN",
                scales: {
                  "x": {
                    "grid": {
                      "display": false,
                    },
                    title: {
                      display: true,
                      text: datasets[0].data.length > 15 ? `Top 15 ${this.selectedPivot.name}` : `${this.selectedPivot.code}`,
                      "font": {
                        "weight": "bold"
                      }
                    },
                    type: 'category'
                  },
                  "y": {
                    "type": "linear",
                    "title": {
                      "display": true,
                      "text": "Revenue",
                      "font": {
                        "weight": "bold"
                      }
                    },
                    "ticks": {
                      format: {
                        style: 'currency',
                        currency: 'INR',
                        minimumFractionDigits: 0
                      }
                    },
                    "display": true,
                    "grid": {
                      "display": true,
                      "lineWidth": 0.5,
                    }
                  },

                },
                plugins: {
                  legend: {
                    display: false,
                  }
                }
              },
              type: "bar"
            });
            try {
              let numberFormat = new Intl.NumberFormat('en-IN', {
                style: 'currency',
                currency: 'INR',
                minimumFractionDigits: 0
              })
              this.tableItems = [];
              let revenueDataSet: number[] = datasets[0].data;
              let qtySoldDataSet: number[] = datasets.length > 1 ? datasets[1].data : [];
              let grossMarginSoldDataSet: number[] = datasets.length > 2 ? datasets[2].data : [];
              labels.forEach((value: string, index) => {
                this.tableItems.push(
                  {
                    identifier: value,
                    revenue: numberFormat.format(revenueDataSet[index]),
                    gross_margin: grossMarginSoldDataSet[index],
                    quantity_sold: qtySoldDataSet[index]
                  }
                )
              })
              this.tableData = {};
              this.tableData.data = this.tableItems;
              this.tableData.count = labels.length;
              this.tableData.headers = [
                {
                  field: "identifier",
                  headerText: this.selectedPivot.name,
                  allowFiltering: true,
                  allowSorting: false,
                  textAlign: 'left',
                  width: "180"
                },
                {
                  field: "revenue",
                  headerText: "Revenue",
                  allowFiltering: false,
                  allowSorting: false,
                  textAlign: 'left',
                  width: "150"
                },
                {
                  field: "gross_margin",
                  headerText: "Gross Margin",
                  allowFiltering: false,
                  allowSorting: true,
                  textAlign: 'left',
                  width: "150"
                },
                {
                  field: "quantity_sold",
                  headerText: "Units Sold ",
                  allowFiltering: false,
                  allowSorting: true,
                  textAlign: 'left',
                  width: "150"
                }
              ]
            } catch (e) {
              this.tableData = null;
              console.error(e);
            }
          }


          this.loadComplete = true;
        }
      },
      (error) => {
        this.loadComplete = true;
        this.error = true;
      }
    );
  }
}
