import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { FilterBayService } from "src/app/services/filter-bay.service";

@Component({
  selector: "app-filter-bay-reusable",
  templateUrl: "./filter-bay-reusable.component.html",
  styleUrls: ["./filter-bay-reusable.component.scss"],
})
export class FilterBayReusableComponent implements OnInit {
  @Input() addFilters;
  @Input() MaterialMaster:boolean=false;
  @Output() getChildChanges = new EventEmitter<boolean>();
  filtersLength;
  detectChanges: boolean;
  constructor(private filterBayService: FilterBayService) {}
  ngOnInit(): void {
    this.filtersLength = Object.keys(this.addFilters).length||0;
  }

  removeFilters(key) {
    if(this.MaterialMaster){
       this.filterBayService.setFiltersForMaterialMaster(key);
       return
    }
    this.filterBayService.setFilters(key);
  }
}
