import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { api } from "../properties/endpoints";
import * as _ from "lodash";

@Injectable({
  providedIn: "root",
})
export class AppSettingService {
  app_settings: {}
  default_setting = {
    grid: {
      connect: "local",
      pageSizes: [50, 100, 200]
    }
  }
  constructor(private http: HttpClient) {
    console.log("Called App Settings");
    this.loadAppSetting()
  }
  api_endpoint = api.host;
  siteCode = this.api_endpoint
    .replace("https://", "")
    .replace(".api.flowsmart.ai", "");

  loadAppSetting() {
    if (this.app_settings) {
      return this.app_settings;
    } else if (!(this.app_settings = this.getSettings())) {
      this.fetchFromServer();
    }
  }

  isRemoteGridMode() {
    return "remote" == this.getAppSetting("grid.connect","local")
  }

  getAppSetting(path, defaultValue: any = null) {
    try {
      let value = _.get(this.app_settings ? this.app_settings : this.default_setting, path)
      if (!value) {
        return defaultValue;
      }
      return value;
    } catch (e) {
      console.log("error fetching app setting")
      return defaultValue;
    }
  }

  private fetchFromServer() {
    const url = `https://storage.googleapis.com/flowsmart-cms/${this.siteCode}/settings.json?34235489`;
    this.http.get(url, { responseType: "text" }).subscribe((res) => {
      this.app_settings = JSON.parse(res);
      this.storeSettings(this.app_settings);
    },
      (error) => {
        this.storeSettings(null);
      })
  }

  private storeSettings(settings) {
    sessionStorage.setItem("fs_app_settings", JSON.stringify(settings));
  }

  getSettings() {
    let settings = sessionStorage.getItem("fs_app_settings");
    if (settings) {
      return JSON.parse(settings)
    }
  }


}
