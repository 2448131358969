<!-- <p>workflow-organism works!</p> -->
<div class="fs-padding">
    <div class="fs-section-block fs-margin-0 header-background ">
        <div class="d-flex justify-content-between align-items-center">
            <div>
                <span class="mat-headline">Manage Workflows</span>
                <p class="fs-hint-text">Automate your workflows</p>
            </div>
            <button style="height: fit-content;" class="mat-button mat-primary mat-raised-button" *ngIf="!isCreated"
                (click)="createWorkflow()">
                Create
            </button>
        </div>
    </div>
    <div class="fs-section-block" *ngIf="!isCreated">
        <mat-accordion>
            <mat-expansion-panel expanded=true>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <strong>Workflow List</strong>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="list-container">
                    <div *ngFor="let item of workflowList" class="list-item">
                        <p class="list-item-title">{{ item.name }}</p>
                        <div class="actions-cont">
                            <mat-icon (click)="editWorkflow(item.code)">edit</mat-icon>
                            <mat-icon (click)="deleteWorkflow(item.code)">delete</mat-icon>
                            <mat-slide-toggle [checked]="item.active" (change)="toggleActive(item)">
                                {{ item.active ? 'Active' : 'Inactive' }}</mat-slide-toggle>
                        </div>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div class="workspace-cont" *ngIf="isCreated" [class.mat-elevation-z8]="true">
        <mat-toolbar color="primary">
            <button mat-icon-button (click)="toggleHome()">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <div class="toolbar-title">
                <input type="text" #workflowNameInput [formControl]="workflowNameControl" [readonly]="!isEditingName"
                    placeholder="New Workflow" class="toolbar-input" />
            </div>
            <span class="spacer"></span>
            <button mat-icon-button (click)="EditWorkflowName()" style="outline: none;">
                <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button (click)="confirmationDialog.show()">
                <mat-icon>save</mat-icon>
            </button>
        </mat-toolbar>
        <ejs-dialog #confirmationDialog [visible]="false" [header]="schedule ? 'Schedule cron' : 'Save WorkFlow'" [position]='{ X: "center", Y: "center" }' 
            [content]="content" [showCloseIcon]='true' (close)="closeConfirmDialog()" [target]="documentBody">
            <ng-template #content>
                <div *ngIf="!schedule">
                    <mat-form-field style="margin: 10px;width: 90%;">
                        <mat-label>Workflow Code</mat-label>
                        <input matInput placeholder="Enter Workflow Code" [formControl]="workflowCodeControl">
                        <mat-error *ngIf="workflowCodeControl.hasError('required') && workflowCodeControl.touched">
                            Workflow Code is required.
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field style="margin: 10px;width: 90%;">
                        <mat-label>Workflow Name</mat-label>
                        <input matInput placeholder="Enter Workflow Name" [formControl]="workflowNameControl">
                        <mat-error *ngIf="workflowNameControl.hasError('required') && workflowNameControl.touched">
                            Workflow Name is required.
                        </mat-error>
                    </mat-form-field>
                </div>
                <!-- Schedule cron template -->
                <div *ngIf="schedule">
                    <div class="cron-generator">
                        <div class="tabs">
                            <button *ngFor="let tab of tabs" [class.active]="selectedTab === tab.value"
                                (click)="selectedTab = tab.value; onTabChange()">
                                {{ tab.label }}
                            </button>
                        </div>

                        <div class="tab-content-cont" [ngSwitch]="selectedTab">
                            <div *ngSwitchCase="'minutes'">
                                <label>Every
                                    <input type="number" [(ngModel)]="minutesModel.minutes" (change)="generateCron()"
                                        min="1" max="59">
                                    minute(s)
                                </label>
                            </div>

                            <div *ngSwitchCase="'hourly'">
                                <label>Every
                                    <input type="number" [(ngModel)]="hourlyModel.hours" (change)="generateCron()"
                                        min="1" max="23">
                                    hour(s)
                                </label>
                            </div>

                            <div *ngSwitchCase="'daily'">
                                <label>Every
                                    <input type="number" [(ngModel)]="dailyModel.days" (change)="generateCron()" min="1"
                                        max="31">
                                    day(s) at
                                    <input type="time" [(ngModel)]="dailyModel.time" (change)="generateCron()">
                                </label>
                            </div>

                            <div *ngSwitchCase="'weekly'" class="weekly-cont">
                                <label>Every</label>
                                <div class="days-cont" *ngFor="let day of ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']">
                                    <label>
                                        <input type="checkbox" [checked]="isWeekDaySelected(day)"
                                            (change)="toggleWeekDay(day)">
                                        {{ day }}
                                    </label>
                                </div>
                                <div class="time-cont">
                                <label>at</label>
                                <input type="time" [(ngModel)]="weeklyModel.time" (change)="generateCron()">
                            </div>
                            </div>

                            <div *ngSwitchCase="'monthly'">
                                <label>Day
                                    <input type="number" [(ngModel)]="monthlyModel.day" (change)="generateCron()"
                                        min="1" max="31">
                                    of every
                                    <input type="number" [(ngModel)]="monthlyModel.months" (change)="generateCron()"
                                        min="1" max="12">
                                    month(s) at   
                                <input type="time" [(ngModel)]="monthlyModel.time" (change)="generateCron()">
                                </label>
                            </div>

                            <div *ngSwitchCase="'yearly'">
                                <label>Every
                                    <select [(ngModel)]="yearlyModel.month" (change)="generateCron()">
                                        <option *ngFor="let month of months; let i = index" [value]="i + 1">
                                            {{ month }}
                                        </option>
                                    </select>
                                    <input type="number" [(ngModel)]="yearlyModel.day" (change)="generateCron()" min="1"
                                        max="31">
                                    at
                                    <input type="time" [(ngModel)]="yearlyModel.time" (change)="generateCron()">
                                </label>
                            </div>

                            <div *ngSwitchCase="'advanced'">
                                <label>Cron Expression:
                                    <input type="text" [(ngModel)]="advancedModel.expression" (change)="generateCron()">
                                </label>
                            </div>
                        </div>

                        <div class="result" *ngIf="cronExpression">
                            <h3>Generated Cron Expression:</h3>
                            <pre>{{ cronExpression }}</pre>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template #footerTemplate>
                <button class="e-control e-btn" mat-raised-button color="warn"
                    (click)="closeConfirmDialog()">{{schedule ? 'Back' : 'Cancel'}}</button>
                <button class="e-control e-btn" mat-raised-button color="primary"
                    (click)="schedule ? saveWorkflow() : switchTab()">
                    {{schedule ? 'Save' : 'Next'}}</button>
            </ng-template>
        </ejs-dialog>

        <div class="diagram-cont" (dragover)="onDragOver($event)" (drop)="onDrop($event,'card')">
            <ejs-diagram #diagram id="diagram" width="100%" height="425px" [snapSettings]="snapSettings"
                [scrollSettings]="scrollSettings" [layout]="layout" [getNodeDefaults]='nodeDefaults'
                [getConnectorDefaults]="getConnectorDefaults" (created)="created()" (click)="onClick($event)"
                style="background-color: #f2f4ff;">
            </ejs-diagram>

            <div class="menu-card-cont">
                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <strong class="menu-icon"><mat-icon> import_contacts</mat-icon> Steps Menu</strong>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="cards-container">
                            <mat-card *ngFor="let card of cards" class="card mat-elevation-z4" id="card{{card.id}}"
                                (dragstart)="onDrag(card)" (touchstart)="onDrag(card)"
                                (touchend)="onTouchEnd($event,card)" draggable="true">
                                <mat-card-content>
                                    <mat-icon class="card-icon">{{ card.icon }}</mat-icon>
                                    <div class="card-text">
                                        <div class="card-title">{{ card.title }}</div>
                                        <div class="card-subtitle">{{ card.subtitle }}</div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
            <app-workflow-configure-modal-molecule [showModal]="showModal" (showModalChange)="closeModal()"
                [data]="currentDialogData" (save)="updateNodeData($event)"
                (changeLabels)="updateNodeLabels($event)"></app-workflow-configure-modal-molecule>
            <ejs-contextmenu #cmenu id="contextmenu" [items]="menuItems" (select)="menuClick($event)"></ejs-contextmenu>
        </div>
    </div>
</div>