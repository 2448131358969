import { Component, Input, OnInit } from '@angular/core';
import { ChartType, Chart } from 'chart.js/auto';
import { ChartjsReportsService } from '../../../services/chartjs-reports.service';
import { SyncfusionService } from 'src/app/services/syncfusion.service';

@Component({
  selector: 'app-dashboard-chartjs-grids-organsim',
  templateUrl: './dashboard-chartjs-grids-organsim.component.html',
  styleUrls: ['./dashboard-chartjs-grids-organsim.component.scss']
})
export class DashboardChartjsGridsOrgansimComponent implements OnInit {
  chartData: any;
  charts: any[] = [];
  chartType: ChartType;
  data: any;
  dynamic_headers: any;
  count: any;
  size: number;
  @Input() dashboardCharts;
  show: boolean;
  constructor(private reportsservice: ChartjsReportsService, private syncfusion: SyncfusionService) { }

  async ngOnInit() {
    const orderMap = {}; // Initialize an object to store the order of dashboardCharts

    this.dashboardCharts.forEach((element, index) => {
      orderMap[element] = index; // Store the index of each element in orderMap
    });

    const observables = this.dashboardCharts.map(async element => {
      this.show = true;
      if (element === 'Chronic_Items' || element === 'Surplus_Inventory') {
        try {
          const res = await this.syncfusion.getSyncfusionData(element).then((res) => {
            if (res.items.length > 0) {
              const grids_data = {
                "data": res.items,
                "headers": JSON.parse(res.headerContent),
                "displayname": res.displayName,
                "order": orderMap[element],
                "pagination" : {
                    pageSize: 10,
                    pageCount:5,
                    locale: "en-US",
                },
                "frozenColumns": 1 // Store the order in the object
              };
              if (element === 'Chronic_Items') {
                grids_data.headers = [
                  {
                      "field": "materialCode",
                      "headerText": "Material",
                      "allowFiltering": false,
                      "width": "150"
                  },
                  {
                      "field": "imageUrl",
                      "headerText": "Image",
                      "allowFiltering": false,
                      "width": "50",
                      "imageRender": true
                  },
                  {
                      "field": "locationName",
                      "headerText": "Location",
                      "allowFiltering": true,
                      "width": "150",
                      "filter": {
                          "type": "CheckBox"
                      }
                  },
                  {
                      "field": "currentInventory",
                      "headerText": "Stock on Hand",
                      "allowFiltering": false,
                      "width": "150"
                  }
              ];
              }
              this.charts.push(grids_data);
            }
            this.size = this.charts.length;
            this.charts.sort((a, b) => a.order - b.order);
          })
          
        } catch (error) {
          this.show = false;
          console.error('Error in grid request report :', error);
        }
      } else {
        try {
          const res = await this.reportsservice.getReportWithCache(element);
          if (res.data) {
            this.chartData = {
              header: res.displayName,
              type: res.type,
              data: res.data,
              options: JSON.parse(res.options),
              "order": orderMap[element] // Store the order in the object
            };
            this.chartData.options.aspectRatio = 100 / 70;
            this.charts.push(this.chartData);
          }
        } catch (error) {
          this.show = false;
          console.error('Error in Chart JS request:', error);
        }
      }
      this.size = this.charts.length;
      this.charts.sort((a, b) => a.order - b.order);
    });
    await Promise.all(observables);
    this.show = false;
    // this.size = this.charts.length;
  }

}
