import { Component, EventEmitter, Input, Output } from "@angular/core";
import { oldToNew } from "src/app/properties/menuMapping";
import { ListingGridService } from "src/app/services/listing-grid.service";
import { Router} from "@angular/router";
import { UserService } from "../../services/user.service";

@Component({
  selector: "app-new-navbar-template",
  templateUrl: "./new-navbar-template.component.html",
  styleUrls: ["./new-navbar-template.component.scss"],
})
export class NewNavbarTemplateComponent {
  @Input() openSidebar: boolean = true;
  @Input() menu;
  @Input() mobile;
  @Output() close = new EventEmitter<boolean>();
  constructor(
    private listinggridservice: ListingGridService,
    private router: Router,
    private UserService: UserService,
  ) { }
  getNewMenuName(menuItem) {
    return oldToNew[menuItem] ? oldToNew[menuItem] : menuItem;
  }

  performRouting(subMenu: any) {
    if(subMenu.route === 'products/listing')
    {
      this.listinggridservice.setAppliedFiltersAndSort(null);
    }
    this.router.navigate([subMenu.route], { queryParams: { reportName: subMenu.params } });
  }

  closeNav() {
    if (this.mobile) {
      this.close.emit(true);
    }
  }
  activateSubmenu(event, submenu) {
    let markupElement = event.target.parentElement;
    let submenuHeight = submenu.offsetHeight;
    let windowHeight = window.innerHeight;
    if (submenuHeight + markupElement.offsetTop + 10 < windowHeight) {
      submenu.style.top = `${markupElement.offsetTop}px`;
    } else {
      submenu.style.top = `${windowHeight - submenuHeight - 20}px`;
    }
  }

  performMarketPlaceRouting(item_sub: any) {
    this.UserService.getMarketplace().subscribe((res) => {
      if (res) {
        if(res["marketPlaceAuthDataList"][0].authorised)
        {
          alert("Amazon is already authorized ...");
        }
        else{
          window.open(`${res["marketPlaceAuthDataList"][0].authorise_url}`, "_blank");
        }
      }
    });
  }
}
