import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { api } from "../properties/endpoints";
import { Subject } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class ReturnAnalyzerService {
  groupByKeys = new Subject<[]>();
  constructor(private http: HttpClient) {}

  setKeys(keys: []) {
    this.groupByKeys.next(keys);
  }
  getVisualChart(payload) {
    const url = `${api.host}/reports/returns/visualize/chart`;
    return this.http.post(url, payload);
  }
  getHighestReturns(payload) {
    const url = `${api.host}/reports/returns/grid/highest-returns`;
    return this.http.post(url, payload);
  }
  getProcessingDates() {
    const url = `${api.host}/reports/returns/processing/date`;
    return this.http.get(url);
  }
  getScoreCard(payload) {
    const url = `${api.host}/reports/returns/scorecard/apply/filter`;
    return this.http.post(url, payload);
  }
}
