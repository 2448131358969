<div class="pt-2 pl-2">
  <button mat-button mat-raised-button color="primary" routerLink='/'>
      <span class="material-icons">
          arrow_back
      </span>
      Back
  </button>
</div>

<mat-toolbar>Change Password Form</mat-toolbar>

<app-dynamic-form-molecule *ngIf="formData" [formData]='formData' (submitForm)="submitForm($event)"></app-dynamic-form-molecule>
<div class="password-rules">
  <h4>Password Change Rules</h4>
  <ul class="reduce-size">
    <li>Password should be minimum 8 characters long.</li>
    <li>Password should contain at least one Upper case and one Lower case alphabet.</li>
    <li>Password should contain at least one of the (!, @, #, $, %, ^, &, *, (, ), ,, ., ?, :, {{'{'}} characters.</li>
  </ul>
</div>
<!-- <h2>Passowrd rules</h2>
<p class="col-4 d-flex">Password should be minimum 8 characters long.</p>
<p class="col-4 d-flex">Password should contain at least one Upper case and one Lower case alphabet.</p>
<p class="col-4 d-flex">Password should contain at least one of the (!, @, #, $, %, ^, &, *, (, ), ,, ., ?, :, {{'{'}} characters.</p>
 -->
