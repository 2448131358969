<div class="container my-3 bg-light">
  <div class="col-md-12 text-center">
    <button (click)="downloadTemplate('inventory')" class="mr-2" type="button" mat-flat-button color="primary"><i class="bi bi-download"></i>&nbsp;
      <i class="bi bi-filetype-csv"></i> Inventory template</button>
    <button (click)="downloadTemplate('purchase_order')" class="mr-2" type="button" mat-flat-button color="primary">
      <i class="bi bi-download"></i>
      &nbsp;<i class="bi bi-filetype-csv"></i>Purchase order template</button>
  </div>
</div>

<div *ngIf="!file" class="file-container container d-flex align-items-center justify-content-center">
  <input type="file" class="file" (change)="getFile($event)">
</div>
<div [ngStyle]="{'margin-bottom':'2%'}" *ngIf="!file" class="d-flex align-items-center justify-content-center">
  <span>Drag and drop or click to upload</span></div>


<div *ngIf="file" class="option-container">
  <div class="option-box">
    <span><strong>File Name:</strong> {{file.name}}</span>
    <!-- <img src="../../../../assets/img/file_icon.png" width="50" height="50"/>
    <br> -->
    <br>
    <br>
    <span><strong>Size: </strong>{{formatFileSize(file.size)}}</span>
  </div>
  <div class="option-box">
    <h4>Choose File Type</h4>
    <label>
      <input type="radio" name="option" value="Inventory" [(ngModel)]="selectedOption"> Inventory
    </label>
    <br>
    <label>
      <input type="radio" name="option" value="Purchase Order" [(ngModel)]="selectedOption"> Purchase Order
    </label>
  </div>
  <div class="option-button">
    <button class="button-aligen mr-2" *ngIf="file" mat-flat-button color="primary"
      (click)="file=undefined">Close</button>
    <button class="button-aligen mr-2" mat-flat-button color="primary" (click)="fileUpload(file)"
      [disabled]="!selectedOption">
      <i class="bi bi-upload"></i> Upload</button>
  </div>
</div>



<div class="mat-elevation-z8 col-md-11" style="margin: 0 auto;">
</div>
<table mat-table class="full-width-table mat-elevation-z8" [dataSource]="dataSource">
  <ng-container *ngFor="let disCol of displayStockColumns; let i=index" matColumnDef="{{disCol}}">
    <th [ngStyle]="{ 'text-align': 'center','width': i === 4 ? '35px' : '40px'}" class="table_heade" mat-header-cell
      *matHeaderCellDef> {{addSpaces(disCol)}} </th>
    <td [ngStyle]="{ 'text-align': 'center'}" mat-cell *matCellDef="let element" class='table_cell'>
      <span *ngIf="i==6">
        <i class="bi bi-download" (click)="downloadFile(element)"></i>
      </span>
      <span *ngIf="i!=6" >
        {{i==5?formatDate(element[disCol]):element[disCol]}}
      </span>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayStockColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayStockColumns;"></tr>
</table>
<mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="0" [pageSize]="100"
  [pageSizeOptions]="[10, 25, 50, 100]">
</mat-paginator>
