<div>
  <div class="fs-section-block fs-margin-0 header-background">
    <div class="filter-visible">
      <div>
        <span class="mat-headline">Product Listings</span>
        <p class="fs-hint-text">A 360&deg; view of Product Performance</p>
      </div>
      <div class="d-flex mobile_screen">
        <div class="d-flex align-items-center form__fields">
          <mat-form-field>
            <mat-label>Search with Text</mat-label>
            <input matInput [(ngModel)]="search_with_text" (keyup.enter)="searchWithText()" (ngModelChange)="onInputChange($event)" >
          </mat-form-field>
          <button [disabled]="!search_with_text" (click)="searchWithText()" style="height: fit-content;" mat-flat-button
            color="primary">Search
            <mat-icon>search</mat-icon></button>
        </div>
        <div class="d-flex align-items-center form__fields">
          <mat-form-field>
            <mat-label>Sort by</mat-label>
            <mat-select [(ngModel)]="sortByOption" (valueChange)="handleValueChange($event)">
              <mat-option *ngFor="let option of sortFilterOptions" [value]="option">
                {{option}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="pointer__arrow"(click)="sortByAscOrDes()">
            <mat-icon *ngIf="sortByAsc">arrow_upward</mat-icon>
            <mat-icon *ngIf="!sortByAsc">arrow_downward</mat-icon>
          </div>
          <button style="height: fit-content;" (click)="toggleOverlay()"
            class="mat-button mat-primary mat-raised-button">
            <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="filter_alt"></mat-icon>
            Filters
          </button>
        </div>
      </div>
    </div>
    <div [hidden]="!showOverlay" class="mat-elevation-z1 fs-padding-1 overlay-filters">
      <app-filter-molecule (filterOutput)="getFilters($event)"  (addFiltersOut)="selectedFilters($event)"
        [detectChangesFromParent]="detectChanges" [isDateFilter]="false"></app-filter-molecule>
    </div>
  </div>

  <app-filter-bay-reusable *ngIf="addFilters" [addFilters]="addFilters"></app-filter-bay-reusable>
  <mat-paginator *ngIf="this.gridList && this.totalRecordCount >42" #paginator (page)="clickToNextPage($event)"
    [length]="totalRecordCount" [pageSize]="pageSize" [(pageIndex)]="currentPage" aria-label="Select page">
  </mat-paginator>
  <div #productContainer>
    <div class="product-grid fs-section-block">
      <ng-container *ngIf="loading; else productGrid">
        <div class="loading-spinner">Loading Products...</div>
      </ng-container>
  
      <ng-template #productGrid>
        <ng-container *ngIf="gridList && gridList.length > 0; else noProducts">
          <ng-container *ngFor="let item of gridList">
            <div class="product-grid-item" [attr.data-code]="item.code">
              <app-product-card-molecule [item]="item"></app-product-card-molecule>
            </div>
          </ng-container>
        </ng-container>
        <!-- Template for "No Products Found" -->
        <ng-template #noProducts>
          <div class="no-products-found">
            No Products Found for "{{ search_with_text }}"
          </div>
        </ng-template>
      </ng-template>
    </div>
  </div>
  <mat-paginator *ngIf="this.gridList && this.gridList.length>10" (page)="clickToNextPage($event)"
    [length]="totalRecordCount" [pageSize]="pageSize" aria-label="Select page">
  </mat-paginator>
</div>
