import { Component, Input } from '@angular/core';
import { TabContent } from '../../../properties/buffer_critera_tabs'

@Component({
  selector: 'app-tab-container-molecule',
  templateUrl: './tab-container-molecule.component.html',
  styleUrls: ['./tab-container-molecule.component.scss']
})
export class TabContainerMoleculeComponent {
  @Input() formData
  @Input() form
  pagedData: any[] = [];
  splitFormData: { [key: string]: string[] } = {};
  staticFields = [];
  ngOnInit(): void {
    for (const key in TabContent) {
      const tabs = TabContent[key]
      const seperate = [];
      for (const identifier in this.formData) {
        if (this.formData[identifier].identifier === 'materialCode' || this.formData[identifier].identifier === 'locationCode') {
          this.staticFields.push(this.formData[identifier]);
        }
        if (tabs.includes(this.formData[identifier].identifier)) {
          seperate.push(this.formData[identifier])
        }
      }
      this.splitFormData[key] = seperate;
    }
    this.staticFields = Array.from(new Set(this.staticFields));
  }
}
