import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../services/user.service';
import { FormDataConstructorService } from '../../../../services/form-data-constructor.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-user-form-organism',
  templateUrl: './user-form-organism.component.html',
  styleUrls: ['./user-form-organism.component.scss']
})
export class UserFormOrganismComponent implements OnInit {
  formData: any;
  addFlag: any = true;
  userId: string;
  old_data: string
  changed: boolean = false;
  constructor(private userService: UserService, private snack: MatSnackBar,
    private formService: FormDataConstructorService, private route: ActivatedRoute,
    private router: Router) {
    this.userService.getUserForm().subscribe((res: any) => {
      let metaData = res.metaData;

      this.route.queryParams.subscribe(params => {
        if (params && params.code) {
          this.addFlag = false;
          let processedMetaData = [];
          this.userService.getUserById(params.code).subscribe((data: any) => {
            if (data) {
              this.userId = data.id;
              //const attrAPI = data && data._links && data._links.self.href;
              const attrAPI = params.code;
              this.userService.getUserAttributes(attrAPI).subscribe((attributeData: any) => {
                data.dynamicAttrs = attributeData.attributes;
                const processedAttrs = this.processAttrData(data);
                processedMetaData = this.processMetaData(metaData, processedAttrs);
                this.formData = this.formService.formConstructor(processedMetaData);
                this.formData.forEach(element => {
                  if (element.identifier === 'passwd') {
                    this.old_data = element.value;
                  }
                });

              });
            }
          });
        } else {
          this.addFlag = true;
          this.userId = null;
          metaData = metaData.filter(each => each.identifier !== 'createdTime' && each.identifier !== 'modifiedTime');
          this.formData = this.formService.formConstructor(metaData);
        }
      });
    });
  }

  ngOnInit(): void {

  }

  processAttrData(data) {
    data.dynamicAttrs && data.dynamicAttrs.map((each) => {
      const key = each.attributeName;
      const val = each.attributeValue;
      data[key] = val;
    });
    return data;
  }

  processMetaData(metaData, data) {
    const processedMetaData = [];
    Object.keys(data).map((singleKey) => {
      metaData.map((eachMetaData) => {
        if (eachMetaData && singleKey === eachMetaData.identifier) {
          if (eachMetaData.dynamic) {
            data && data.dynamicAttrs.map((dynamicAttr) => {
              if (singleKey === dynamicAttr.attributeName) {
                eachMetaData['id'] = dynamicAttr.id;
              }
            })
          }
          eachMetaData.value = data[singleKey];
          if ((singleKey === 'createdTime' || singleKey === 'modifiedTime')) {
            eachMetaData.isReadOnly = true;
            processedMetaData.push(eachMetaData);
          } else {
            eachMetaData.isReadOnly = false;
            processedMetaData.push(eachMetaData);
          }
        }
      });
    });
    return processedMetaData;
  }

  submitForm(formValues) {
    this.formData.forEach(ele => {
      if (ele.identifier === 'passwd') {
        const new_data = formValues.passwd.value;
        if (new_data != this.old_data) {
          this.changed = true;
        }
      }
    })
    this.userService.saveUser(formValues, this.formData, this.addFlag, this.userId, this.changed).subscribe((response) => {
      const message = this.addFlag ? 'Added successfully' : 'changes saved successfully';
      this.snack.open(message, 'close', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      this.router.navigate(['/users']);
    });
  }

}
