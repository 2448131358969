<mat-card class="my-card col-md-4 mat-elevation-z8">
  <div class="logo-image"><img class="img-fluid col-6" (error)="onImageError()" src={{logo}} /></div>
  <mat-card-content>
    <form class="my-form text-center" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <mat-form-field class="form-field">
        <mat-label>Login</mat-label>
        <input matInput type="text" formControlName="username" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
          <div *ngIf="f.username.errors.required">Username is required</div>
        </div>
      </mat-form-field>
      <mat-form-field class="form-field">
        <mat-label>Password</mat-label>
        <input matInput type="password" formControlName="password" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required">Password is required</div>
        </div>
      </mat-form-field><br />
      <div class="form-group">
        <button mat-raised-button color="primary" [disabled]="loading" class="btn btn-primary">
          SIGN IN
        </button>
        <div class="margin">
          <a mat-button routerLink="/passwordReset">Forgot password?</a>
        </div>
        <!-- <img *ngIf="loading" class="pl-2" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" /> -->
      </div>
      <div *ngIf="error" class="alert alert-danger">{{ error }}</div>
    </form>
  </mat-card-content>
  <!-- <mat-card-actions class="text-center">
        <button mat-raised-button color="primary">LOGIN</button>
     </mat-card-actions> -->
</mat-card>