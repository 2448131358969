import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {AuthenticationService} from '../services/authentication.service';
import * as fs from 'file-saver';
import {api} from '../properties/endpoints';

@Injectable({
  providedIn: 'root'
})
export class BulkUploadServiceService {

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {
   }
  httpHeaders:any;
  downloadTemplate(pageName,input?)  {
    if(pageName==='SCMManagement'){
      var mode='create';
      if(input!='update')
          input=mode;
      const urlAPI = `${api.host}/planning/production_intent/excelTemplate/${input}`;
      this.http.get(urlAPI ,{ responseType: 'blob' as 'blob'})
      .subscribe(response => this.downLoadFile(response, pageName+ '_' + input , "application/ms-excel" ));
    }else{
      const currentUser = this.authenticationService.getTokenDetails();
      const authToken = currentUser && currentUser.token;
      const urlAPI = `${api.host}/${pageName}/excelTemplate?token=${authToken}`;

      this.http.get(urlAPI ,{ responseType: 'blob' as 'blob'})
      .subscribe(response => this.downLoadFile(response, pageName , "application/ms-excel" ));
    }
  }

  downloadFnTemplate(pageName) {
    const currentUser = this.authenticationService.getTokenDetails();
    const authToken = currentUser && currentUser.token;
    const urlAPI = `${api.host}/${pageName}/forcenorm/excelTemplate?token=${authToken}`;
    this.http.get(urlAPI ,{ responseType: 'blob' as 'blob'})
    .subscribe(response => this.downLoadFile(response, pageName , "application/ms-excel" ));
  }

  downLoadFile(data: any, fileName: any, type: string) {
      const blob: any = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      let link = document.createElement("a");
      if (link.download !== undefined) {
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }

    uploadFile(file, page){
      if(page==='SCMManagement'){
        const formData = new FormData();
        formData.append('file', file, file.name);
        const url = `${api.host}/planning/production_intent/upload `;
        return this.http.post(url, formData, {responseType: 'text'});
      }else{
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('uploadedBy', this.authenticationService.getUserName());
        const url = `${api.host}/${page}/upload`;
        return this.http.post(url, formData, {responseType: 'text'});
      }

    }

    downLoadReport(pageName)  {
      const currentUser = this.authenticationService.getTokenDetails();
      const authToken = currentUser && currentUser.token;
      const urlAPI = `${api.host}/${pageName}/download?token=${authToken}`;

      this.http.get(urlAPI ,{ responseType: 'blob' as 'blob'})
      .subscribe(response => this.downLoadFile(response, pageName + '_Report' , "application/ms-excel" ));
    }
}
