import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { locationColumns } from '../../../../properties/tableConfig';
import { LocationsService } from '../../../../services/locations-service';
import { MatTableDataSource } from '@angular/material/table';
import { CommonUtilityService } from '../../../../services/common-utility-service';
import { SearchFilterService } from 'src/app/services/search-filter.service';
import { BulkUploadServiceService } from 'src/app/services/bulk-upload-service.service';
import { Subscription } from 'rxjs';
import { SyncfusionService } from 'src/app/services/syncfusion.service';
import { AppSettingService } from 'src/app/services/app-setting.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-locations-table-molecule',
  templateUrl: './locations-table-molecule.component.html',
  styleUrls: ['./locations-table-molecule.component.scss']
})
export class LocationsTableMoleculeComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  ELEMENT_DATA: any[];
  dataSource = new MatTableDataSource<any>();
  displayedColumns = locationColumns;
  add = false;
  update = false;
  searchField;
  nextLink: string
  nextLocationList$ = new Subscription();
  page: string

  clearSearchField() {
    this.searchField = '';
    this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
  }
  constructor(private service: LocationsService,
    private utilService: CommonUtilityService,
    private searchService: SearchFilterService,
    private bulkService: BulkUploadServiceService,
    private syncfusion: SyncfusionService,
    private settingService: AppSettingService,
    private snack: MatSnackBar) { }

  ngOnInit() {
    this.service.getAllLocations().subscribe((res: any) => {
      this.ELEMENT_DATA = res.locations;
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      if (res) {
        // this.nextLink = res?._links?.next?.href;
        this.page = res?._links?.next?.href?.split("?")[1];
      }
    });
    const accessRules = JSON.parse(this.utilService.getCookie('accessRules')).accessRules;
    accessRules.map(each => {
      if (each.entity === 'location') {
        this.add = each.create;
        this.update = each.update;
      }
    });
  }

  public doFilter = (value: string) => {
    //this.dataSource.filter = value.trim().toLocaleLowerCase();
    if (!value) {
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
    else this.searchService.searchFilter('location', value, 'code').subscribe((res: any) => {
      this.dataSource = new MatTableDataSource<any>(res.locations);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    })
  }

  downLoadReport() {
    // this.bulkService.downLoadReport('location');
    if(this.settingService.isRemoteGridMode()){
      const data = {
        downloadEntity: true
      };
      const reportName = 'locations';
      this.syncfusion.exportDataToExcel(data,reportName).subscribe((response) => {
        if (response.status === 307) {
          this.bulkService.downLoadReport('location');
        } else {
          this.snack.open("Download triggered Successfully", "close", {
            duration: 4000,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
        }
      }, (error) => {
        console.error('Error in Excel Export:', error);
      });

    }else{
      this.bulkService.downLoadReport('location');
    } 
  }

  getNextLocation() {
    if (this.page) {
      this.nextLocationList$ = this.service.getNextLocation(this.page).subscribe((res: any) => {
        //this.nextLink = res?._links?.next?.href;

        this.page = res?._links?.next?.href?.split("?")[1];
        this.ELEMENT_DATA.push(...res.locations);
        this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.paginator.nextPage();
      });
    }
  }

  pageChanged(event) {
    if (this.page) {
      if (!(event.previousPageIndex > event.pageIndex)) {
        this.getNextLocation();
      }
    }
  }
  ngOnDestroy() {
    this.nextLocationList$.unsubscribe();
  }
}
