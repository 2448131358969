<div class="product-card" [routerLink]="routeList">
  <div class="product-detail">
    <img src="{{item.imageUrl | imageUrl}}" alt="{{item.name}}" class="mat-elevation-z6">
    <div class="product-info">
      <div>
        <p class="skucode">{{item.code}}&nbsp;<mat-icon
            [ngStyle]="{'color':this.rosTrendIconColor}">{{this.rosTrendIcon}}</mat-icon></p>
        <div class="product-tags">
          <span class="lifecyclestatus tag {{item.lifeCycleStatus}}">{{item.lifeCycleStatus}}</span>
          <div *ngIf="item.brokenChannelCount && item.brokenChannelCount > 0;then broken else available"></div>
          <ng-template #broken><span class="availability tag broken">Broken</span></ng-template>
          <ng-template #available><span class="availability tag available">Available</span></ng-template>

        </div>
        <p class="name">{{item.name}}</p>
        <p class="solddate">{{this.latestDateSold}}</p>
        <div class="attributes fs-flex" *ngIf="detailedView">
          <div class="attribute">
            <div class="value">{{item.categoryLevel1}}</div>
            <label>Category</label>
          </div>
          <!-- <mat-divider [ ]="true"></mat-divider> -->
          <div class="attribute">
            <div class="value">{{item.brand}}</div>
            <label>Brand</label>
          </div>
        </div>
        <div class="attributes fs-flex lifecycle-status" *ngIf="detailedView && waitForBlockStatus">
          <div *ngFor="let attribute of blockAttributes" class="attribute">
            <mat-slide-toggle (change)="onChangeBlockStatus(attribute)" [checked]="blockStatus[attribute]"
              [(ngModel)]="blockStatus[attribute]" color="warn">
              {{ attribute=='productionBlock'?'Production Block':'Planning Block' }}
            </mat-slide-toggle>
          </div>
          <mat-form-field *ngIf="lifeCycleOptions.length>0 && !disableRoute">
            <mat-label>Life Cycle Status</mat-label>
            <mat-select (selectionChange)="handleLifeCycleChange($event)" [(ngModel)]="lifecycleStatus">
              <mat-option value="none">
                None
              </mat-option>
              <mat-option *ngFor="let opt of lifeCycleOptions" value="{{opt}}">{{opt}}</mat-option>
            </mat-select>
          </mat-form-field>
          <ng-container *ngIf="disableRoute && lifeCycleOptions.length>0">
            <div class="form-group">
              <label for="lifecycle-select" class="selection-label">Lifecycle Status</label>
              <select id="lifecycle-select" class="selection-input" (change)="handleLifeCycleChange($event)"
                [(ngModel)]="lifecycleStatus">
                <option value="none">None</option>
                <option *ngFor="let opt of lifeCycleOptions" [value]="opt">{{opt}}</option>
              </select>
            </div>
          </ng-container>

        </div>
      </div>
      <div class="fs-flex" style="justify-content: space-around;">
        <div class="metric-score" alt-text>
          <div class="kpi">{{item.shortTermRateOfSale}}&nbsp;<span class="compare-kpi"><span
                class="{{this.rosTrendIconColor}}">{{this.rosDifference}}%</span></span></div>
          <label>Rate of Sale <mat-icon class="tool-tip" matTooltip="7 days v/s 30 days ros">info</mat-icon></label>
        </div>
        <mat-divider [vertical]="true"></mat-divider>
        <div class="metric-score">
          <div class="kpi">{{item.gross_margin}}%</div>
          <label>Gross Margin</label>
        </div>
        <mat-divider *ngIf="showAnalytics" [vertical]="true"></mat-divider>
        <div class="metric-score" *ngIf="showAnalytics">
          <div class="kpi">{{item.conversion_rate}}</div>
          <label>Conversion Rate</label>
        </div>
      </div>
    </div>
  </div>
  <div class="product-performance fs-flex" style="justify-content: space-around;">
    <div class="metric-score">
      <div class="kpi">₹{{item.maximum_retail_price}}</div>
      <label>MRP</label>
    </div>
    <mat-divider [vertical]="true"></mat-divider>
    <div class="metric-score">
      <div class="kpi">{{this.price}}</div>
      <label>Sale Price</label>
    </div>
    <mat-divider [vertical]="true"></mat-divider>
    <div class="metric-score">
      <div class="kpi"><span *ngIf="this.costPrice !== '  -'">₹</span>{{this.costPrice}}</div>
      <label>Cost Price</label>
    </div>
    <mat-divider [vertical]="true"></mat-divider>
    <div class="metric-score">
      <div class="kpi">{{this.discount}}</div>
      <label>Discount</label>
    </div>
    <mat-divider [vertical]="true"></mat-divider>
    <div class="metric-score">
      <div class="kpi" *ngIf="item.returnPercentage && item.returnPercentage != null">{{item.returnPercentage}}%</div>
      <div class="kpi" *ngIf="!item.returnPercentage">-</div>
      <label>Return</label>
    </div>
  </div>
</div>