<!-- <mat-toolbar class="header display-flex justify-content-center">
  <span>Work Order Management</span>
</mat-toolbar> -->

<div class="fs-section-block fs-margin-0 header-background">
    <span class="mat-headline">Work Order Management</span>
    <p class="fs-hint-text">List of Work Orders
    </p>
</div>

<div class='row table-header'>
<button class=" mobile_margin" mat-button [disabled]='!add' mat-raised-button color="primary" routerLink='/bulkUpload'
[queryParams]="{page: 'SCMManagement'}">Bulk Upload
</button>

<button class=" mobile_margin" mat-button [disabled]='!add' mat-raised-button color="primary" routerLink='/SCMForm'>Add</button>
</div>
<app-syncfusion-grid [dynamic_headers]="headers" [data]="SCMTableData" [reportName]="'SCM_Management'" (dataEmitted)="gridRowData($event)"></app-syncfusion-grid>
