import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {api} from '../properties/endpoints';

@Injectable({
  providedIn: 'root'
})
export class ChartjsReportsService {
  private chatJS_charts: { [url: string]: any } = {};


  constructor(private http: HttpClient) { }
  getReports(chatID){
    const url=`${api.host}/reports/chartjs/${chatID}`
    return this.http.get(url);
  }
  updateFilter(chatID,filter){
    const url=`${api.host}/reports/chartjs/${chatID}`
    return this.http.post(url,filter);
  }
  getReportWithCache(reportName): Promise<any>{
    const cacheKey=`${api.host}/reports/chartjs/${reportName}`

    if (this.chatJS_charts[cacheKey]) {
      return Promise.resolve(this.chatJS_charts[cacheKey]);
    }
    else {
      return this.http.get(cacheKey)
        .toPromise()
        .then(response => {
          this.chatJS_charts[cacheKey] = response;
          return response;
        });
    }

  }
}
