<div *ngIf="!filterOnScoreCard && !errorInScoreCard" class="scorecard-container">
  <ng-container *ngFor="let number of [0, 1, 2, 3, 4]">
    <div class="score-card mat-elevation-z4" [ngClass]="{ 'dashboard-summary-card': isDashboardSummary }">
      <div style="font-weight: lighter; width: 40%; height: 0.8em" class="flowsmart-placeholder"></div>
      <div class="flowsmart-placeholder template"></div>
    </div>
  </ng-container>
</div>
<div *ngIf="scoreCardData == null && filterOnScoreCard">
  <h3 style="text-align: center">No Data Available</h3>
</div>
<div *ngIf="filterOnScoreCard">
  <div class="scorecard-container">
    <ng-container *ngFor="let scorecard of scoreCardData">
      <div *ngIf="
          scorecard.code != 'gross_margin' &&
          scorecard.code != 'discount_rate' &&
          scorecard.code != 'total_qty_sold'
        " class="score-card mat-elevation-z4 {{scorecard.code}}" [ngClass]="{ 'dashboard-summary-card': isDashboardSummary }">
        <div class="name">
          {{ scorecard.name | capitalize }}
        </div>
        <div class="value-container">
          <div class="icon">
            <!--populated through css-->
          </div>
          <div class="value">
            <div>
              {{scorecard.value | currencyFormat : "INR"}}<sub *ngIf="scorecard.value >= 100000">{{
                scorecard.value >= 10000000 ? "Cr" : "Lac"
                }}</sub> <span class="change-percent {{scorecard.changeDirection}}"
                *ngIf="scorecard.changePercent" [ngClass]="{ 'negative': isDashboardSummary }">{{scorecard.changePercent}}</span>
            </div>

            <div *ngIf="scorecard.compareValue" class="compare-value">
              {{scorecard.compareValue | currencyFormat : "INR"}}<sub *ngIf="scorecard.compareValue >= 100000">{{
                scorecard.compareValue >= 10000000 ? "Cr" : "Lac"
                }}</sub>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="
          (scorecard.code == 'discount_rate' || scorecard.code == 'gross_margin') &&
          scorecard.code != 'total_qty_sold'
        " class="score-card mat-elevation-z4 {{scorecard.code}}" [ngClass]="{ 'dashboard-summary-card': isDashboardSummary }">
        <div class="name">
          {{ scorecard.name | capitalize }}
        </div>
        <div class="value-container">
          <div class="icon">
            <!--populated through css-->
          </div>
          <div class="value">
            <div class="actual">
              {{ scorecard.value | number:'1.2'}}<sub>%</sub> <span class="change-percent {{scorecard.changeDirection}}"
                *ngIf="scorecard.changePercent" [ngClass]="{ 'negative': isDashboardSummary }"  >{{scorecard.changePercent}}</span>
            </div>
            <div *ngIf="scorecard.compareValue != null" class="compare-value">
              {{ scorecard.compareValue | number:'1.2'}}<sub>%</sub>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="scorecard.code == 'total_qty_sold'" class="score-card mat-elevation-z4 {{scorecard.code}}" [ngClass]="{ 'dashboard-summary-card': isDashboardSummary }">
        <div class="name">
          {{ scorecard.name | capitalize }}
        </div>
        <div class="value-container">
          <div class="icon">
            <!--populated through css-->
          </div>
          <div class="value">
            <div class="actual">
              {{ scorecard.value | number:'1.0'}} <span class="change-percent {{scorecard.changeDirection}}"
                *ngIf="scorecard.changePercent" [ngClass]="{ 'negative': scorecard.changeDirection === 'minus' && isDashboardSummary}">{{scorecard.changePercent}}</span>
            </div>
            <div *ngIf="scorecard.compareValue" class="compare-value">
              {{scorecard.compareValue | number:'1.0'}}
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div *ngIf="dateRange.startDate" class="lookup">
    <span>Lookup Period: {{ dateRange.startDate | date: 'dd-MMM-yyyy' }} to {{ dateRange.endDate | date: 'dd-MMM-yyyy'
      }}</span>
    <span *ngIf="compareDateRange.startDate">Compare Period: {{ compareDateRange.startDate | date: 'dd-MMM-yyyy' }} to
      {{ compareDateRange.endDate | date: 'dd-MMM-yyyy' }}</span>

  </div>
</div>