export const menu = [
  {
    header: 'Production Planning',
    icon: 'inventory',
    child: [
      { name: 'Planning Report', route: 'grid', entity: 'production_intents', key: 'Production_Intent_Planning' },
      { name: 'Manage Work Orders', route: 'WorkOrders', entity: 'production_intents' },
      { name: 'Production Intent Report', route: 'grid', entity: 'production_intents', key: 'Production_Intent' },
      { name: 'RM - Purchase Order Report', route: 'grid', entity: 'production_intents', key: 'RM_Procurement_Plan' }
    ]
  },
  {
    header: 'supplier communication',
    icon: 'description',
    child: [
      {
        name: 'Stock Planning',
        route: 'planningStock',
        entity: 'stockPlanning',
      }
    ]
  },
  {
    header: 'parameter mangement',
    icon: 'widgets',
    child: [
      {
        name: 'Materials',
        route: 'material',
        entity: 'material',
      },
      {
        name: 'Buffer Criteria',
        route: 'stock',
        entity: 'stockcriteria'
      },
      {
        name: 'Locations',
        route: 'locations',
        entity: 'location',

      },
      {
        name: 'Share of Business',
        route: 'shareofbusiness',
        entity: 'shareofbusiness'
      },
      {
        name: 'Stock Forecast',
        route: 'suggestion',
        entity: 'dynamicnorm'
      },
      {
        name: 'Sales Forecast',
        route: 'salesforecast',
        entity: 'dynamicnorm'
      }
      ,
      {
        name: 'Dynamic Norm Review',
        route: 'dynamicnorm-review',
        entity: 'dynamicnorm'
      }
    ]
  },
  {
    header: 'Attribute Management',
    icon: 'store_mall_directory',
    child: [
      { name: 'Materials', route: 'parameter-mgt-material', entity: 'material' },
      { name: 'Locations', route: 'parameter-mgt-locations', entity: 'location' },
      { name: 'Stock Criteria', route: 'parameter-mgt-stock-criteria', entity: 'stockcriteria' },
      { name: 'Share Of Business', route: 'parameter-mgt-share-of-business', entity: 'shareofbusiness' }
    ]
  },
  {
    header: 'User Management',
    icon: 'supervised_user_circle',
    child: [
      { name: 'Users', route: 'users', entity: 'user' }
    ]
  }
  , {
    header: 'Data processing',
    icon: 'file_copy',
    child: [
      {
        name: 'Upload Files',
        route: 'upload',
        entity: 'integration',
      }
    ]
  }
];




export const users = [
  {
    username: 'user@gstool.com',
    password: '123456'
  },
  {
    username: 'user@vnenterprise.com',
    password: '123456'
  },
  {
    username: 'user@goodwillindustries.com',
    password: '123456'
  },
  {
    username: 'warehousemanager@oceaniasolution.com',
    password: '123456'
  }
];

