import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups'
import { WorkflowService } from 'src/app/services/workflow.service';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-workflow-configure-modal-molecule',
  templateUrl: './workflow-configure-modal-molecule.component.html',
  styleUrls: ['./workflow-configure-modal-molecule.component.scss']
})
export class WorkflowConfigureModalMoleculeComponent {
  @ViewChild('ejDialog') ejDialog: DialogComponent;
  @Input() showModal: boolean;
  @Output() showModalChange = new EventEmitter<boolean>();
  @Input() data: any;
  @Output() save = new EventEmitter<any>();
  @Output() changeLabels = new EventEmitter<any>();

  dialogVisible: boolean;
  header: string;
  nodeType: string = '';
  entities: any[] = [];
  comparisonOptions: string[] = [];
  conditionTypes: string[] = [];
  entityAttributes: any[] = [];
  actionEntityAttr: any[] = [];
  entityFormGroup: FormGroup;
  stepFormGroup: FormGroup;
  conditionFormGroup: FormGroup;
  evaluationFormGroup: FormGroup;
  actionFormGroup: FormGroup;
  evaluationTypes: string[];
  conditionCodes: string[] = [];
  lastSelected: 'conditions' | 'evaluationTypes' = null;
  workflowData: any;
  stepId: string;
  nodeData: any;
  actions: string[];

  constructor(private workflowService: WorkflowService, private fb: FormBuilder, private snack: MatSnackBar) { }

  ngOnInit(): void {
    this.fetchEntities();
    this.fetchMetaData();
    this.fetchEvaluationTypes();
    this.entityFormGroup = this.fb.group({
      entity: ['', Validators.required],
      preFilterConditions: this.fb.array([])
    });
    this.stepFormGroup = this.fb.group({
      code: ['', Validators.required],
    });
    this.conditionFormGroup = this.fb.group({
      code: ['', Validators.required],
      compareAttribute: ['', Validators.required],
      compareValue: ['', Validators.required],
      conditionType: ['', Validators.required]
    });
    this.evaluationFormGroup = this.fb.group({
      conditions: [''],
      evaluationTypes: [''],
      evaluationValue: ['', Validators.required]
    });
    this.actionFormGroup = this.fb.group({
      code: ['', Validators.required],
      entity: ['', Validators.required],
      actionType: ['', Validators.required],
      attributes: this.fb.array([])
    });

    this.nodeType = this.data?.type;
    this.stepId = this.data?.stepId;
    this.workflowData = this.data?.workflowData;

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && this.data) {
      this.nodeType = this.data?.type;
      this.header = this.nodeType ? `Configure ${this.nodeType}` : 'Configure Node';
      this.stepId = this.data?.stepId;
      this.nodeData = { ...this.data?.data };
      this.workflowData = this.data?.workflowData;
      this.updateForm(this.data);
      this.conditionCodes = this.getConditionCodesForStep();
    }
    if (changes['showModal']) {
      this.dialogVisible = this.showModal;
    }
  }

  updateForm(data: any) {
    console.log("In Update form", data);
    const entityObj = this.entities?.find((ele: any) => ele.code === data?.workflowData?.entity) || '';
    this.entityAttributes = entityObj?.attributes;
    const step = data?.workflowData?.steps?.find((step: any) => step.id === data?.id);
    const stepForChild = data?.workflowData?.steps?.find((step: any) => step.id === data?.stepId);
    const condition = stepForChild?.conditions?.find((condition: any) => condition.id === this.data?.id);
    const action = stepForChild?.actions?.find((ele: any) => ele.id === this.data?.id);
    const evaluation = stepForChild?.evaluationExpression?.replace(/^\(|\)$/g, '');
    const preFilterConditions = data?.workflowData?.preFilterConditions || [];
    let preFilterConditionsFormArray = this.entityFormGroup?.get('preFilterConditions') as FormArray;
    preFilterConditionsFormArray?.clear();

    preFilterConditions.forEach((condition: any) => {
      const compareAttribute = this.entityAttributes?.find((attr: any) => attr.code === condition?.compareAttribute);
      const conditionType = this.comparisonOptions?.find((ele: any) => ele === condition?.conditionType)
      preFilterConditionsFormArray?.push(this.fb.group({
        compareAttribute: compareAttribute?.code || '',
        compareValue: condition?.compareValue || '',
        conditionType: conditionType || ''
      }));
    });
    this.entityFormGroup?.setControl('preFilterConditions', preFilterConditionsFormArray);
    this.entityFormGroup?.patchValue({
      entity: data?.workflowData?.entity || '',
    });

    this.stepFormGroup?.patchValue({
      code: step?.code || '',
    });
    const compareAttribute = this.entityAttributes?.find((attr: any) => attr.code === condition?.compareAttribute);
    this.conditionFormGroup?.patchValue({
      code: condition?.code || '',
      compareAttribute: compareAttribute?.code || '',
      compareValue: condition?.compareValue || '',
      conditionType: condition?.conditionType || ''
    });
    const actionEntityObj = this.entities?.find((ele: any) => ele.code === action?.entity);
    this.actionEntityAttr = actionEntityObj?.attributes;
    const actionType = this.actions?.find((ele: any) => ele === action?.actionType);
    const attributes = action?.attributes || [];
    let actionAttributesFormArray = this.actionFormGroup?.get('attributes') as FormArray;
    actionAttributesFormArray?.clear();

    attributes.forEach((item: any) => {
      const attribute = this.actionEntityAttr?.find((attr: any) => attr.code === item?.attribute);
      actionAttributesFormArray?.push(this.fb.group({
        attribute: attribute?.code || '',
        value: item?.value || '',
        unique: item?.unique || false,
        lookupAttribute: item?.lookupAttribute || '',
        lookup: !!item?.lookupAttribute
      }));
    });
    this.actionFormGroup?.setControl('attributes', actionAttributesFormArray);
    this.actionFormGroup?.patchValue({
      code: action?.code || '',
      entity: action?.entity || '',
      actionType: actionType || '',
    });

    this.evaluationFormGroup?.patchValue({
      conditions: '',
      evaluationTypes: '',
      evaluationValue: evaluation || ''
    });

  }

  getConditionCodesForStep(): string[] {
    const step = this.data?.workflowData?.steps?.find((step: any) => step.id === this.data?.stepId);
    return step ? step?.conditions?.map((condition: any) => condition?.code) : [];
  }
  onDropdownChange(selectedDropdown: 'conditions' | 'evaluationTypes'): void {
    const conditionsControl = this.evaluationFormGroup.get('conditions');
    const evaluationTypesControl = this.evaluationFormGroup.get('evaluationTypes');
    const evaluationValueControl = this.evaluationFormGroup.get('evaluationValue');

    const selectedCondition = conditionsControl.value;
    const selectedEvaluationType = evaluationTypesControl.value;
    let currentTextInputValue = evaluationValueControl.value || '';

    if (selectedDropdown === 'conditions' && this.lastSelected !== 'conditions') {
      currentTextInputValue = `${currentTextInputValue.trim()} ${selectedCondition}`.trim();
      evaluationValueControl.setValue(currentTextInputValue);
      this.lastSelected = 'conditions';
    } else if (selectedDropdown === 'evaluationTypes' && this.lastSelected !== 'evaluationTypes') {
      currentTextInputValue = `${currentTextInputValue.trim()} ${selectedEvaluationType}`.trim();
      evaluationValueControl.setValue(currentTextInputValue);
      this.lastSelected = 'evaluationTypes';
    }
  }

  fetchEntities() {
    this.workflowService.getEntities().subscribe((entities: any) => this.entities = entities);
  }
  fetchMetaData() {
    this.workflowService.getMetaData().subscribe((data: any) => {
      this.comparisonOptions = data.preFilterConditions;
      this.conditionTypes = data.filterConditions;
      this.actions = data.actions;
    })
  }
  fetchEvaluationTypes() {
    this.workflowService.getEvaluationTypes().subscribe((res: any) => this.evaluationTypes = res);
  }
  get preFilterConditions() {
    return this.entityFormGroup.get('preFilterConditions') as FormArray;
  }
  get attributes() {
    return this.actionFormGroup.get('attributes') as FormArray;
  }

  onEntityChange(entity: any): void {
    this.entityFormGroup.get('entity')?.setValue(entity);
    const entityObj = this.entities.find((ele: any) => ele.code === entity);
    this.entityAttributes = entityObj.attributes;
  }
  onActionEntityChange(actionEntity: any): void {
    this.actionFormGroup.get('entity')?.setValue(actionEntity);
    const actionEntityObj = this.entities.find((ele: any) => ele.code === actionEntity);
    this.actionEntityAttr = actionEntityObj.attributes;
  }
  onAttributeChange(condition: any, attribute: string): void {
    condition.get('compareAttribute').setValue(attribute);
  }

  addPrefilterCondition(): void {
    this.preFilterConditions.push(this.fb.group({
      compareAttribute: ['', Validators.required],
      compareValue: ['', Validators.required],
      conditionType: ['', Validators.required]
    }));
  }
  addAttributes(): void {
    this.attributes.push(this.fb.group({
      attribute: ['', Validators.required],
      value: [''],
      unique: [false],
      lookup: [false],
      lookupAttribute: ['']
    }, { validators: this.valueOrLookupValidator() }));
  }
  valueOrLookupValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const formGroup = control as FormGroup;
      const lookup = formGroup.get('lookup')?.value;
      const lookupAttributeControl = formGroup.get('lookupAttribute');
      const valueControl = formGroup.get('value');

      if (lookup && !lookupAttributeControl?.value) {
        lookupAttributeControl?.setErrors({ lookupInvalid: true });
      } else {
        lookupAttributeControl?.setErrors(null);
      }

      if (!lookup && !valueControl?.value) {
        valueControl?.setErrors({ valueOrLookupRequired: true });
      } else {
        valueControl?.setErrors(null);
      }

      return null;
    };
  }

  toggleLookup(index: number): void {
    const attributeGroup = this.attributes.at(index) as FormGroup;
    const lookupControl = attributeGroup.get('lookup');
    const valueControl = attributeGroup.get('value');
    const lookupAttributeControl = attributeGroup.get('lookupAttribute');

    if (lookupControl?.value) {
      valueControl?.disable();
      lookupAttributeControl?.enable();
    } else {
      valueControl?.enable();
      lookupAttributeControl?.disable();
    }
  }

  removeAttribute(index: number): void {
    this.attributes.removeAt(index);
  }
  removePrefilterCondition(index: number): void {
    this.preFilterConditions.removeAt(index);
  }

  onClose(): void {
    this.dialogVisible = false;
    this.showModal = false;
    this.showModalChange.emit(this.showModal);
  }
  showSnackbar(message: string) {
    this.snack.open(message, 'close', {
      duration: 4000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }

  onSave(): void {
    const nodeConfigs = [
      { type: 'Entity', formGroup: this.entityFormGroup, labelKey: 'entity' },
      { type: 'Step', formGroup: this.stepFormGroup, labelKey: 'code' },
      { type: 'Condition', formGroup: this.conditionFormGroup, labelKey: 'code' },
      { type: 'Evaluation', formGroup: this.evaluationFormGroup, labelKey: 'code' },
      { type: 'Action', formGroup: this.actionFormGroup, labelKey: 'code' }
    ];

    nodeConfigs.forEach(({ type, formGroup, labelKey }) => {
      if (this.nodeType === type && formGroup.invalid) {
        formGroup.markAllAsTouched();
        return;
      }

      if (this.nodeType === type && formGroup.valid) {
        const formData = formGroup.value;
        this.nodeData = { id: this.data.id, type: this.data.type, ...formData };

        if (type === 'Step') {
          if (this.entityFormGroup.invalid) {
            this.showSnackbar('Please Configure Entity before proceeding');
            return;
          }
        }

        if (type !== 'Evaluation') {
          this.changeLabels.emit({ key: type, value: formData[labelKey] });
        }

        if (type === 'Condition') {
          if (this.workflowData.steps.length == 0) {
            this.showSnackbar('Please Configure Step before proceeding');
            return;
          }
        }
        if (type === 'Evaluation') {
          if (this.conditionCodes.length == 0) {
            this.showSnackbar('Please Configure Condition before proceeding');
            return;
          }
          this.nodeData.evaluation = `(${formData.evaluationValue.trim()})`;
        }
        if (type === 'Action') {
          if (this.workflowData.steps.length == 0) {
            this.showSnackbar('Please Configure Step before proceeding');
            return;
          }
          if (!this.nodeData.attributes) {
            this.nodeData.attributes = [];
          }
          const attributes = formGroup.get('attributes') as FormArray;
          if (attributes) {
            const attributeValues = attributes.value;
            const transformedAttributes = attributeValues.map((attr: any) => {
              let result: any = {
                attribute: attr?.attribute,
              };

              if (attr.lookup) {
                result.lookupAttribute = attr?.lookupAttribute;
              } else {
                result.value = attr?.value;
              }
              if (attr.unique) {
                result.unique = attr?.unique;
              }

              return result;
            });
            this.nodeData.attributes = transformedAttributes.filter(attr => attr.lookupAttribute || attr.value);
          }
        }
        this.save.emit(this.nodeData);
        formGroup.reset();
        this.lastSelected = null;
        this.onClose();
      }
    });
  }

}
