import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { ReportsService } from "src/app/services/reports.service";

@Component({
  selector: "app-bom-availability-state",
  templateUrl: "./bom-availability-state.component.html",
  styleUrls: ["./bom-availability-state.component.scss"],
})
export class BOMAvailabilityState implements OnInit {
  @Input() sku: string = null;
  @Input() location: string = null;
  @Input() displayName: string = null;
  @Input() tableHeight: number = 300;
  isLoading: boolean = true;
  bom_data: any;
  bom_header: Array<any>;
  headers: Array<any> = [
    {
      "field": "code",
      "headerText": "RM Code",
      "allowFiltering": true,
      "width": "120",
      "filter": {
        "type": "Menu"
      }
    },
    {
      "field": "name",
      "headerText": "RM Name",
      "allowFiltering": false,
      "width": "180"
    },
    {
      "field": "soh",
      "headerText": "Stock On hand",
      "allowFiltering": true,
      "width": "150",
      "filter": {
        "type": "Excel"
      }
    },
    {
      "field": "reservedQty",
      "headerText": "Alloted Qty",
      "allowFiltering": false,
      "width": "100"
    },
    {
      "field": "availableQty",
      "headerText": "Free Stock",
      "width": "100",
      "allowFiltering": false
    },
    {
      "field": "replenishmentDemandQty",
      "headerText": "To Be Planned Qty",
      "allowFiltering": true,
      "width": "150",
      "filter": {
        "type": "Excel"
      }
    },
    {
      "field": "unitOfMeasurement",
      "headerText": "UOM",
      "allowFiltering": false,
      "width": "100"
    }
  ];

  constructor(private reportsService: ReportsService) { }
  ngOnInit(): void {
    if(this.sku !=null){
      this.headers.push({
        "field": "ratio",
        "headerText": "BOM for Product",
        "allowFiltering": false,
        "width": "100",
  
      });
    }
    this.reportsService.getBillMaterialAvailability(this.sku, this.location).subscribe(
      (res:Array<any>) => {
        this.bom_data = {
          displayname: this.displayName,
          data: res,
          count: res.length,
          headers: this.headers
        };
        
      },
      (error) => {
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );

  }

}
