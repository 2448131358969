import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CookieModule } from 'ngx-cookie';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './material.module';
import { TemplatesModule } from './templates/templates.module';
import { SharedModule } from './shared/shared.module';
import { TokenInterceptorService } from './interceptors/login-interceptor';
import { ToastrModule } from 'ngx-toastr';
import { GraphQLModule } from './graphql.module'
import { ExcelExportService, FreezeService, GroupService, PdfExportService, SortService, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { registerLicense } from '@syncfusion/ej2-base';
import { key } from './properties/syncfusion-key';

registerLicense(key);
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CookieModule.forRoot(),
    BrowserAnimationsModule,
    MaterialModule,
    TemplatesModule,
    SharedModule,
    ToastrModule.forRoot(),
    BrowserModule,
    HttpClientModule,
    GraphQLModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true }, ToolbarService, ExcelExportService, PdfExportService, GroupService, SortService, FreezeService],
  bootstrap: [AppComponent]
})
export class AppModule { }
