<app-header-content-help-text [Heading]="reportNameWithSpace" [helpText]="subHeadings[reportNameWithSpace]"></app-header-content-help-text>
<ng-container>
  <div *ngIf="filterValues">
    <app-buffer-trend-molecules [chatID]="reportName" [selectedFilters]="selectedFilters" [filterValues]="filterValues"
    (getReportsByFilters)="getReportsByFilters($event)" [addFlt]="addFilter"></app-buffer-trend-molecules>
  </div>
  </ng-container>
    <div>
      <canvas width="300px" height="650px" class="chart" #myChart></canvas>
    </div>

