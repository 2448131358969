<div class="bom-container" *ngIf="isLoading">
  <ng-container *ngFor="let number of [0, 1, 2, 3, 4]">
    <div class="holder-card mat-elevation-z4">
      <div style="font-weight: lighter; width: 60%; height: 2em" class="flowsmart-placeholder"></div>
      <div style="font-weight: lighter; width: 80%; height: 2em" class="flowsmart-placeholder"></div>
      <div style="font-weight: lighter; width: 80%; height: 2em" class="flowsmart-placeholder"></div>
      <div style="font-weight: lighter; width: 95%; height: 1em" class="flowsmart-placeholder"></div>
    </div>
  </ng-container>
</div>
<div *ngIf="!isLoading && bom_data">
  <ng-container *ngIf="bom_data.data.length == 0">
    <p>No Bill of materials defined for the product in the system.</p>
  </ng-container>
  <ng-container *ngIf="bom_data.data.length > 0">
    <div style="margin: 1em;">
    <app-dashboard-grids-molecule class="chartjs-charts"
          [header]="bom_header" [chartData]="bom_data" [tableHeight]="tableHeight"></app-dashboard-grids-molecule>
    </div>
  </ng-container>
</div>