<mat-form-field>
  <mat-label>Enter a month and year range</mat-label>
  <div style="display: flex; justify-content: space-between;">
    <mat-date-range-input [rangePicker]="picker">
      <input matInput [formControl]="startDate" matStartDate   placeholder="Start month and year" />
      <input matInput [formControl]="endDate" matEndDate  placeholder="End month and year" />
    </mat-date-range-input>
    <mat-date-range-picker #picker
    startView="multi-year"
                  (monthSelected)="setMonthAndYear($event, picker)"
                  panelClass="example-month-picker"
    ></mat-date-range-picker>
    <mat-datepicker-toggle style="width: 1.5rem; margin: -10px 0" matIconSuffix [for]="picker"></mat-datepicker-toggle>
  </div>
</mat-form-field>
