import { Component, OnInit } from "@angular/core";
import { DatePipe } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { SyncfusionService } from "src/app/services/syncfusion.service";
import { AppSettingService } from "src/app/services/app-setting.service";
import { DataResult, DataStateChangeEventArgs } from "@syncfusion/ej2-angular-grids";
@Component({
  selector: "app-historical-reports",
  templateUrl: "./historical-reports.component.html",
  styleUrls: ["./historical-reports.component.scss"],
  providers: [DatePipe],
})
export class HistoricalReportsComponent implements OnInit {
  dateList: string[] = [];
  dataList: any;
  columns: any;
  dynamicColumns: string[];
  reportName: any;
  reportNameWithSpace: any;
  today = new Date();

  constructor(
    private route: ActivatedRoute,
    private syncfusion: SyncfusionService,
    private datePipe: DatePipe,
    private settingsService: AppSettingService
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params && params.reportName) {
        this.reportName = params.reportName.replace("Trend", "Report");
        // this.reportNameWithSpace = params.reportName.replace(/_/g, " ");
        if (this.settingsService.isRemoteGridMode()) {
          this.syncfusion.getPaginatedSyncfusionData(this.reportName).subscribe((res) => {
            if (res) {
              this.dataList = (<DataResult>{
                result: res.items,
                count: res.pageData ? res.pageData.totalRecords : res.count
              });
              this.syncfusion.setExportData(res);
              this.reportNameWithSpace = res["displayName"];
            }
          });
        } else {
          syncfusion.getSyncfusionData(this.reportName).then((res) => {
            this.reportNameWithSpace = res["displayName"];
            this.dataList = res["items"];
          });
        }
      }
    });
    const currentDate = new Date(); // Get the current date

    for (let i = 0; i <= 9; i++) {
      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - i
      );

      const formattedDate = this.datePipe.transform(date, "dd-MMM-yyyy");
      if (formattedDate) {
        this.dateList.push(formattedDate);
      }
    }
    this.columns = [
      {
        headerText: "Material",
        field: "materialCode",
      },
      {
        headerText: "Style",
        field: "parentProductCode",
      },
      {
        headerText: "Location",
        field: "locationCode",
      },
      {
        headerText: "Description",
        field: "itemDescription",
      },
      {
        headerText: "Image",
        field: "imageUrl",
        image: true,
      },
    ];
    this.dynamicColumns = this.columns.concat(this.dateList.reverse());
  }
  ngOnInit(): void { }

  navigateGrid(state: DataStateChangeEventArgs) {
    this.syncfusion.getPaginatedSyncfusionData(this.reportName,this.syncfusion.convertGridStateToPageData(state)).subscribe((res) =>{
      if (res) {
        this.dataList = (<DataResult>{
          result: res.items,
          count: res.pageData ? res.pageData.totalRecords : res.count
        });
        this.syncfusion.setExportData(res);
      }
    },(error)=>{
      console.error(error);
    })
  }
}
