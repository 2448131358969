import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SyncfusionService } from 'src/app/services/syncfusion.service';
import { ListingGridService } from "src/app/services/listing-grid.service";


@Component({
  selector: 'app-predict-promotion-listing-organisms',
  templateUrl: './predict-promotion-listing-organisms.component.html',
  styleUrls: ['./predict-promotion-listing-organisms.component.scss']
})
export class PredictPromotionListingOrganismsComponent {
  queryParamsSubscription: any;
  dynamic_headers: any[];
  isChecked: boolean = false;
  data: any;
  tableDataFetched: boolean;
  count: any;
  fieldData: any;
  headerData: any;
  visiblePOPUP: boolean;
  headers: Array<any> = [
    {
      "field": "decision",
      "headerText": "",
      "width": "50",
      "accordian": "rateOfSale",
      "decisionAttr": "code"
    },
    {
      "field": "code",
      "headerText": "Product",
      "allowFiltering": true,
      "width": "120",
      "filter": {
        "type": "Menu"
      }
    },
    {
      "field": "imageUrl",
      "headerText": "Image",
      "width": "120",
      "image": true
    },
    {
      "field": "mediumTermDOS",
      "headerText": "Days of Stock",
      "allowFiltering": true,
      "width": "100",
      "filter": {
        "type": "Excel"
      }
    },
    {
      "field": "name",
      "headerText": "Name",
      "allowFiltering": false,
      "width": "240"
    },
    {
      "field": "latestDateSold",
      "headerText": "Last Sold On",
      "allowFiltering": false,
      "width": "100",
      "filter": {
        "type": "Excel"
      }
    },
    {
      "field": "mediumTermRateOfSale",
      "headerText": "Rate of Sale - 30 days",
      "allowFiltering": true,
      "width": "150",
      "filter": {
        "type": "Excel"
      }
    },
    {
      "field": "longTermRateOfSale",
      "headerText": "Rate of Sale - 90 days",
      "allowFiltering": true,
      "width": "150",
      "filter": {
        "type": "Excel"
      }
    },
    {
      "field": "maximum_retail_price",
      "headerText": "MRP",
      "allowFiltering": false,
      "width": "100"
    },
    {
      "field": "min_sell_price",
      "headerText": "Sell price",
      "allowFiltering": false,
      "width": "100"
    },
    {
      "field": "min_discount_percent",
      "headerText": "Discount %",
      "allowFiltering": false,
      "width": "100"
    },
    {
      "field": "lifeCycleStatus",
      "headerText": "Status",
      "allowFiltering": true,
      "filter": {
        "type": "Excel"
      },
      "width": "100"
    },
    {
      "field": "categoryLevel1",
      "headerText": "Category Level1",
      "allowFiltering": true,
      "filter": {
        "type": "Menu"
      },
      "width": "150"
    },
    {
      "field": "brand",
      "headerText": "Brand",
      "allowFiltering": true,
      "filter": {
        "type": "Menu"
      },
      "width": "150"
    },
  ];
  constructor(private route: ActivatedRoute, private syncfusion: SyncfusionService, private listingGridService: ListingGridService
  ) {

  }

  ngOnInit() {
    this.fetchData(false);
  }

  fetchData(flag) {
    this.tableDataFetched = false;
    this.listingGridService.getPredictedPromotionalListings(flag).subscribe((res: []) => {
      if (res) {
        this.data = res;
        this.count = res.length;
        this.dynamic_headers = this.headers;
        setTimeout(() => {
          this.tableDataFetched = true;
        });
      }
    });
  }

  showAllData() {
    this.fetchData(this.isChecked);
  } 
  
  handlePOPUP(event) {
    this.fieldData = event.fieldData;
    this.headerData = event.header;
    this.visiblePOPUP = true;
  }

}
