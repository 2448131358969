import { Injectable, Inject } from '@angular/core';
import { CookieService } from 'ngx-cookie';

export enum EventType {
    GRID_TOOLBAR_ACTION = "grid_toolbar_action",
    GRID_COLUMN_ACTION = "grid_column_action",
    SEARCH = "search",
    FILTER = "filter",
    SORT = "sort",
    PAGINATE = "paginate",
    TAB_ACTION = "tab_click",
    OPENAI_CHAT = "openai_chat",
}

@Injectable({
    providedIn: 'root'
  })
export class ClickStreamAnalyticsService {

    private browserGlobals = {
        windowRef(): any {
            return window;
        },
        documentRef(): any {
            return document;
        },
    };

    constructor(
        private cookieService: CookieService) { }

    private getDataLayer(): any[] {
        const window = this.browserGlobals.windowRef();
        window.dataLayer = window.dataLayer || [];
        return window.dataLayer;
    }


    public publishGAEvent(eventName: EventType, variables: any) {
        this.getDataLayer().push({
            event: 'user_interaction',
            sub_event: eventName,
            ...variables
        })
    }
}



