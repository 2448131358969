// import { Injectable } from '@angular/core';
// import { HttpClient } from '@angular/common/http';
// import { Observable, of } from 'rxjs';
// import { map } from 'rxjs/operators';

// @Injectable({
//   providedIn: 'root'
// })
// export class ChatbotService {

//   private apiUrl = 'https://sandbox.api.flowsmart.ai/reports/ai-assistant/ask-hawk';
//   private threadID: string | null = null;

//   constructor(private http: HttpClient) { }

//   getResponse(userInput: string): Observable<string> {
//     const requestBody = {
//       text: userInput,
//       threadID: this.threadID
//     };

//     return this.http.post<any>(this.apiUrl, requestBody).pipe(
//       map(response => {
//         this.threadID = response.threadID; // Update threadID for subsequent requests
//         const messages = response.messages.map((msg: any) => msg.content).join('\n');
//         return messages;
//       })
//     );
//   }
// }

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { api } from '../properties/endpoints';

@Injectable({
  providedIn: 'root'
})
export class ChatbotService {

  constructor(private http: HttpClient) { }

  getResponse(userInput: string,threadId: string|null): Observable<any> {
    let payload: any = {
      text: userInput,
    };
    if(threadId){
      payload.threadId = threadId;
    }
    let host = api.host;
    //host = "http://localhost:4000"
    return this.http.post<any>(`${host}/ai-assistant/ask-hawk`, payload)
      .pipe(map(response => {
        return response;
      }));
  }
}
