import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { FilterBayService } from "src/app/services/filter-bay.service";
import { DatePipe } from "@angular/common";
import { Observable, forkJoin } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "app-business-score-card",
  templateUrl: "./business-score-card.component.html",
  styleUrls: ["./business-score-card.component.scss"],
})
export class BusinessScoreCardComponent implements OnInit, OnChanges {
  @Input() changesFromParent: any;
  scoreCardData;
  filterOnScoreCard: boolean;
  errorInScoreCard: boolean;
  dateRange: { startDate?: string; endDate?: string } = {};
  compareDateRange: { startDate?: string; endDate?: string } = {};
  public datePipe = new DatePipe("en-US");
  @Input() isDashboardSummary: boolean = false;
  constructor(private filterbayservice: FilterBayService) {}

  ngOnInit(): void {
    this.loadScoreCardResults(null);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.changesFromParent &&
      (changes.changesFromParent.currentValue ||
        changes.changesFromParent.previousValue)
    ) {
      this.loadScoreCardResults(this.changesFromParent);
    }
  }

  loadScoreCardResults(payload: any): void {
    this.filterOnScoreCard = false;
    this.errorInScoreCard = false;
    this.scoreCardData = null;
    this.computeDateRanges(payload);
    payload = payload || {};
    if (payload) {
      payload.startDate = this.dateRange.startDate;
      payload.endDate = this.dateRange.endDate;
      let comparePayload = JSON.parse(JSON.stringify(payload));
      comparePayload.startDate = this.compareDateRange.startDate;
      comparePayload.endDate = this.compareDateRange.endDate;
      forkJoin([
        this.filterbayservice.applyFilterOnScoreCard(payload),
        this.filterbayservice.applyFilterOnScoreCard(comparePayload),
      ])
        .pipe(
          map(([response1, response2]) => {
            return [response1, response2];
          })
        )
        .toPromise()
        .then((values) => {
          let searchResponse = values[0];
          let compareResponse = values[1];
          this.scoreCardData = this.updateCompareValues(
            searchResponse["scoreCardList"],
            compareResponse["scoreCardList"]
          );
          this.filterOnScoreCard = true;
        })
        .catch((error) => {
          this.errorInScoreCard = true;
          this.filterOnScoreCard = true;
        });
    }
  }

  updateCompareValues(scoreCardData, compareScoreCardData) {
    if (compareScoreCardData && scoreCardData) {
      scoreCardData.forEach((card) => {
        let scoreCards = compareScoreCardData.filter(
          (scoreCard) => card.code == scoreCard.code
        );
        if (scoreCards && scoreCards.length > 0) {
          try {
            let compareValue = scoreCards[0].value;
            let changePercent = this.computeChangePercent(
              card.value,
              compareValue
            );
            card.compareValue = compareValue;
            card.changePercent = Math.abs(changePercent);
            card.changeDirection = changePercent > 0 ? "plus" : "minus";
          } catch (e) {
            console.error(e);
          }
        }
      });
    }
    return scoreCardData;
  }

  computeChangePercent(first: number, second: number): number {
    try {
      return parseFloat((((first - second) / second) * 100).toFixed(2));
    } catch (e) {
      console.error(e);
    }
    return null;
  }

  computeDateRanges(payload: any): void {
    if (payload != null && payload.startDate != null) {
      this.dateRange.startDate = payload.startDate;
    } else {
      let temp = new Date();
      temp.setDate(temp.getDate() - 30);
      this.dateRange.startDate = this.datePipe.transform(temp, "yyyy-MM-dd");
    }

    if (payload != null && payload.endDate != null) {
      this.dateRange.endDate = payload.endDate;
    } else {
      this.dateRange.endDate = this.datePipe.transform(
        new Date(),
        "yyyy-MM-dd"
      );
    }

    if (this.dateRange.endDate && this.dateRange.startDate) {
      let endDate: Date = new Date(this.dateRange.endDate);
      let startDate: Date = new Date(this.dateRange.startDate);
      let dateDifference = endDate.getTime() - startDate.getTime();
      startDate.setDate(startDate.getDate() - 1);
      this.compareDateRange.endDate = this.datePipe.transform(
        startDate,
        "yyyy-MM-dd"
      );
      this.compareDateRange.startDate = this.datePipe.transform(
        new Date(startDate.getTime() - dateDifference),
        "yyyy-MM-dd"
      );
    }
  }
}
