import { DatePipe } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";

import * as _moment from "moment";
import { default as _rollupMoment, Moment } from "moment";

const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: "MM/YYYY",
  },
  display: {
    dateInput: "MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
@Component({
  selector: "app-date-month-range-molecule",
  templateUrl: "./date-month-range-molecule.component.html",
  styleUrls: ["./date-month-range-molecule.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    DatePipe,
  ],
})
export class DateMonthRangeMoleculeComponent implements OnChanges, OnInit {
  @Input() removeMonthRange: boolean = false;
  @Input() returnsDateRange;
  @Output() DateMonthRange = new EventEmitter<{
    startDate: string;
    endDate: string;
  }>();
  constructor(public datepipe: DatePipe) {}
  ngOnInit(): void {
    this.startDate.setValue(this.returnsDateRange.startDate);
    this.endDate.setValue(this.returnsDateRange.endDate);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.removeMonthRange) {
      this.startDate.setValue(null);
      this.endDate.setValue(null);
    }
    this.removeMonthRange = false;
  }
  startDate = new FormControl();
  endDate = new FormControl();

  setMonthAndYear(event: Moment, picker: any) {
    const normalizedMonthAndYear: Moment = event;
    const ctrlValue = picker._startAt || moment();
    if (this.startDate.value && !this.endDate.value) {
      ctrlValue.month(normalizedMonthAndYear.month());
      ctrlValue.year(normalizedMonthAndYear.year());
      this.endDate.setValue(ctrlValue);
    } else if (!this.startDate.value && this.endDate.value) {
      ctrlValue.month(normalizedMonthAndYear.month());
      ctrlValue.year(normalizedMonthAndYear.year());
      this.startDate.setValue(ctrlValue);
      this.endDate.setValue("");
    } else {
      ctrlValue.year(normalizedMonthAndYear.year());
      ctrlValue.month(normalizedMonthAndYear.month());
      this.startDate.setValue(ctrlValue);
      this.endDate.setValue("");
    }
    picker.close();
    if (this.startDate.value && this.endDate.value) {
      const startDateFormat = new Date(this.startDate?.value);
      const endDateFormat = new Date(this.endDate?.value);
      const sDate = this.datepipe.transform(startDateFormat, "yyyy-MM");
      const eDate = this.datepipe.transform(endDateFormat, "yyyy-MM");
      if (eDate > sDate) {
        this.DateMonthRange.emit({
          startDate: sDate,
          endDate: eDate,
        });
      }
    }
  }
}
