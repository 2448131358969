import { DatePipe } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { SyncfusionService } from "src/app/services/syncfusion.service";

@Component({
  selector: "app-pu-stock-trend-molecule",
  templateUrl: "./pu-stock-trend-molecule.component.html",
  styleUrls: ["./pu-stock-trend-molecule.component.scss"],
  providers: [DatePipe],
})
export class PuStockTrendMoleculeComponent implements OnInit {
  dateList: string[] = [];
  dataList: any;
  @Input() code;
  columns: any;
  dynamicColumns: string[];
  reportName: any;
  reportNameWithSpace: any;
  today = new Date();
  groupOptions: { columns: string[] };
  constructor(
    private syncfusionService: SyncfusionService,
    private datePipe: DatePipe
  ) {}
  ngOnInit(): void {
    this.syncfusionService.getStockForEcoTrend(this.code).subscribe((res) => {
      this.reportNameWithSpace = res["displayName"];
      this.groupOptions = { columns: ["buffer"] };
      this.dataList = res["items"];
    });
    const currentDate = new Date(); // Get the current date

    for (let i = 0; i <= 9; i++) {
      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - i
      );

      const formattedDate = this.datePipe.transform(date, "dd-MMM-yyyy");
      if (formattedDate) {
        this.dateList.push(formattedDate);
      }
    }
    this.columns = [
      {
        headerText: "Material",
        field: "materialCode",
      },
      {
        headerText: "Location",
        field: "locationCode",
      },
      {
        headerText: "Description",
        field: "itemDescription",
      },
      {
        headerText: "Buffer",
        field: "buffer",
      },
    ];
    this.dynamicColumns = this.columns.concat(this.dateList.reverse());
  }
}
