import { Component, OnInit } from '@angular/core';
import { scmMgt } from '../../../../properties/scm-mgt-headers'
import { SyncfusionService } from 'src/app/services/syncfusion.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-scm-management-organism',
  templateUrl: './scm-management-organism.component.html',
  styleUrls: ['./scm-management-organism.component.scss']
})
export class ScmManagementOrganismComponent implements OnInit {
  headers = scmMgt
  SCMTableData: any;
  add = true;
  constructor(private syncfusionService: SyncfusionService, private router: Router) {
  }
  ngOnInit(): void {
    this.syncfusionService.getSCMdata().subscribe(res => {
      this.SCMTableData = res['items'];
    })
  }

  gridRowData(event) {
    const selectedRowData = event.data;
    const WorkOrderCode = selectedRowData['code'];
    this.router.navigate(['/SCMForm'], {
      queryParams: { code: WorkOrderCode }
    });

  }

}

