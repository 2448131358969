import { Component } from '@angular/core';

@Component({
  selector: 'rm-demand-allocation-organisms',
  templateUrl: './rm-demand-allocation-organisms.component.html',
  styleUrls: ['./rm-demand-allocation-organisms.component.scss']
})
export class RawMaterialDemandOrganismsComponent {

  constructor() {
    
  }
}
