<div class="mat-elevation-z8 col-md-11" style="margin: 0 auto;">
    <div class='table-header'>
      <!-- <mat-form-field fxFlex="40%"  style="margin-top:5px;">
        <input matInput type="text" (keyup)="doFilter($event.target.value)" autocomplete="off" placeholder="Search">
      </mat-form-field> -->

      <mat-form-field class="">
        <input #userSearch [(ngModel)]="searchField" (keydown.enter)="doFilter(userSearch.value)" matInput placeholder="Search" autocomplete="off">
        <button (click)="doFilter(userSearch.value)" mat-button mat-icon-button matSuffix (click)="clearSearchField()" *ngIf="searchField">
          <mat-icon>close</mat-icon>
        </button>
        <mat-icon  matSuffix>search</mat-icon>
        <mat-hint align="start"><strong>Search with UserId</strong> </mat-hint>
      </mat-form-field>

      <button mat-button  [disabled]='!add' mat-raised-button color="primary" routerLink='/userForm'>Add</button>
    </div>

    <table mat-table class="full-width-table"  [dataSource]="dataSource" matSort>
      <ng-container *ngFor="let disCol of displayedColumns" matColumnDef="{{disCol}}">
        <th class="table_header" mat-header-cell *matHeaderCellDef mat-sort-header> {{disCol}} </th>
        <td mat-cell *matCellDef="let element" class='table_cell' [ngStyle]="{'pointer-events': !update ? 'none' : ''}" [routerLink]="['/userForm']" [queryParams]="{ code: element['userId'] }"> {{element[disCol]}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator #paginator
        [length]="dataSource?.data.length+ (this.page ? 1 : 0)"
        [pageIndex]="0"
        [pageSize]="100"
        [pageSizeOptions]="[10, 25, 50, 100]" (page)="pageChanged($event)">
    </mat-paginator>
  </div>

