export const salesForecast = [
  {
    field: "code",
    headerText: "Material",
    allowFiltering: true,
    width: "130",
    filter: { type: "Menu" },
  },
  {
    field: "name",
    headerText: "Description",
    allowFiltering: false,
    width: "250",
  },
  { field: "imageUrl", headerText: "Image", width: "100", image: true },
  {
    field: "realTimeRateOfSale",
    headerText: "DROS - 1",
    allowFiltering: false,
    width: "120",
  },
  {
    field: "shortTermRateOfSale",
    headerText: "DROS - 7",
    allowFiltering: false,
    width: "120",
  },
  {
    field: "mediumTermRateOfSale",
    headerText: "DROS - 30",
    allowFiltering: false,
    width: "120",
  },
  {
    field: "longTermRateOfSale",
    headerText: "DROS - 90",
    allowFiltering: false,
    width: "120",
  },
  {
    field: "paddedRateOfSale",
    headerText: "DROS - 90-N",
    allowFiltering: false,
    width: "140",
  },
  {
    field: "mrp",
    headerText: "MRP",
    allowFiltering: false,
    width: "140",
  },
  {
    field: "sellPrice",
    headerText: "Sale Price",
    allowFiltering: false,
    width: "140",
  },
  {
    field: "discountPercent",
    headerText: "Discount %",
    allowFiltering: false,
    width: "140",
  },
  {
    field: "analysis",
    headerText: "Analysis",
    allowFiltering: false,
    width: "150",
  },
];
