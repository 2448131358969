import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FilterUiService } from 'src/app/services/filter-ui.service';
import { subHeadings } from "src/app/properties/map_sub_heading";
import { SyncfusionService } from 'src/app/services/syncfusion.service';
import { AppSettingService } from "src/app/services/app-setting.service";
import { DataResult, DataStateChangeEventArgs } from '@syncfusion/ej2-angular-grids';


@Component({
  selector: 'app-sync-fusion-external-filter-organism',
  template: '',
  styleUrls: []
})
export class SyncFusionExternalFilterBaseComponent{
  showOverlay: boolean;
  displayAppliedFilters
  detectChangesOnFilters: boolean = false
  queryParamsSubscription: any;
  dynamic_headers: any[];
  reportName: any;
  data: any;
  reportNameWithSpace: any;
  tableDataFetched: boolean;
  appliedFilters: any;
  count: any;
  fieldData: any;
  headerData: any;
  visiblePOPUP: boolean;
  subHeadings = subHeadings;
  today = new Date();
  constructor(protected filterServices: FilterUiService, protected route: ActivatedRoute, protected syncfusion: SyncfusionService,
    protected settingsService: AppSettingService) {
  }

  handlePOPUP(event) {
    this.fieldData = event.fieldData;
    this.headerData = event.header;
    this.visiblePOPUP = true;
  }
  ngOnDestroy(): void {
    console.log("NG Destroy called on base component.");
    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe(); // Unsubscribe to avoid memory leaks
    }
  }
  toggleOverlay() {
    this.showOverlay = !this.showOverlay;
  }
  reloadPage() {
    this.queryParamsSubscription = this.route.queryParams.subscribe(
      (params) => {
        this.data = [];
        this.dynamic_headers = [];
        if (params && params.reportName) {
          this.reportName = params.reportName;
          this.reportNameWithSpace = params.reportName.replace(/_/g, " ");
          this.tableDataFetched = false;
          if (this.settingsService.isRemoteGridMode()) {
            this.syncfusion.getPaginatedSyncfusionData(this.reportName).subscribe((res) => {
              if (res) {
                this.data = (<DataResult>{
                  result: res.items,
                  count: res.pageData ? res.pageData.totalRecords : res.count
                });
                this.dynamic_headers = JSON.parse(res.headerContent);
                this.syncfusion.setExportData(res);
                this.count = res.count;
                setTimeout(() => {
                  this.tableDataFetched = true;
                });
              }
            }, (error) => {
              this.tableDataFetched = true;
              console.error(error);
            })
          } else {
            this.syncfusion.getSyncfusionData(this.reportName).then((res) => {
              if (res) {
                this.data = res.items;
                this.dynamic_headers = JSON.parse(res.headerContent);
                this.count = res.count;
                setTimeout(() => {
                  this.tableDataFetched = true;
                });
              }
            });
          }
        }
      }
    );
  }
  applyFilters(event: object) {
    this.tableDataFetched = false;
    this.showOverlay = false;
    this.displayAppliedFilters = event;
    this.detectChangesOnFilters = !this.detectChangesOnFilters;
    if (Object.keys(event['filters']).length > 0) {
      this.appliedFilters = event['filters'];
      if (this.settingsService.isRemoteGridMode()) {
        // let pageData = this.syncfusion.getInitialPageData();
        let pageData = this.syncfusion.getExistingPageData();
        pageData.filters = event['filters'];
        this.filterServices.applyFilters(this.reportName, pageData).subscribe((res: any) => {
          if (res) {
            this.data = (<DataResult>{
              result: res.items,
              count: res.pageData ? res.pageData.totalRecords : res.count
            });
            this.syncfusion.setExportData(res);
            this.count = res.count;
            setTimeout(() => {
              this.tableDataFetched = true;
            });
          }
        }, (error) => {
          this.tableDataFetched = true;
          console.error(error);
        })
      } else {
        this.filterServices.applyFilters(this.reportName, event).subscribe(res => {
          if (res) {
            this.data = res['items'];
            this.count = res['count'];
            setTimeout(() => {
              this.tableDataFetched = true;
            });
          }
        })
      }

    } else {
      this.appliedFilters = null;
      this.reloadPage();
    }
  }

  navigateGrid(state: DataStateChangeEventArgs) {
    let pageData = this.syncfusion.convertGridStateToPageData(state);
    if (this.appliedFilters) {
      pageData.filters = this.appliedFilters;
      this.filterServices.applyFilters(this.reportName, pageData).subscribe((res: any) => {
        if (res) {
          this.data = (<DataResult>{
            result: res.items,
            count: res.pageData ? res.pageData.totalRecords : res.count
          });
          this.syncfusion.setExportData(res);
        }
      }, (error) => {
        console.error(error);
      })
    } else {
      this.syncfusion.getPaginatedSyncfusionData(this.reportName, pageData).subscribe((res: any) => {
        if (res) {
          this.data = (<DataResult>{
            result: res.items,
            count: res.pageData ? res.pageData.totalRecords : res.count
          });
          this.syncfusion.setExportData(res);
        }
      }, (error) => {
        console.error(error);
      })
    }
  }


}
