import { Component, OnInit, ViewChild } from '@angular/core';
import { PlanningStockService } from '../../../services/planning-stock.service'
import { MatTableDataSource } from '@angular/material/table';
import { LoaderService } from '../../../services/loader.service'
import { planning_Stock } from 'src/app/properties/stock-planning';
import { Router } from '@angular/router';
import { GridComponent,FilterSearchBeginEventArgs } from '@syncfusion/ej2-angular-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-planning-stock-table-molecule',
  templateUrl: './planning-stock-table-molecule.component.html',
  styleUrls: ['./planning-stock-table-molecule.component.scss']
})
export class PlanningStockTableMoleculeComponent implements OnInit {
  dynamic_headers: ({ field: string; headerText: string; allowFiltering: boolean; width: string; filter: { type: string; }; } | { field: string; headerText: string; allowFiltering: boolean; width: string; filter?: undefined; })[];
  data: any;
  filterOption={type:'Excel'}
  pageSettings: { pageSizes: number[]; pageSize: number; locale: string; };
  loadingIndicator: { indicatorType: 'Shimmer'; };
  @ViewChild('grid', { static: false })
  public grid: GridComponent;
  toolbar: string[];
  reportName="stock_planning"
  public datePipe = new DatePipe("en-US");
  constructor(private planningstockservice: PlanningStockService,
    public loaderService: LoaderService,
    private router:Router) {

  }
  addSpaces(str: string): string {
    return str.replace(/([a-z])([A-Z])/g, '$1 $2');
  }
  onRowSelected(event: any) {
    const selectedRowData = event.data;
    const materialCode = selectedRowData['materialCode'];
    const locationCode = selectedRowData['locationCode'];
    this.router.navigate(['/iplanningStock'], {
      queryParams: { Mcode: materialCode, Lcode: locationCode }
    });
  }
  ngOnInit() {
    this.toolbar = ['ExcelExport', 'CsvExport', 'ColumnChooser'];
    // this.loadingIndicator = { indicatorType: 'Shimmer' };
    this.pageSettings = { pageSizes: [200, 400], pageSize: 200, locale: 'en-US' };
    this.dynamic_headers=planning_Stock;
    this.planningstockservice.getLocationMaterialGrid().subscribe(res=>{
      this.data=res['items'];
    })
  }
  actionBegin(args: FilterSearchBeginEventArgs) {
    if (args.requestType === "filterchoicerequest" || args.requestType === "filtersearchbegin") {
      // args.filterChoiceCount = this.count;
    }
    if (args.requestType === "filterbeforeopen") {
      setTimeout(() => {
        const filterDialog = (args as any).filterModel?.dlgDiv;
        if (filterDialog) {
          const filterElement = filterDialog.querySelector('.e-autocomplete');
          if (filterElement && filterElement.ej2_instances) {
            filterElement.ej2_instances[0].actionBegin = (args)=>{
              args.cancel = true;
            }
          };
        }
      }, 0);
    }
  }
  toolbarClick(args: ClickEventArgs): void {
    switch (args.item.text) {
      case 'Excel Export':
        this.grid.excelExport(this.getExcelExportProperties());
        break;
      case 'CSV Export':
        this.grid.csvExport(this.getCSVExportProperties());
        break;
    }
  }
  private getExcelExportProperties(): any {
    let currentDateTime = this.datePipe.transform((new Date), 'MM_dd_yy_hh:mm:ss');
    return {
      fileName: this.reportName + "_" + currentDateTime + ".xlsx"
    };
  }
  private getCSVExportProperties(): any {
    let currentDateTime = this.datePipe.transform((new Date), 'MM_dd_yy_hh:mm:ss');
    return {
      fileName: this.reportName + "_" + currentDateTime + ".csv"
    };
  }
  }


