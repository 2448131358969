import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { api } from "../properties/endpoints";

@Injectable({
  providedIn: "root",
})
export class CachingService {
  constructor(private http: HttpClient) {}

  getCacheList() {
    const url = `${api.host}/reports/cache/list`;
    return this.http.get(url);
  }
  clearAllCache() {
    const url = `${api.host}/reports/cache/flush-all-cache`;
    return this.http.post(url, {}, { responseType: "text" });
  }

  //{
  //     "cacheNames":["RateOfSaleByProductAndChannel"]
  // }
  clearCacheUsingNames(listOfCacheNames) {
    const url = `${api.host}/reports/cache/flush-cache`;
    return this.http.post(
      url,
      { cacheNames: listOfCacheNames },
      { responseType: "text" }
    );
  }
}
