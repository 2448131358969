import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import {
  FilterSearchBeginEventArgs,
  FilterSettingsModel,
  GridComponent,
} from "@syncfusion/ej2-angular-grids";
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import { DatePipe } from "@angular/common";
import { ClickStreamAnalyticsService, EventType } from "src/app/services/clickstream-analytics-service";


@Component({
  selector: "app-dashboard-grids-molecule",
  templateUrl: "./dashboard-grids-molecule.component.html",
  styleUrls: ["./dashboard-grids-molecule.component.scss"],
})
export class DashboardGridsMoleculeComponent {
  ctx: any;
  @Input() data;
  @Input() dynamic_headers: any;
  @Input() excelExport: boolean = true;
  @Input() chartData: any;
  @Input() header: any;
  @Input() size: any;
  @Input() tableHeight: any;
  @Input() frozenColumns: number = 0;
  pageSettings: any;
  public filterOption: FilterSettingsModel = { type: "Excel" };
  pagination: boolean = false;
  width: string;
  height: string;
  @ViewChild("grid", { static: false })
  public grid: GridComponent;
  public datePipe = new DatePipe("en-US");
  reportName: string;
  toolbar: string[];
  loadingIndicator: { indicatorType: string };
  @Input() count: any;

  constructor(private clickStream: ClickStreamAnalyticsService) { }
  ngOnInit() {
    this.toolbar = ["ExcelExport", "ColumnChooser"];
    this.loadingIndicator = { indicatorType: "Shimmer" };
    if (this.size == 1) {
      this.width = "100%";
      this.height = "240";
    } else if (this.size == 2) {
      // this.width="auto"
      this.height = "240";
    } else {
      // this.width="auto"
      this.height = "240";
    }
    if (this.chartData.pagination) {
      this.pageSettings = this.chartData.pagination;
      this.pagination = true;
    }

    if (this.tableHeight) {
      this.height = this.tableHeight;
    }
  }

  actionBegin(args: FilterSearchBeginEventArgs) {
    if (args.requestType != 'filterchoicerequest') {
      this.clickStream.publishGAEvent(EventType.GRID_COLUMN_ACTION, {
        actionType: args.requestType,
        value: args.columnName,
        section: this.reportName || this.chartData.displayname
      })
    }
    if (
      args.requestType === "filterchoicerequest" ||
      args.requestType === "filtersearchbegin"
    ) {
      args.filterChoiceCount = this.count;
    }
    if (args.requestType === "filterbeforeopen") {
      setTimeout(() => {
        const filterDialog = (args as any).filterModel?.dlgDiv;
        if (filterDialog) {
          const filterElement = filterDialog.querySelector('.e-autocomplete');
          if (filterElement && filterElement.ej2_instances) {
            filterElement.ej2_instances[0].actionBegin = (args)=>{
              args.cancel = true;
            }
          };
        }
      }, 0);
    }
  }
  toolbarClick(args: ClickEventArgs): void {
    this.clickStream.publishGAEvent(EventType.GRID_COLUMN_ACTION, {
      actionType: args.item.text,
      section: this.reportName || this.chartData.displayname
    })
    switch (args.item.text) {
      case "Excel Export":
        this.grid.excelExport(this.getExcelExportProperties());
        break;
      case "CSV Export":
        this.grid.csvExport(this.getCSVExportProperties());
        break;
    }
  }
  private getExcelExportProperties(): any {
    let currentDateTime = this.datePipe.transform(
      new Date(),
      "MM_dd_yy_hh:mm:ss"
    );
    return {
      fileName: this.chartData.displayname + "_" + currentDateTime + ".xlsx",
    };
  }
  private getCSVExportProperties(): any {
    let currentDateTime = this.datePipe.transform(
      new Date(),
      "MM_dd_yy_hh:mm:ss"
    );
    return {
      fileName: this.chartData.displayname + "_" + currentDateTime + ".csv",
    };
  }
}
