export const scmMgt = [
  {
    "field": "materialCode",
    "headerText": "Material",
    "allowFiltering": true,
    "width": "180",
    "filter": { "type": "Menu" }
  },
  {
    "field": "parentProductCode",
    "headerText": "Style",
    "allowFiltering": true,
    "width": "180",
    "filter": {
      "type": "Menu"
    }
  },
  {
    "field": "name",
    "headerText": "Description",
    "allowFiltering": true,
    "width": "250",
    "filter": {
      "type": "Menu"
    }
  },
  {
    "field": "imageUrl",
    "headerText": "Image",
    "width": "120",
    "image": true
  },
  {
    "field": "locationCode",
    "headerText": "Location",
    "allowFiltering": true,
    "width": "90",
    "filter": {
      "type": "CheckBox"
    }
  },
  {
    "field": "code",
    "headerText": "Work Order",
    "allowFiltering": true,
    "width": "120",
    "filter": { "type": "Menu" }
  },
  {
    "field": "qty",
    "headerText": "Quantity",
    "allowFiltering": false,
    "width": "100"
  },
  {
    "field": "fulfilledQty",
    "headerText": "Fulfilled Quantity",
    "allowFiltering": false,
    "width": "100"
  },
  {
    "field": "recordDate",
    "headerText": "Created Date",
    "allowFiltering": false,
    "width": "100"
  }
]
