import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { ChartjsReportsService } from '../../../services/chartjs-reports.service';

@Component({
  selector: 'app-buffer-trend-molecules',
  templateUrl: './buffer-trend-molecules.component.html',
  styleUrls: ['./buffer-trend-molecules.component.scss']
})
export class BufferTrendMoleculesComponent implements OnInit, OnChanges {
  @ViewChild('myChart', { static: false }) barChartElement: ElementRef;
  @Input() chatID;
  @Input() filterValues;
  @Input() addFlt
  @Input() selectedFilters;
  chatIdValue: string = ''
  allOtherFilters: any[] = [];
  selectedOtherFilterOperator: any
  selectedOtherFilterValue: any
  selectedOtherFilterKey: any;
  addNewFilter: any[] = [];
  selectAllDeafult: boolean = true;
  slectedFilterInfo: any;
  locationFilterValues = new UntypedFormControl();
  @ViewChild('allLocationSelected') private allLocationSelected: MatOption;
  @Output() getReportsByFilters = new EventEmitter<any>();
  allOtherFiltersOperators: { label: string; key: string; }[];
  constructor(private chatjs: ChartjsReportsService) {
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.toggleLocationAllSelection();
      this.addFilter();
    });
  }
  ngOnInit() {
    this.allOtherFilters = []
    this.locationFilterValues.valueChanges.subscribe(res => {
      if (res) {
        this.slectedFilterInfo = this.filterValues?.labelValues?.filter(item => {
          return res.some(value => {
            return value == item.key
          })
        });
      }
    })
    if (this.addFlt) {
      this.createColorAndOtherFilters();
    }
  }

  createColorAndOtherFilters() {
    this.addFlt.map(filter => {
      if (filter.values == null) {
        this.allOtherFilters.push(filter);
      }
    })
  }

  closeFilter(index) {
    if (this.addNewFilter[index]) {
      // if (this.addNewFilter[index]['key'] === 'locationCode') {
      this.locationFilterValues.patchValue([...this.filterValues.labelValues.map(item => item.key), 0]);
      // }
    }
    this.addNewFilter.splice(index, 1);
    this.getReportsByFilters.emit(this.addNewFilter);
  }

  locationOneSelected(all) {
    if (this.allLocationSelected?.selected) {
      this.allLocationSelected?.deselect();
      return false;
    }
    if (this.locationFilterValues?.value.length == this.filterValues?.labelValues.length)
      this.allLocationSelected?.select();

  }

  ngOnChanges() {
    if (this.chatIdValue != this.chatID) {
      this.chatIdValue = this.chatID
    }
  }

  toggleLocationAllSelection() {
    this.checkALLFilters();
  }

  checkALLFilters() {
    if (this.allLocationSelected?.selected || this.selectAllDeafult) {
      this.selectAllDeafult = false;
      this.locationFilterValues?.patchValue([...this.filterValues?.labelValues.map(item => item.key), 0]);
    } else {
      this.locationFilterValues?.patchValue([]);
    }
  }

  addFilter() {
    let locationFilterFlag = false;
    this.addNewFilter.map((filter, index) => {
      if (filter.key === this.filterValues.key) {
        locationFilterFlag = true;
        this.addNewFilter[index]["values"] = this.removeExtraZeroFromLocationFilter(this.locationFilterValues.value);
        this.addNewFilter[index]["operator"] = "in";
      }
    });
    !locationFilterFlag && this.pushLocationFilter();
    this.pushAllOtherFilters();
    this.getReportsByFilters.emit(this.addNewFilter);
  }

  pushLocationFilter() {
    if (this.locationFilterValues.value && this.locationFilterValues.value.length > 0) {
      const temp = {
        "key": this.filterValues.key,
        "values": this.removeExtraZeroFromLocationFilter(this.locationFilterValues.value),
        "operator": "in"
      }
      this.addNewFilter.push(temp)
    }
  }

  removeExtraZeroFromLocationFilter(lctnFilterValues) {
    return lctnFilterValues.filter(v => v !== 0);
  }


  onOtherKeyChanges() {
    this.addFlt.map(filter => {
      if (filter.key === this.selectedOtherFilterKey) {
        if (filter.dataType === 'String') {
          this.allOtherFiltersOperators = [{
            "label": "Equals",
            "key": "eq"
          }, {
            "label": "Starts With",
            "key": "regex"
          }, {
            "label": "Contains",
            "key": "regex"
          }
          ];
        } else if (filter.dataType === 'Number') {
          this.allOtherFiltersOperators = [{
            "label": "Equals",
            "key": "eq"
          }, {
            "label": "Greater than",
            "key": "greaterthan"
          }, {
            "label": "Less than",
            "key": "lessthan"
          }
          ];
        }
      }
    })
  }

  pushAllOtherFilters() {
    let filterExist = false;
    if (!this.selectedOtherFilterKey || !this.selectedOtherFilterValue) {
      return false;
    }
    this.addNewFilter.map((filter, index) => {
      if (filter.key === this.selectedOtherFilterKey) {
        filterExist = true;
        // let value = this.selectedFilters[index]['value'];
        //   this.selectedFilters[index]['values'].push(this.selectedOtherFilterValue);
      }
    })

    if (!filterExist) {
      this.addNewFilter.push({
        "key": this.selectedOtherFilterKey,
        "value": this.selectedOtherFilterValue,
        "operator": this.selectedOtherFilterOperator,
      });
    }
    this.selectedOtherFilterKey = 'all';
    this.selectedOtherFilterValue = '';
    this.selectedOtherFilterOperator = '';
    this.allOtherFiltersOperators = [];
  }

  getLocationName(locationCode) {
    let result = '';
    this.filterValues && this.filterValues.labelValues.map(data => {
      if (data.key === locationCode) {
        result = ' - ' + data.label;
      }
    })
    return result;
  }
}
