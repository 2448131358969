import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { api } from "../properties/endpoints";

@Injectable({
  providedIn: "root",
})
export class ListingGridService {
  private appliedFiltersAndSort: any;

  private selectedProduct: any;

  setAppliedFiltersAndSort(filters: any) {
    this.appliedFiltersAndSort = filters;
  }

  getAppliedFiltersAndSort() {
    return this.appliedFiltersAndSort;
  }

  constructor(private http: HttpClient) { }

  getGridList(payLoad) {
    const url = `${api.host}/reports/listing/apply/filter`;
    const body = {
      pageSize: payLoad.pageSize,
      pageNumber: payLoad.pageNumber,
      filters: payLoad.filters,
      sortOption: payLoad.sortOption,
      searchText: payLoad.searchText,
      sortAsc: payLoad.sortAsc
    };
    this.setAppliedFiltersAndSort(body);
    return this.http.post(url, body);
  }

  getVisualizeOptions() {
    return this.http.get(`${api.host}/reports/listing/visualize/options`);
  }

  filterProductSalesSummaryByDimension(dimension, metric, filterPayload) {
    const url = `${api.host}/reports/listing/sales-performance/dimension/summary?dimension=${dimension}&metric=${metric}`;
    return this.http.post(url, filterPayload);
  }


  getProductSalesSummary() {
    const url = `${api.host}/reports/listing/sales-performance/summary`;

    return this.http.post(url, {});
  }

  filterProductSalesSummary(payload) {
    const url = `${api.host}/reports/listing/sales-performance/summary`;

    return this.http.post(url, payload);
  }

  getPredictedPromotionalListings(includeAll: boolean) {
    const url = `${api.host}/reports/listing/predict/promotional-items${includeAll ? "?include_promotional_only=false" : ""}`;
    return this.http.get(url);
  }

  getGridListWithAppliedFilters() {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${api.host}/reports/listing/apply/filter`;
    return this.http.post(url, this.getAppliedFiltersAndSort(), { headers: headers });
  }

  getDataByCode(code) {
    const url = `${api.host}/reports/sales/listing/product/${code}`;
    return this.http.get(url);
  }
  getdynamicNormByCode(code) {
    const url = `${api.host}/suggestion/ros-dn/product/${code}`;
    return this.http.get(url);
  }
  getSortByOptions() {
    const url = `${api.host}/reports/listing/sort-options`;
    return this.http.get(url);
  }
  getSalesChannel() {
    const url = `${api.host}/reports/listing/sales-channels`;
    return this.http.get(url);
  }

  getSelectedProduct() {
    return this.selectedProduct;
  }

  setSelectedProduct(code) {
    this.selectedProduct = code;
  }

  getBlockByProductCode(code) {
    const url = `${api.host}/material/${code}`;
    return this.http.get(url);
  }
  updateMaterialAttribute(code: string, attribute: any, value: any, updateSkus: boolean = false) {
    const payload = {
      code,
      attribute,
      value
    };
    const url = `${api.host}/material/update${updateSkus ? "/allSkus" : ""}`;
    return this.http.post(url, payload, { responseType: 'text' });
  }
  getProductAnalytics(code){
    const url = `${api.host}/reports/listing/analytics/product/${code}`;
    return this.http.get(url);
  }
}
