import { Injectable } from '@angular/core';
import { api } from '../properties/endpoints';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FilterUiService {

  constructor(private http: HttpClient) { }
  getFilters(productTypes: string[], analyticsFilter: boolean = false) {
    let uri = "/material/filters";
    if (analyticsFilter) {
      uri = "/material/analytics/filters"
    }

    if (productTypes && productTypes.length > 1) {
      return this.http.get(`${api.host}${uri}?productTypes=${productTypes.join(",")}`);
    } else {
      return this.http.get(`${api.host}${uri}`);
    }

  }
  applyFilters(reportName, payload: object) {
    const url = `${api.host}/reports/grid/apply/filter/${reportName}`;
    return this.http.post(url, payload);
  }
}
