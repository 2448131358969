import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { User } from '../interfaces/user';
import { CommonUtilityService } from '../services/common-utility-service';
import {api} from '../properties/endpoints';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public _isLoggedIn: boolean = false;
  private _isLoggedInEvent: BehaviorSubject<boolean>;
  private _isLoggedInObservable: Observable<boolean>;
  httpHeaders: any;

  loginApi = `${api.host}/authenticate`;
  // accessApi = '../../assets/mock/getAccessRules.json';
  accessApi = `${api.host}/get/accessrules`;

  currentUserApi = `${api.host}/get/currentuser`;

  reportsUserApi = `${api.host}/reports/get/`;

  constructor(private http: HttpClient, private utilService: CommonUtilityService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this._isLoggedInEvent = new BehaviorSubject<boolean>(false);
    this._isLoggedInObservable =  this._isLoggedInEvent.asObservable();
    this._isLoggedInEvent.next(false);
  }
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  public get isLoggedInObservable(): Observable<boolean> {
      return this._isLoggedInObservable;
  }
  getRole() {
    const userDetails = this.getUserDetails();
    return userDetails?.role;
  }

  setUserDetails(user: any)
  {
    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  getUserDetails(): any
  {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  getTokenDetails(): any
  {
    return JSON.parse(localStorage.getItem('access_token'));
  }

  getUserId(): string{
    return this.getUserDetails()?.userId;
  }

  getUserName(): string{
    return this.getUserDetails()?.name;
  }

  getRoleDetail(): any {

    return this.http.get<any>(this.reportsUserApi+ this.getRole(), {headers: this.httpHeaders})
  }

  login(username: string, password: string) {
    return this.http.post<any>(this.loginApi
      , { username, password })
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('access_token', JSON.stringify(user));
          this.utilService.setCookie('isLoggedIn', true);
          this.currentUserSubject.next(user);
          this._isLoggedIn = true;
          const authToken = 'Bearer ' +  user.token;
          this.httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json;',
            Authorization: authToken
          });
          return this.http.get(this.currentUserApi).subscribe(user => {
            this.setUserDetails(user);
            return user;
          })
        }
        return user;
      }), mergeMap(user =>
          this.http.get<any>(this.accessApi, {headers: this.httpHeaders})));
  }
  public get isLoggedIn(): boolean {
    const value = this.utilService.getCookie('isLoggedIn');
    return value ? true : false;
  }
  sendEmail(mail){
    const palyod={
      "email":`${mail}`
    }
    const url = `${api.host}/user/password/reset`;
    return this.http.post(url,palyod)
  }
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('access_token');
    localStorage.removeItem('currentUser');
    this.utilService.removeCookie('isLoggedIn');
    this.utilService.removeCookie('accessRules');
    this._isLoggedIn = false;
    this.currentUserSubject.next(null);
  }
}
