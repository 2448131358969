import { Component, Input, OnInit, ViewChild, Inject } from '@angular/core';
import { stockTable } from '../../../properties/tableConfig';
import { PlanningStockService } from '../../../services/planning-stock.service'
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { FormDataConstructorService } from '../../../services/form-data-constructor.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PlanningStock } from './../../../properties/planning_stock_config'
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-stock-planning-tab',
  templateUrl: './stock-planning-tab.component.html',
  styleUrls: ['./stock-planning-tab.component.scss']
})
export class StockPlanningTabComponent implements OnInit {
  @Input() materialCode: string;
  @Input() locationCode: string;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  ELEMENT_DATA: any[];
  stock_data: any[];
  dataSource = new MatTableDataSource<any>([]);
  stocksource = new MatTableDataSource<any>([]);
  displayStockColumns = stockTable;
  add = true;
  update = true;
  selection;
  page: any;
  formData: any;
  addFlag: any = true;
  fm = false;
  planningStockSave$ = new Subscription();
  planningStock$ = new Subscription();
  reloadStockList$ = new Subscription();
  create() {
    this.fm = true;
  }
  close() {
    this.fm = false;
  }
  addSpaces(str: string): string {
    return str.replace(/([a-z])([A-Z])/g, '$1 $2');
  }
  constructor(private planningstockservice: PlanningStockService, private route: ActivatedRoute,
    private formService: FormDataConstructorService, private router: Router,
    private snack: MatSnackBar) { }
  ngOnInit() {

    this.planningStock$ = this.planningstockservice.getPlanningServices2(this.materialCode, this.locationCode).subscribe(data => {
      if (data) {
        const lst = []
        this.stock_data = data['stockPlanningEntityList']
        this.stock_data.forEach(l => {
          l.plannedDate = l.plannedDate.split('T')[0]
          lst.push(l);
        })
        this.stocksource = new MatTableDataSource<any>(lst);
        this.stocksource.sort = this.sort;
        this.stocksource.paginator = this.paginator;
      }
    })
    const options = [];
    this.planningstockservice.getSupplier(this.materialCode, this.locationCode).subscribe(res => {
      res['shareOfBusinessList'].forEach(i => {
        this.planningstockservice.getSupplierName(i.supplier).subscribe(sup => {
          options.push(`${i.supplier} - ${sup['name']}`)
        })
      })
    })
    const Planning_stock = [];
    PlanningStock.forEach(dt => {
      if (dt.identifier === 'supplier') {
        dt.values = options;
      }
      Planning_stock.push(dt);
    })
    this.formData = this.formService.formConstructor(Planning_stock);
  }

  submitForm(formValues) {
    const year = formValues.planneddate.value.getFullYear();
    const month = ('0' + (formValues.planneddate.value.getMonth() + 1)).slice(-2);
    const day = ('0' + formValues.planneddate.value.getDate()).slice(-2);
    const formattedDate = `${year}-${month}-${day}`;
    this.planningStockSave$ = this.planningstockservice.addPlanningStock(this.materialCode, this.locationCode, formValues.quantity.value, formattedDate, formValues.supplier.value.split("-")[0].trim()).subscribe((response) => {
      const message = this.addFlag ? 'Added successfully' : 'changes saved successfully';
      this.snack.open(message, 'close', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      this.reloadList()
      this.fm = false;
    });
  }
  reloadList() {
    this.reloadStockList$ = this.planningstockservice.getPlanningServices2(this.materialCode, this.locationCode).subscribe(data => {
      if (data) {
        const lst = []
        this.stock_data = data['stockPlanningEntityList']
        this.stock_data.forEach(l => {
          l.plannedDate = l.plannedDate.split('T')[0]
          lst.push(l);
        })
        this.stocksource = new MatTableDataSource<any>(lst);
        this.stocksource.sort = this.sort;
        this.stocksource.paginator = this.paginator;
      }
    })
  }
  ngOnDestroy() {
    if (this.planningStockSave$) {
      this.planningStockSave$.unsubscribe();
    }
    if (this.planningStock$) {
      this.planningStock$.unsubscribe();
    }
    if (this.reloadStockList$) {
      this.reloadStockList$.unsubscribe();
    }
  }
}
