export const gridHeaders = [
  {
    field: "code",
    headerText: "Material",
    allowFiltering: true,
    width: "150",
    filter: { type: "Menu" },
  },
  {
    field: "imageUrl",
    headerText: "Image",
    width: "120",
    image: true,
  },
  {
    field: "qtyReturnedThisMonth",
    headerText: "Qty Returned This Month",
    allowFiltering: true,
    width: "150",
    filter: { type: "Menu" },
  },
  {
    field: "qtySoldThisMonth",
    headerText: "Qty Sold ThisMonth",
    allowFiltering: true,
    width: "150",
    filter: { type: "Menu" },
  },
  {
    field: "returnPercentage",
    headerText: "Return Percentage",
    allowFiltering: true,
    width: "150",
    filter: { type: "Menu" },
  },
  {
    field: "name",
    headerText: "Description",
    allowFiltering: true,
    width: "250",
    filter: {
      type: "Menu",
    },
  }
];
