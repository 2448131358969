import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { FilterBayService } from "src/app/services/filter-bay.service";
import { ReturnAnalyzerService } from "src/app/services/return-analyzer.service";
import { gridHeaders } from "src/app/properties/returns-analyser-grid";
import { DatePipe } from "@angular/common";
import { Observable, forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { AuthenticationService } from "src/app/services/authentication.service";


import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-summary',
  templateUrl: './dashboard-summary.component.html',
  styleUrls: ['./dashboard-summary.component.scss']
})

export class DashboardSummaryComponent  implements OnInit {
  @Input() changesFromParent: any;
  // scoreCardData;
  // filterOnScoreCard: boolean;
  // errorInScoreCard: boolean;
  // dateRange: { startDate?: string; endDate?: string } = {};
  // compareDateRange: { startDate?: string; endDate?: string } = {};
  // public datePipe = new DatePipe("en-US");
  visualizationData: any;
  gridData: any;
  dynamicHeaders: any;
  gridDataProcessing: any;
  dynamicHeadersProcessing: any;
  scoreCardData: any;
  scoreCardResponse: boolean;
  chartJSResponse: boolean;
  prossingResponse: boolean;
  GridHighestReturnsResponse: boolean;
  filtersEvent: any;
  public datePipe = new DatePipe("en-US");
  pivotFields: string[] = [];
  selectedPivot: string;
  returnDateTableData: any;
  // dataSource = new MatTableDataSource<any>();
  // displayedColumns = dateProcessing;
  showOverlay;
  changesDetected: boolean;
  addFilters: any;
  detectChanges: boolean;
  text1: string = 'Key Performance Indicator';
  text2: string = 'A comprehensive sales summary analysis';
  text3: string = 'A complete analysis of Returns across the channels';
  text4: string = 'Dig Deeper';
  showSection1: boolean = false;
  showSection2: boolean = false;
  showSection3: boolean = false;
  showSection4: boolean = false;
  showSection5: boolean = false;
  showSection6: boolean = false;

  constructor(private filterbayservice: FilterBayService , private router: Router , private returnService: ReturnAnalyzerService,private authenticationService: AuthenticationService,) {}

  ngOnInit(): void {
    this.returnService.groupByKeys.subscribe((keys: string[]) => {
      this.pivotFields.push(...keys);
      this.selectedPivot = this.pivotFields[0].toString();
      this.returnService
        .getVisualChart({
          ...this.filtersEvent,
          ...{ groupBy: this.selectedPivot },
        })
        .subscribe(
          (res) => {
            if (res) {
              res["chartJSResponseData"].displayName = null;
              this.visualizationData = res["chartJSResponseData"];
              this.visualizationData.options = {
                //indexAxis: 'y', used to change to horizontal bar
                locale: "en-IN",
                scales: {
                  x: {
                    grid: {
                      display: false,
                    },
                    title: {
                      display: true,
                      text: `${this.selectedPivot}`,
                      font: {
                        weight: "bold",
                      },
                    },
                    type: "category",
                  },
                  y: {
                    type: "linear",
                    title: {
                      display: true,
                      text: "Sold/Returned Qty",
                      font: {
                        weight: "bold",
                      },
                    },
                    display: true,
                    grid: {
                      display: true,
                      lineWidth: 0.5,
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              };
            }
          },
          (error) => {},
          () => {
            this.chartJSResponse = true;
          }
        );
    });
    const { startDate, endDate } = this.getLastMonthRange();
    const startDateOut = this.datePipe.transform(startDate, "yyyy-MM-dd");
    const endtDateOut = this.datePipe.transform(endDate, "yyyy-MM-dd");
    this.filtersEvent = {
      startDate: startDateOut,
      endDate: endtDateOut,
    };
    this.returnService.getHighestReturns(this.filtersEvent).subscribe(
      (res) => {
        if (res) {
          this.gridData = res["reportGridResponseData"]["items"];
          this.dynamicHeaders = gridHeaders;
        }
      },
      (error) => {},
      () => {
        this.GridHighestReturnsResponse = true;
      }
    );
    this.returnService.getProcessingDates().subscribe(
      (res) => {
        if (res) {
          this.returnDateTableData = {
            headers: [
              {
                field: "data",
                headerText: "Sales Channel",
                allowFiltering: true,
                textAlign: "left",
                filter: { type: "Menu" },
              },
              {
                field: "date",
                headerText: "Latest Return Date",
                allowFiltering: false,
                textAlign: "left",
              },
            ],
            data: res["values"],
            displayname: "Return dates recieved by flowsmart",
          };
        }
      },
      (error) => {},
      () => {
        this.prossingResponse = true;
      }
    );
    this.returnService.getScoreCard(this.filtersEvent).subscribe(
      (res) => {
        if (res) {
          this.scoreCardData = res["scoreCardList"];
        }
      },
      (error) => {},
      () => {
        this.scoreCardResponse = true;
      }
    );
    this.loadSections();
  }
  getLastMonthRange(): { startDate: Date; endDate: Date } {
    const currentDate = new Date();
    currentDate.setDate(1);
    const startDate = new Date(currentDate);
    startDate.setMonth(currentDate.getMonth() - 1);
    const endDate = new Date(currentDate);
    endDate.setDate(0);
    return { startDate, endDate };
  }
  loadSections() {
    // Use setTimeout to display sections with delay
    setTimeout(() => {
      this.showSection1 = true;
      setTimeout(() => {
        this.showSection2 = true;
        setTimeout(() => {
          this.showSection3 = true;
          setTimeout(()=>{
            this.showSection4 = true;
            setTimeout(()=>{
              this.showSection5 = true;
            },3000);
          },1000);
        }, 3000); // Delay for third section
      }, 2000); // Delay for second section
    }, 1000); // Delay for first section
    setTimeout(()=>{
      this.showSection6 = true;
    },10000);
  }

  getName() {
    let name = this.authenticationService.getUserName();
    let names = name?.split(" ");
    return names
      .map((value) => {
        return value.charAt(0);
      })
      .slice(0, 2)
      .join("")
      .toUpperCase();
  }
 
  navigateToChatbot() {
    // const contentToPass = './new-home-page-template.component.html';
    // this.router.navigate(['/chatbot'], { queryParams: { content: contentToPass } });
  }
  navigateToRevenueInsights(){
    this.router.navigate(['/revenueInsights'])
  }
  navigateToReturnsAnalyzer(){
    this.router.navigate(['/return-analyzer'])
  }

}
