import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { SyncfusionService } from "src/app/services/syncfusion.service";
import { salesForecast } from "src/app/properties/salesforecastGridHeaders";
import { ListingGridService } from "src/app/services/listing-grid.service";

@Component({
  selector: "app-pu-ros-molecule",
  templateUrl: "./pu-ros-molecule.component.html",
  styleUrls: ["./pu-ros-molecule.component.scss"],
})
export class PuRosMoleculeComponent implements OnInit {
  innerDynamicHeaders;
  innerGridData;
  newChartData;
  chartData;
  chartType;
  @Input() code;
  @Input() data;
  isNewChartLoaded: boolean;
  label: string;
  salesChannelList: any;
  oldChart: boolean;
  isExpanded: boolean = null;
  salesChanneItem: any;
  newChartDataToHeader: any;

  constructor(
    private SyncfusionService: SyncfusionService,
    private listingGridService: ListingGridService
  ) { }

  ngOnInit(): void {
    if (this.oldChart) {
      this.oldChart = !this.oldChart;
    }
    this.listingGridService.getSalesChannel().subscribe((res) => {
      setTimeout(() => {
        this.salesChannelList = res;
        this.label = "all";
      }, 0);
    });
    this.SyncfusionService.getSalesLineGraph(this.code, "product").subscribe(
      (res) => {
        if (res) {
          this.chartData = this.chartData = {
            header: res["displayName"],
            type: res["type"],
            data: res["data"],
            options: {
              pointRadius: 0.5,
              pointStyle:'cross',
              scales: {
                y: {
                  beginAtZero: true,
                  grace: 1,
                  position: "left",
                  title: {
                    "display": true,
                    "text": "Qty Sold",
                    "font": {
                      "weight": "bold"
                    }
                  }
                },
                "secondary-y-axis": {
                  "type": "linear",
                  "min": 0,
                  "max": 100,
                  "position": "right",
                  "beginAtZero": true,
                  "title": {
                    "display": true,
                    "text": "Discount %",
                    "font": {
                      "weight": "bold"
                    }
                  }
                }
              },
              tooltips: {
                enabled: true,
                mode: "index",
                intersect: false,
              },
            },
            plugins: [brokenSetLabel],
          };
        }
      },
      (error) => { },
      () => {
        this.oldChart = true;
      }
    );
    this.SyncfusionService.getSalesGridTable(this.code).subscribe((res) => {
      if (res) {
        this.innerGridData = res["items"];
        this.innerDynamicHeaders = salesForecast;
      }
    });
  }
  chartTypeRecived(event) {
    this.newChartDataToHeader = event;
    this.isNewChartLoaded = false;
    this.SyncfusionService.getSalesLineGraph(
      event.data.code,
      event.type,
      this.salesChanneItem
    ).subscribe(
      (res) => {
        if(this.isExpanded == null){
          this.isExpanded=false;
        }
        if (res) {
          this.newChartData = {
            header: res["displayName"],
            type: res["type"],
            data: res["data"],
            options: {
              pointRadius: 0.5,
              pointStyle:'cross',
              scales: {
                y: {
                  beginAtZero: true,
                  grace: 1,
                  position: "left",
                  title: {
                    "display": true,
                    "text": "Qty Sold",
                    "font": {
                      "weight": "bold"
                    }
                  }
                },
                "secondary-y-axis": {
                  "type": "linear",
                  "min": 0,
                  "max": 100,
                  "position": "right",
                  "beginAtZero": true,
                  "title": {
                    "display": true,
                    "text": "Discount %",
                    "font": {
                      "weight": "bold"
                    }
                  }
                }
              },
              tooltips: {
                enabled: true,
                mode: "index",
                intersect: false,
              },
            },
            plugins: [brokenSetLabel],
          };
        }
      },
      (error) => { },
      () => {
        this.isNewChartLoaded = true;
      }
    );
  }

  toggleFullWidth() {
    this.isExpanded = !this.isExpanded;
  }
  handleValueChange(event) {
    if (this.label === "all") {
      this.ngOnInit();
      return;
    }
    this.salesChanneItem = this.label;

    this.oldChart = false;
    this.SyncfusionService.getSalesLineGraph(
      this.code,
      "product",
      this.salesChanneItem
    ).subscribe(
      (res) => {
        if (res) {
          
          this.chartData = this.chartData = {
            header: res["displayName"],
            type: res["type"],
            data: res["data"],
            options: {
              pointRadius: 0.5,
              pointStyle:'cross',
              scales: {
                y: {
                  beginAtZero: true,
                  grace: 1,
                  position: "left",
                  title: {
                    "display": true,
                    "text": "Qty Sold",
                    "font": {
                      "weight": "bold"
                    }
                  }
                },
                "secondary-y-axis": {
                  "type": "linear",
                  "min": 0,
                  "max": 100,
                  "position": "right",
                  "beginAtZero": true,
                  "title": {
                    "display": true,
                    "text": "Discount %",
                    "font": {
                      "weight": "bold"
                    }
                  }
                }
              },
              tooltips: {
                enabled: true,
                mode: "index",
                intersect: false,
              },
            },
            plugins: [brokenSetLabel],
          };
        }
      },
      (error) => { },
      () => {
        this.oldChart = true;
      }
    );
    this.SyncfusionService.getSalesGridTable(
      this.code,
      this.salesChanneItem
    ).subscribe((res) => {
      if (res) {
        this.innerGridData = res["items"];
        this.innerDynamicHeaders = salesForecast;
      }
    });
  }
}
const brokenSetLabel = {
  id: "brokenSetLabel",
  afterDatasetsDraw(chart, args, plugins) {
    const {
      ctx,
      scales: { y },
    } = chart;
    let datasetMeta0 = chart.getDatasetMeta(0);
    if (!datasetMeta0.hidden) {
      datasetMeta0.data.forEach((item, index) => {
        if (item.options.backgroundColor === "#ffa07a") {
          ctx.save();
          ctx.font = "bold 10px sans-serif";
          ctx.textAlign = "center";
          ctx.fillStyle = "#ffa07a";
          ctx.fillText("*", item.x, item.y - 2);
        }
      });
    }
  },
};
