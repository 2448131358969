<div class="fs-margin-1-0 dasboardChartsContainer" *ngIf="show">
  <ng-container *ngFor="let number of [0,1]">
    <div class="charts">
      <div class=" mat-elevation-z4 fs-padding-1 chart-section">
        <p class="flowsmart-placeholder placeholder-template fs-margin-2 fs.padding-2-0"></p>
        <p class="flowsmart-placeholder placeholder-template fs-padding-1"></p>
        <p class="flowsmart-placeholder placeholder-template fs-padding-2"></p>
        <p class="flowsmart-placeholder placeholder-template fs-margin-2 fs.padding-2-0"></p>
      </div>
    </div>
  </ng-container>
</div>
<div class="fs-margin-1-0 dasboardChartsContainer" *ngIf="show && size">
  <ng-container *ngFor="let number of [0]">
    <div class="charts">
      <div class=" mat-elevation-z4 fs-padding-1 chart-section">
        <p class="flowsmart-placeholder placeholder-template fs-margin-2 fs.padding-2-0"></p>
        <p class="flowsmart-placeholder placeholder-template fs-padding-1"></p>
        <p class="flowsmart-placeholder placeholder-template fs-padding-2"></p>
        <p class="flowsmart-placeholder placeholder-template fs-margin-2 fs.padding-2-0"></p>
      </div>
    </div>
  </ng-container>
</div>
<div *ngIf="size" class="dasboardChartsContainer" style="height:max-content">
  <ng-container *ngFor="let chts of charts">
    <div *ngIf="size==1" class="sizecharts">
      <app-dashboard-chatjs-molecule class="chartjs-charts" *ngIf="chts.type"
        [size]="size" [header]="chts.header" [chartData]="chts" [htmlLegend]="true"></app-dashboard-chatjs-molecule>
      <app-dashboard-grids-molecule class="grid-charts " *ngIf="!chts.type" chts.type
        [size]="size" [header]="chts.header" [chartData]="chts"  [tableHeight]="300" [frozenColumns]="chts.frozenColumns"></app-dashboard-grids-molecule>
    </div>

    <div *ngIf="size!=1" class="charts">
      <app-dashboard-chatjs-molecule class="chartjs-charts" *ngIf="chts.type"
        [size]="size" [header]="chts.header" [chartData]="chts" htmlLegend="true"></app-dashboard-chatjs-molecule>
      <app-dashboard-grids-molecule class="grid-charts" *ngIf="!chts.type" chts.type
        [size]="size" [header]="chts.header" [chartData]="chts" [tableHeight]="300" [frozenColumns]="chts.frozenColumns"></app-dashboard-grids-molecule>
    </div>
  </ng-container>
</div>