import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {api} from '../properties/endpoints';
@Injectable({
  providedIn: 'root'
})
export class PlanningStockService {
  constructor(private http: HttpClient) { }
  getPlanningServices(){
    const url=`${api.host}/stockplanning/materiallocation/all`
    return this.http.get(url);
  }
  getPlanningServices2(Mcode,Lcode){
      const uri=`${api.host}/stockplanning/materialcode/${Mcode}/locationcode/${Lcode}`
      return this.http.get(uri);
  }
  addPlanningStock(materialCode,locationCode,quantity,plannedDate,supplier){
    const uri=`${api.host}/stockplanning/save`
    const data= {
      "materialCode":materialCode ,
      "locationCode": locationCode,
      "quantity": quantity,
      "plannedDate": plannedDate,
      "supplier": supplier
    }
    return this.http.put(uri,data);
  }
  dynamicForm(){
    const url=`${api.host}/stockplanning/metadata`
    return this.http.get(url)
  }
  getStockLocation(code){
    const url=`${api.host}/location/${code}`
    return this.http.get(url);
  }
  getStockMaterial(name){
    const url=`${api.host}/material/${name}`
    return this.http.get(url);
  }
  getStockStatus(materialCode,locationCode){
    const url=`${api.host}/reports/bpr/history/materialcode/${materialCode}/locationcode/${locationCode}?days=15`
    return this.http.get(url);
  }
  getSupplier(materialCode,locationCode){
    const data=[
      {
          "key": "materialCode",
          "value": materialCode,
          "operator": "eq"
      },
      {
          "key": "locationCode",
          "value": locationCode,
          "operator": "eq"
      }
  ]
  const url=`${api.host}/shareofbusiness/search`
  return this.http.post(url,data);
  }
  getSupplierName(supplierCode){
   const url=`${api.host}/location/${supplierCode}`;
   return this.http.get(url);
  }
  getNextPage(page){
    const url=`${api.host}/stockplanning/materiallocation/all?${page}`
    return this.http.get(url);
  }

  getLocationMaterialGrid(){
    const url=`${api.host}/stockplanning/materiallocation/grid/all`
    return this.http.get(url);
  }
}
