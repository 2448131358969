import { Component, OnInit } from '@angular/core';
import { FormDataConstructorService } from '../../../services/form-data-constructor.service';
import { ChangePwd } from '../../../properties/password_change'
import { UserService } from '../../../services/user.service'
import { AuthenticationService } from './../../../services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
@Component({
  selector: 'app-change-pwd-organisms',
  templateUrl: './change-pwd-organisms.component.html',
  styleUrls: ['./change-pwd-organisms.component.scss']
})
export class ChangePwdOrganismsComponent implements OnInit {
  formData: any;
  userId=""
  constructor(private formService: FormDataConstructorService,
    private userservice: UserService,
    private authservice: AuthenticationService,
    private toastr: ToastrService,
    private router: Router
    ) { }

  ngOnInit() {
    this.formData = this.formService.formConstructor(ChangePwd);
  }
  getuers(){
    return this.authservice.getUserId();
  }
  submitForm(formValues) {
    if(formValues['newpassword'].value===formValues['confirmnewpassword'].value){
    this.userservice.changepasswd(this.getuers(),formValues['currentpassword'].value,formValues['newpassword'].value).subscribe(res=>{
    },err=>{
      if(err.error.text === "Success"){
      this.toastr.success("Password Change Successful");
      this.router.navigate(['/'])
      }else{
        this.toastr.error("Unable to change password, may be current password is incorrect. Please try again later");
      }
    })
    }else{
    this.toastr.error("New password is not matched with Confirm password");
    }
  }
}
