import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appTypingEffect]'
})
export class TypingEffectDirective implements OnInit {
  @Input('appTypingEffect') text: string = '';
  @Input() typingSpeed: number = 30; // Speed of typing in milliseconds

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.typeText();
  }

  private typeText() {
    let index = 0;
    const intervalId = setInterval(() => {
      if (index < this.text.length) {
        this.el.nativeElement.innerHTML += this.text.charAt(index);
        index++;
      } else {
        clearInterval(intervalId);
      }
    }, this.typingSpeed);
  }
}
