import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AccessRulesPipe } from "./access-rules.pipe";
import { CapitalizePipe } from "./capitalize.pipe";
import { ImageUrl } from "./image-url.pipe";
import { CurrencyFormatPipe } from "./number-conversion.pipe";

@NgModule({
  declarations: [AccessRulesPipe, CapitalizePipe, CurrencyFormatPipe, ImageUrl],
  imports: [CommonModule],
  exports: [AccessRulesPipe, CapitalizePipe, CurrencyFormatPipe, ImageUrl],
})
export class PipesModule {}
