import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imageUrl'
})
export class ImageUrl implements PipeTransform {

  transform(value: string): string {
    if (value) {
        try{
          new URL(value);
          return value;
        }catch(e){
        }
    }
    return "/assets/img/Image_Coming_Soon.jpg";

  }

}
