import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { api } from "../properties/endpoints";

@Injectable({
  providedIn: "root",
})
export class MenuService {
  constructor(private http: HttpClient) {}
  api_endpoint = api.host;
  siteCode = this.api_endpoint
    .replace("https://", "")
    .replace(".api.flowsmart.ai", "");
  getMenuFile(role) {
    const url = `https://storage.googleapis.com/flowsmart-cms/${this.siteCode}/nav/${role}.json`;
    return this.http.get(url, { responseType: "text" });
  }
}
