import { Component, Input, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ChartjsReportsService } from "../../../../services/chartjs-reports.service";
// import * as Chart from 'chart.js';
import Chart from "chart.js/auto";
import { subHeadings } from "src/app/properties/map_sub_heading";

@Component({
  selector: "app-chatjs-organism",
  templateUrl: "./chartjs-organism.component.html",
  styleUrls: ["./chartjs-organism.component.scss"],
})
export class ChartjsOrganismComponent implements OnInit {
  @ViewChild("myChart", { static: false }) barChartElement: ElementRef;
  reports = ["Buffer_Trend_Eco", "Buffer_Trend_Tech"];
  @Input() reportName: any;
  reportNameWithSpace: any;
  // chatData: Chart.ChartData = {};
  addFilter = [];
  charts: Chart;
  type: any;
  filterValues: any;
  selectedFilters: any;
  ctx: any;
  myChart: Chart;
  chartData: any;
  subHeadings=subHeadings;
  constructor(
    private route: ActivatedRoute,
    private chatjs: ChartjsReportsService
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params && params.reportName) {
        this.reportName = params.reportName;
        this.chatjs.getReports(params.reportName).subscribe((res) => {
          if (res) {
            res["filterValues"].filter((fl) => {
              if (fl.dataType === "String") {
                this.addFilter.push(fl);
              }
            });
            this.type = res["type"];
            this.filterValues = res["filterValues"][0];
            this.selectedFilters =
              res["selectedFilters"] && res["selectedFilters"][0];
            this.initializeChart(this.type, res["data"]);
          }
        });
      }
    });
  }
  ngOnInit() {
    if (this.reportName) {
      this.chatjs.getReports(this.reportName).subscribe((res) => {
        this.reportNameWithSpace = res["displayName"];
        res["filterValues"].filter((fl) => {
          if (fl.dataType === "String") {
            this.addFilter.push(fl);
          }
        });
        this.type = res["type"];
        this.filterValues = res["filterValues"][0];
        this.selectedFilters = res["selectedFilters"][0];
        this.initializeChart(this.type, res["data"]);
      });
    }
  }
  getReportsByFilters($event: any) {
    this.myChart?.destroy();
    this.chatjs.updateFilter(this.reportName, $event).subscribe((res) => {
      if (res["data"]) {
        this.reportNameWithSpace = res["displayName"];
        this.type = res["type"];
        this.filterValues = res["filterValues"][0];
        this.selectedFilters = res["selectedFilters"][0];
        this.initializeChart(this.type, res["data"]);
      }
    });
  }
  initializeChart(type, chartData) {
    this.myChart?.destroy();
    const ctx = this.barChartElement.nativeElement.getContext("2d");
    this.myChart = new Chart(ctx, {
      type: type,
      data: chartData,
      options: {
        maintainAspectRatio: false,
        elements: {
          point: {
            radius: 4,
            backgroundColor: "rgba(0, 0, 0, 0)",
            pointStyle: "circle", // Set the point style to 'circle' for round dots
          },
          line: {
            tension: 0.5, // Set the tension to 0 for sharp curves
          },
        },
        scales: {
          x: {
            beginAtZero: true,
            grid: {
              display: false,
            },

            ticks: {
              minRotation: 90,
              maxRotation: -60,
            },
          },
          y: {
            ticks: {
              stepSize: 50,
            },
          },
        },
        layout: {
          padding: {
            top: 30,
            right: 50,
            bottom: 50,
          },
        },
      },
    });
  }
}
