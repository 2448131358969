import { Injectable } from '@angular/core';
import { FormBase } from '../interfaces/formInterface';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Injectable()
export class FormControlService {
  constructor(private fb: UntypedFormBuilder) { }
  toFormGroup(data: FormBase<any>[]) {
    const group: any = {};

    data.forEach(each => {
      if (each.controlType === 'Array' && each.identifier === 'components') { // If controlType is "Array"
        const formArray = new UntypedFormArray([]);
        each?.value?.forEach(option => { // Create new FormGroup for each option
          const formGroup = this.fb.group({
            code: [option.code, Validators.required],
            qty: [option.qty, Validators.min(1)]
          });
          formArray.push(formGroup); // Push new FormGroup to the FormArray
        });
        group[each.identifier] = formArray; // Assign FormArray to the identifier in the group object
      } else if (each.controlType === 'passwd' && each.identifier != 'currentpassword') {
        group[each.identifier] = !each.optional ? new UntypedFormControl(each.value || ''
          , [
            Validators.required, // Password is required
            Validators.minLength(8), // Password must be at least 8 characters long
            Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[!@#$%^&*(),.?":{}|<>]).{8,}$/) // Password must contain at least one special character
          ])
          : new UntypedFormControl(each.value || '');
      }
      else { // If controlType is not "Array"

        group[each.identifier] = !each.optional ? new UntypedFormControl(each.value || '', Validators.required)
          : new UntypedFormControl(each.value || '');
      }
    });
    return new UntypedFormGroup(group);
  }
}
