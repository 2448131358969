import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DownloadService } from 'src/app/services/download.service';
import { DatePipe } from "@angular/common";

@Component({
  selector: 'app-download-molecule',
  templateUrl: './download-molecule.component.html',
  styleUrls: ['./download-molecule.component.scss']
})
export class DownloadMoleculeComponent {
  @ViewChild('downloadContainer', { static: true }) downloadContainer: ElementRef;
  downloadedItems: any = [];
  showList = false;
  public datePipe = new DatePipe("en-US");
  constructor(private downloadService: DownloadService,private snack: MatSnackBar) { }

  ngOnInit() {
    this.loadItems();
  }
  loadItems() {
    this.downloadService.getDownloadsList().subscribe(
      (data) => {
        this.downloadedItems = data;
      },
      (error) => {
        console.error('Error fetching items:', error);
      }
    );
  }

  toggleList() {
    this.showList = !this.showList;
    if(this.showList){
      this.loadItems();
    }
  }
  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    if (!this.downloadContainer.nativeElement.contains(event.target)) {
      this.showList = false;
    }
  }
  downloadItem(item:any){
    this.downloadService.getDownloadFile(item.code).subscribe((res)=>{
      this.downLoadFile(res, item.reportName, "application/ms-excel")
      const msg = 'Downloaded Successfully';
      this.snack.open(msg, "close", {
        duration: 4000,
        horizontalPosition: "center",
        verticalPosition: "top",
      });
    },
    (error) => {
      const msg = `Download failed please try again later`;
      this.snack.open(msg, "close", {
        duration: 4000,
        horizontalPosition: "center",
        verticalPosition: "top",
      });
    })
  }
  downLoadFile(data: any, name: any, type: string) {
    let currentDateTime = this.datePipe.transform(
      new Date(),
      "MM_dd_yy_hh:mm:ss"
    );
    let fileName = name + "_" + currentDateTime + ".xlsx";
    const blob: any = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    let link = document.createElement("a");
    if (link.download !== undefined) {
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
