<div class="container">
  <div class="row">
    <div class="col-md-6" *ngFor="let data of staticFields">
      <app-dynamic-form-field-molecule [fieldData]="data" [form]="form"></app-dynamic-form-field-molecule>
    </div>
  </div>
</div>


<mat-tab-group>
  <mat-tab *ngFor="let tabData of splitFormData| keyvalue">
    <ng-template mat-tab-label>
      {{tabData.key}}
    </ng-template>
    <div *ngFor="let data of tabData.value">
      <app-dynamic-form-field-molecule [fieldData]="data" [form]="form"></app-dynamic-form-field-molecule>
    </div>
  </mat-tab>
</mat-tab-group>
