import { Component, Input, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { SyncfusionService } from "src/app/services/syncfusion.service";

@Component({
  selector: "app-pu-bpr-molecule",
  templateUrl: "./pu-bpr-molecule.component.html",
  styleUrls: ["./pu-bpr-molecule.component.scss"],
})
export class PuBprMoleculeComponent implements OnInit {
  data: any;
  count: any;
  tableDataFetched: boolean;
  @Input() code;
  dataSource;
  dynamic_headers: any;
  groupOptions: { columns: string[] };
  constructor(private syncfusionService: SyncfusionService) {}
  ngOnInit(): void {
    this.syncfusionService.getBPR(this.code).subscribe((res) => {
      if (res) {
        this.data = res["items"];
        this.dynamic_headers = JSON.parse(res["headerContent"]);

        // this.count = res.count;
        setTimeout(() => {
          this.tableDataFetched = true;
        });
      }
    });
  }
}
