import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { api } from "../properties/endpoints";
import { BehaviorSubject, Observable } from "rxjs";
import { of } from "rxjs";
import { AppSettingService } from "./app-setting.service";
import { DataStateChangeEventArgs} from "@syncfusion/ej2-angular-grids";
@Injectable({
  providedIn: "root",
})
export class SyncfusionService {
  private cache: { [url: string]: any } = {};
  private syncfusion_cache: { [url: string]: any } = {};
  private paginated_syncfusion_cache: { [url: string]: any } = {};
  private exportDataSubject = new BehaviorSubject<any>(null);
  exportData$: Observable<any> = this.exportDataSubject.asObservable();
   pageData: any = {};

  private operatorMap: { [key: string]: string } = {
    'equal': 'EQUAL',
    'startswith': 'STARTS_WITH',
    'contains': 'CONTAINS',
    'equalto': 'EQUAL_TO',
    'notequalto': 'NOT_EQUAL_TO',
    'greaterthan': 'GREATER_THAN',
    'lessthan': 'LESS_THAN',
    'greaterthanorequal': 'GREATER_THAN_EQUAL',
    'lessthanorequal': 'LESS_THAN_EQUAL'
  };
  constructor(private http: HttpClient, private settingService: AppSettingService) { }

  convertGridStateToPageData(state: DataStateChangeEventArgs) {
   
    //apply sorting
    if (state.sorted && state.sorted.length > 0) {
      this.pageData.sortData = {
        sortColumn: state.sorted[0].name,
        sortDesc: state.sorted[0].direction == "descending"
      }
    }
    //apply filtering
    if (state.action.requestType === 'filtering' && state.where && state.where.length > 0) {
      const filters = this.extractFilters(state.where[0]);
      if (filters.length > 0) {
        this.pageData.gridFilters = filters;
      }
    }
    //apply page size
    if (state.take) {
      this.pageData.pageSize = state.take;
    } else {
      this.pageData.pageSize = this.settingService.getAppSetting('grid.pageSizes', [50, 100])[0]
    }
    if (state.action.requestType == "paging") {
      this.pageData.pageNumber = ((state.skip / state.take) + 1);
    } else if (state.action.requestType == "sorting" || state.action.requestType == "filtering" || state.action.requestType == "searching") {
      this.pageData.pageNumber = 1;
    }
    return this.pageData;
  }
  extractFilters(data: any): any[] {
    const processPredicate = (predicate: any): any[] => {
      if (predicate.isComplex) {
        return predicate.predicates.flatMap(processPredicate);
      } else {
        return [{
          column: predicate.field,
          condition:this. operatorMap[predicate.operator.toLowerCase()],
          value: predicate.value,
        }];
      }
    };
    return data?.predicates ? data.predicates.flatMap(processPredicate) : [];
  }

  getInitialPageData(): any {
    return {
      pageNumber: 1,
      pageSize: 10//this.settingService.getAppSetting('grid.pageSizes',[50,100])[0]
    }
  }

  getPaginatedSyncfusionData(reportName, pageData = null): Observable<any> {
    const cacheKey = `${api.host}/reports/grid/${reportName}`;
    return this.http
      .post(cacheKey, pageData != null ? pageData : this.getInitialPageData())
      .pipe((response) => {
        return response;
      });
  }



  getSyncfusionData(reportName): Promise<any> {
    const cacheKey = `${api.host}/reports/grid/${reportName}`;

    if (this.syncfusion_cache[cacheKey]) {
      return Promise.resolve(this.syncfusion_cache[cacheKey]);
    } else {
      return this.http
        .get(cacheKey)
        .toPromise()
        .then((response) => {
          this.syncfusion_cache[cacheKey] = response;
          return response;
        });
    }
  }

  setExportData(data: any) {
    this.exportDataSubject.next(data);
  }
  getExistingPageData(): any {
    // If pageData exists, return it; otherwise, return initial page data
    return this.pageData && Object.keys(this.pageData).length ? this.pageData : this.getInitialPageData();
  }

  exportDataToExcel(payload: any,reportName:any): Observable<any> {
    const url = `${api.host}/reports/download/${reportName}`;
    return this.http.post(url, payload, { responseType: 'text' as 'json' });
  }


  getWithCache(materialcode, locationcode, Suppliercode): Promise<any> {
    const cacheKey = `${api.host}/reports/po/${materialcode}/${locationcode}/${Suppliercode}`;

    if (this.cache[cacheKey]) {
      return Promise.resolve(this.cache[cacheKey]);
    } else {
      return this.http
        .get(cacheKey)
        .toPromise()
        .then((response) => {
          this.cache[cacheKey] = response;
          return response;
        });
    }
  }

  getSCMdata() {
    const url = `${api.host}/planning/production_intent/grid/all`;
    return this.http.get(url);
  }

  getSalesForecastByChannel() {
    const url = `${api.host}/reports/grid/ROS_By_Product_Channel`;
    return this.http.get(url);
  }

  getSalesForecast() {
    const url = `${api.host}/reports/grid/ROS_By_Product`;
    return this.http.get(url);
  }

  getSalesGridTable(code, channel = null) {
    let url = `${api.host}/reports/sales/grid/${code}`;
    if (channel) {
      url += `/${channel}`;
    }
    return this.http.get(url);
  }

  getSalesLineGraph(code, type, channel = null) {
    let url = `${api.host}/reports/sales/linegraph/${type}/${code}`;
    if (channel) {
      url += `/${channel}`;
    }
    return this.http.get(url);
  }

  getStockForEcoTrend(code) {
    const url = `${api.host}/reports/listing/stock-trend/${code}`;
    return this.http.get(url);
  }
  getBPR(code) {
    const url = `${api.host}/reports/listing/buffer-report/${code}`;
    return this.http.get(url);
  }
  createWorkOrders(payload: object) {
    const url = `${api.host}/planning/production_intent/save`;
    return this.http.put(url, payload)
  }
}
