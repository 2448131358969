import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TypingEffectDirective } from './typing-effect.directive';



@NgModule({
  declarations: [
    // TypingEffectDirective
  ],
  imports: [
    CommonModule
  ]
})
export class DirectivesModule { }
