<div class="fs-padding-0-1 fs-margin-top-1">
  <button *ngIf="!getCodeFromParent" class="mat-primary fs-margin-bottom-1" mat-raised-bu tton (click)="navigateBack()">
    <mat-icon>arrow_back</mat-icon>Go back
  </button>
  <div *ngIf="!itemData">
    <div class="product-overview fs-margin-bottom-1">
      <div class="product-detail-card fs-padding-0-1"
        style="border-radius:10px;background-color: rgb(211, 211, 211, 0.2)">
        <div style="font-weight: lighter;width: 60%" class="flowsmart-placeholder fs-padding-1 fs-margin-1-0"></div>
        <div style="font-weight: lighter;width: 40%" class="flowsmart-placeholder fs-padding-1 fs-margin-1-0"></div>
        <div style="font-weight: lighter;width: 80%" class="flowsmart-placeholder fs-padding-1 fs-margin-1-0"></div>
        <div style="font-weight: lighter;width: 80%" class="flowsmart-placeholder fs-padding-1 fs-margin-1-0"></div>
      </div>
      <div class="mat-elevation-z4 fs-padding-1 chart-section">
        <div style="font-weight: lighter;width: 60%" class="flowsmart-placeholder fs-padding-1 fs-margin-1-0"></div>
        <div style="font-weight: lighter;width: 40%" class="flowsmart-placeholder fs-padding-1 fs-margin-1-0"></div>
        <div style="font-weight: lighter;width: 80%" class="flowsmart-placeholder fs-padding-1 fs-margin-1-0"></div>
        <div style="font-weight: lighter;width: 80%" class="flowsmart-placeholder fs-padding-1 fs-margin-1-0"></div>
      </div>
    </div>
  </div>
  <div *ngIf="itemData">
    <div class="product-overview fs-margin-bottom-1">
      <div class="product-detail-card">
        <app-product-card-molecule [disableRoute]="disableRoute" [item]="itemData"
          [detailedView]="true"></app-product-card-molecule>
      </div>
      <div *ngIf="showAnalytics" class="flip-card" [ngClass]="{'flipped': isFlipped}"
        (click)="flipCard()">
        <div class="flip-card-inner">
          <!-- Front side (Line Chart) -->
          <div class="flip-card-front chart-section">
            <p class="chart-header" style="margin: 5px 0 0px 0px;">
              Conversion Trend
            </p>
            <div style="padding: 0rem 1rem 0 1rem;"> <canvas #myChart ></canvas></div>
            <div class="product-performance fs-flex">
              <div class="metric-score">
                <div class="kpi"><strong>{{this.analyticsChartData?.viewed}}</strong></div>
                <label>Impressions</label>
              </div>
              <mat-divider [vertical]="true"></mat-divider>
              <div class="metric-score">
                <div class="kpi"><strong>{{this.analyticsChartData?.addedToCart}}</strong></div>
                <label>Added to Cart</label>
              </div>
              <mat-divider [vertical]="true"></mat-divider>
              <div class="metric-score">
                <div class="kpi"><strong>{{this.revenuePerImpressions}}</strong></div>
                <label>Revenue per Impression</label>
              </div>
            </div>
          </div>

          <!-- Back side (Flipped content - Bar Chart) -->
          <div class="flip-card-back fs-padding-1 chart-section">
            <p class="chart-header">
              Rate of sale
              <i style="font-size: 0.8em;" class="fs-hint-text">(30 v/s 90 days)</i>
            </p>
            <app-dashboard-chatjs-molecule [chartData]="config" [htmlLegend]="false"></app-dashboard-chatjs-molecule>
          </div>
        </div>
      </div>
      <div *ngIf="!showAnalytics" class="fs-padding-1 chart-section">
        <p class="chart-header">Rate of sale <i style="font-size: 0.8em;" class="fs-hint-text">(30 v/s 90 days)</i></p>
        <app-dashboard-chatjs-molecule [chartData]="config" [htmlLegend]="false"></app-dashboard-chatjs-molecule>
      </div>
    </div>
    <!--
    <div class="mat-elevation-z4 fs-margin-bottom-1">
      <mat-toolbar *ngIf="salesChannelList.length>1 class="justify-content-between">
        <span class="mat-headline-2 fs-margin-0">Sales Momentum</span>
        <mat-form-field class="fs-padding-0-1 mat-body-1">
          <mat-select [(value)]="selectedPivot" [multiple]="false" panelWidth="max-content"
            (selectionChange)="onOptionSelection($event)">
            <mat-option *ngFor="let item of salesChannelList" [value]="item">{{
              item
              }}</mat-option>
          </mat-select>
        </mat-form-field>
      </mat-toolbar>
      <app-dashboard-chatjs-molecule *ngIf="ChannelChange" [chartData]="chartData"
        chartType="{{ chartData.type }}"></app-dashboard-chatjs-molecule>
    </div>

    <app-grid-detail-sales-forecast-molecule [code]="code"></app-grid-detail-sales-forecast-molecule>-->
    <div class="fs-margin-2-0" *ngIf="!getCodeFromParent">
      <h3 class="fs-font-weight-700" style="text-align:center">Sales Channel Performance Metrics</h3>
      <table mat-table [dataSource]="salesChannelTableData" class="mat-elevation-z4">
        <ng-container matColumnDef="PerformanceIndicator">
          <th mat-header-cell *matHeaderCellDef> Performance Metrics </th>
          <td mat-cell *matCellDef="let element"> {{element.metric}} </td>
        </ng-container>


        <!-- Mertics Column -->
        <ng-container matColumnDef="{{column}}" *ngFor="let column of salesChannelList">
          <th mat-header-cell *matHeaderCellDef> {{column}} </th>
          <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="salesChannelTableDataHeader"></tr>
        <tr mat-row *matRowDef="let row; columns: salesChannelTableDataHeader;"></tr>
      </table>
    </div>
  </div>

</div>
<app-detail-page-accordian-molecule *ngIf="itemData && !getCodeFromParent" [data]="itemData"
  [code]="code"></app-detail-page-accordian-molecule>