import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {api} from '../properties/endpoints';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {
  evaluationTypes: string[] = ['and', 'or'];
  constructor(private http: HttpClient) { }
  getEntities(){
    const url = `${api.host}/material/work-flow/entity/list`;
    return this.http.get(url);
  }
  getMetaData(){
    const url = `${api.host}/material/work-flow/meta-data`;
    return this.http.get(url);
  }

  getAllWorkflows() {
    const url = `${api.host}/material/work-flow/list`;
    return this.http.get(url);
  }
  deleteWorkflowData(workflowId: string){
    const url = `${api.host}/material/work-flow/${workflowId}`;
    return this.http.delete(url,{responseType: 'text'});
  }
  createWorkflow(payload){
    const url = `${api.host}/material/work-flow/create`;
    return this.http.put(url,payload);
  }
  getWorkflowData(workflowId: string) {
    const url = `${api.host}/material/work-flow/${workflowId}`;
    return this.http.get(url);
  }
  updateWorkflow(payload){
    const url = `${api.host}/material/work-flow/update`;
    return this.http.post(url,payload);
  }
 
  getEvaluationTypes(): Observable<string[]> {
    return of(this.evaluationTypes);
  }

  minutesToCron(minutes: number): string {
    return `*/${minutes} * * * *`;
  }

  hourlyToCron(hours: number): string {
    return `0 */${hours} * * *`;
  }

  dailyToCron(time: string, days: number): string {
    const [hours, minutes] = time.split(':');
    return `${minutes} ${hours} */${days} * *`;
  }

  weeklyToCron(time: string, days: string[]): string {
    const [hours, minutes] = time.split(':');
    const daysOfWeek = days.map(day => this.getDayNumber(day)).join(',');
    return `${minutes} ${hours} * * ${daysOfWeek}`;
  }

  monthlyToCron(time: string, day: number, months: number): string {
    const [hours, minutes] = time.split(':');
    return `${minutes} ${hours} ${day} */${months} *`;
  }

  yearlyToCron(time: string, day: number, month: number): string {
    const [hours, minutes] = time.split(':');
    return `${minutes} ${hours} ${day} ${month} *`;
  }

  private getDayNumber(day: string): number {
    const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    return days.indexOf(day);
  }

}
