<div *ngIf="showSection1" style="display:grid">
    <span class="new_head"><span class="new_home_head" >Key Performance Indicator </span> 
    <span class="chatbot_botIcon">{{getName()}}</span></span></div>
  <!-- <app-business-intelligence-organsim></app-business-intelligence-organsim> -->
  <div *ngIf="showSection2" class="business_score_card">
      <div class="bsc_summary new_home_page_summary" appTypingEffect [appTypingEffect]="text2" [typingSpeed]="20" ><svg version="1.1" style="border: 1px solid #d3d3d3b3;border-radius:50%; padding:2px; margin-right:10px" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" xml:space="preserve" width="20px" height="20px" fill="#EC9600" stroke="#EC9600"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <style type="text/css"> .st0{fill:none;stroke:#EC9600;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;} </style> <g> <path class="st0" d="M17.9,9.9c-4.6,0.9-6,2.3-6.9,6.9c-0.9-4.6-2.3-6-6.9-6.9C8.7,9,10.1,7.6,11,3C11.9,7.6,13.3,9,17.9,9.9z"></path> </g> <g> <path class="st0" d="M21.8,25c-3.2,0.6-4.1,1.6-4.8,4.8c-0.6-3.2-1.6-4.1-4.8-4.8c3.2-0.6,4.1-1.6,4.8-4.8 C17.6,23.4,18.6,24.4,21.8,25z"></path> </g> <g> <path class="st0" d="M29,15c-2.6,0.5-3.4,1.3-3.9,3.9c-0.5-2.6-1.3-3.4-3.9-3.9c2.6-0.5,3.4-1.3,3.9-3.9C25.6,13.7,26.4,14.5,29,15 z"></path> </g> <line class="st0" x1="5" y1="23" x2="5" y2="23"></line> <line class="st0" x1="28" y1="6" x2="28" y2="6"></line> </g></svg></div>
      <span *ngIf="showSection6" class="details_btn"  (click)="navigateToRevenueInsights()" appTypingEffect [appTypingEffect]="text4" [typingSpeed]="20"  ></span>
  <app-business-score-card [isDashboardSummary]="true" *ngIf="showSection3" [changesFromParent]="changesFromParent"></app-business-score-card>
  </div>
  <!-- <app-dashboard-chatjs-molecule></app-dashboard-chatjs-molecule> -->
  <ng-container *ngIf="scoreCardResponse">
      <div *ngIf="showSection4" class="returns_analyzer">
          <div class="ra_summary new_home_page_summary" appTypingEffect [appTypingEffect]="text3" [typingSpeed]="20" ><svg version="1.1" style="border: 1px solid #d3d3d3b3;border-radius:50%; padding:2px; margin-right:10px" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" xml:space="preserve" width="20px" height="20px" fill="#EC9600" stroke="#EC9600"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <style type="text/css"> .st0{fill:none;stroke:#EC9600;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;} </style> <g> <path class="st0" d="M17.9,9.9c-4.6,0.9-6,2.3-6.9,6.9c-0.9-4.6-2.3-6-6.9-6.9C8.7,9,10.1,7.6,11,3C11.9,7.6,13.3,9,17.9,9.9z"></path> </g> <g> <path class="st0" d="M21.8,25c-3.2,0.6-4.1,1.6-4.8,4.8c-0.6-3.2-1.6-4.1-4.8-4.8c3.2-0.6,4.1-1.6,4.8-4.8 C17.6,23.4,18.6,24.4,21.8,25z"></path> </g> <g> <path class="st0" d="M29,15c-2.6,0.5-3.4,1.3-3.9,3.9c-0.5-2.6-1.3-3.4-3.9-3.9c2.6-0.5,3.4-1.3,3.9-3.9C25.6,13.7,26.4,14.5,29,15 z"></path> </g> <line class="st0" x1="5" y1="23" x2="5" y2="23"></line> <line class="st0" x1="28" y1="6" x2="28" y2="6"></line> </g></svg></div>
          <span *ngIf="showSection6" class="details_btn" (click)="navigateToReturnsAnalyzer()" appTypingEffect [appTypingEffect]="text4" [typingSpeed]="20"  ></span>
      <div *ngIf="showSection5" class="scorecard-container">
        <ng-container *ngFor="let scorecard of scoreCardData">
          <div class="score-card mat-elevation-z4 {{scorecard.code}}">
            <div class="name">
              {{ scorecard.name | capitalize }}
            </div>
            <div class="value-container">
              <div class="icon">
                <!--populated through css-->
              </div>
              <div class="value">
                <div>
                  {{scorecard.value}}<span *ngIf="scorecard.code == 'return_percentage'">%</span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      </div>
    </ng-container>
 
    