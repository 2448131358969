<div class="fs-section-block fs-margin-0 header-background ">
  <div class="d-flex justify-content-between align-items-center">
    <div>
      <span class="mat-headline">Cache Management</span>
      <p style="font-family: lato;" class="fs-hint-text">Configure the system for better performance</p>
    </div>
  </div>
</div>
<div class="container my-3">
  <div class="col-md-12 text-center d-flex justify-content-center">
    <button (click)="selectAll()" class="mr-2" type="button" mat-flat-button color="primary">
      Select All <mat-icon>select_all</mat-icon>
    </button>
    <button (click)="deleteAll()" class="mr-2" type="button" mat-flat-button color="primary">
      Clear All <mat-icon>delete_outline</mat-icon>
    </button>
    <button (click)="clearSelected()" class="mr-2" type="button" mat-flat-button color="primary">
      Clear Selected <mat-icon>delete_outline</mat-icon>
    </button>
  </div>
</div>

<mat-accordion #checkboxes class="example-headers-align">
  <mat-expansion-panel hideToggle *ngFor="let item of caches">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong>{{ item.name }}</strong>
      </mat-panel-title>
      <mat-panel-description>
        <i> Items cached {{ item.itemsCached }}</i>
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(item.name) : null"
          [checked]="selection.isSelected(item.name)"></mat-checkbox>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <table mat-table [dataSource]="[item]" class="mat-elevation-z8">
      <ng-container matColumnDef="itemsCached">
        <th mat-header-cell *matHeaderCellDef>Items Cached</th>
        <td mat-cell *matCellDef="let element">{{ element.itemsCached }}</td>
      </ng-container>
      <ng-container matColumnDef="hitCount">
        <th mat-header-cell *matHeaderCellDef>Hit Count</th>
        <td mat-cell *matCellDef="let element">{{ element.hitCount }}</td>
      </ng-container>
      <ng-container matColumnDef="missCount">
        <th mat-header-cell *matHeaderCellDef>Miss Count</th>
        <td mat-cell *matCellDef="let element">{{ element.missCount }}</td>
      </ng-container>
      <ng-container matColumnDef="loadSuccessCount">
        <th mat-header-cell *matHeaderCellDef>Load Success Count</th>
        <td mat-cell *matCellDef="let element">
          {{ element.loadSuccessCount }}
        </td>
      </ng-container>
      <ng-container matColumnDef="loadFailureCount">
        <th mat-header-cell *matHeaderCellDef>Load Failure Count</th>
        <td mat-cell *matCellDef="let element">
          {{ element.loadFailureCount }}
        </td>
      </ng-container>
      <ng-container matColumnDef="totalLoadTime">
        <th mat-header-cell *matHeaderCellDef>Total Load Time</th>
        <td mat-cell *matCellDef="let element">{{ element.totalLoadTime }}</td>
      </ng-container>
      <ng-container matColumnDef="evictionCount">
        <th mat-header-cell *matHeaderCellDef>Eviction Count</th>
        <td mat-cell *matCellDef="let element">{{ element.evictionCount }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </mat-expansion-panel>
</mat-accordion>
