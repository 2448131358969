import { Component } from '@angular/core';
import {AuthenticationService} from "../../../services/authentication.service";

@Component({
  selector: 'app-inventory-health-organism',
  templateUrl: './inventory-health-organism.component.html',
  styleUrls: ['./inventory-health-organism.component.scss']
})
export class InventoryHealthOrganismComponent {
  dashboardReports: any;
  constructor(private authenticationService: AuthenticationService) { }
  ngOnInit(): void {
    this.authenticationService.getRoleDetail().subscribe(data => {
      if(data?.dashboardReports){
        this.dashboardReports = data.dashboardReports
      }
    });
  }
}
