import { Component, Input, OnInit } from '@angular/core';
import { PlanningStockService } from '../../../services/planning-stock.service'
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
@Component({
  selector: 'app-stock-planning-table',
  templateUrl: './stock-planning-table.component.html',
  styleUrls: ['./stock-planning-table.component.scss']
})
export class StockPlanningTableComponent implements OnInit {
  @Input() materialCode: any;
  @Input() locationCode: any;
  loc: any;
  mName: any;
  locationCode$ = new Subscription();
  MaterialCode$ = new Subscription();
  constructor(private planningstockservice: PlanningStockService) { }
  ngOnInit() {
    this.locationCode$ = this.planningstockservice.getStockLocation(this.locationCode).subscribe(data => {
      if (data) {
        this.loc = data['name'];
      }
    });
    this.MaterialCode$ = this.planningstockservice.getStockMaterial(this.materialCode).subscribe(data => {
      if (data) {
        this.mName = data['name']
      }
    })
  }
  ngOnDestroy() {
    if (this.locationCode$) {
      this.locationCode$.unsubscribe();
    }
    if (this.MaterialCode$) {
      this.MaterialCode$.unsubscribe();
    }
  }
}
