import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api } from "../properties/endpoints";

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor(private http: HttpClient) { }

  getDownloadsList(){
    const url = `${api.host}/reports/download/list`;
    return this.http.get(url);
  }
  getDownloadFile(code) {
    const url = `${api.host}/reports/download/file?code=${code}`;
    return this.http.get(url, { responseType: 'blob' });
  }
}
