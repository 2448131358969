<div class="fs-padding">
  <div class="fs-section-block fs-margin-0 header-background ">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <span class="mat-headline">Raw Material Availability</span>
        <p class="fs-hint-text">Required raw materials for the replenishment cycle</p>
      </div>
    </div>
  </div>
</div>

<app-bom-availability-state [displayName]="" [tableHeight]="500"></app-bom-availability-state>
