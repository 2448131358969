import { Component, OnInit } from '@angular/core';
import { DataProcessingService } from '../../../services/data-processing.service'
import { MatTableDataSource } from '@angular/material/table';
import { listUploadedUser } from '../../../properties/tableConfig'
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-upload-files-molecules',
  templateUrl: './upload-files-molecules.component.html',
  styleUrls: ['./upload-files-molecules.component.scss'],
  providers: [DatePipe]
})
export class UploadFilesMoleculesComponent implements OnInit {
  uploadName = 'upload file';
  dataSource = new MatTableDataSource<any>([]);
  displayStockColumns = listUploadedUser;
  selectedFile: File | null = null;
  ELEMENT_DATA: any;
  file: any
  selectedOption: string | null = null;
  selectedFileType: string | null = null;
  selected: boolean = false;
  constructor(private uploadfiles: DataProcessingService, private authenticationService: AuthenticationService,
    private toastrservice: ToastrService, public datepipe: DatePipe) {
  }

  ngOnInit() {
    this.loadUserTable();
  }

  getFile(event: any){
    this.file = event.target.files[0];
  }

  addSpaces(str: string): string {
    if(str==='filename'){
      return 'File Name'
    }
    return str.replace(/([a-z])([A-Z])/g, '$1 $2');
  }
  fileUpload(event: any) {
    if(this.file.name){
      this.uploadfiles.uploadFiles(this.file, this.authenticationService.getUserName(),this.selectedOption).subscribe(res => {
        this.toastrservice.success(this.file.name + " " + "uploaded Successfully");
        this.loadUserTable();
        this.file=undefined


      }, err => {
        this.toastrservice.error("Failed to upload", this.uploadName);
      })
    }
  }
downloadTemplate(name){
  this.uploadfiles.downloadTemplate(name)
}

  formatFileSize(size: number): string {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB'];
    let index = 0;
    while (size >= 1024 && index < units.length - 1) {
      size /= 1024;
      index++;
    }
    return size.toFixed(0) + ' ' + units[index];
  }

  loadUserTable() {
    this.uploadfiles.getUploadedBy().subscribe(res => {
      this.ELEMENT_DATA = res;
      this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
    })
  }

  formatDate(dateString?) {
    const dateObj = new Date(dateString);
    return this.datepipe?.transform(dateObj, 'dd-MMM hh:mm a');
  }

  downloadFile(row){
    this.uploadfiles.downloadFile(row.filename).subscribe(
      ({ blob, filename }) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();
      },
      error => {
        console.error('Error downloading file:', error);
      }
    );
  }
}
