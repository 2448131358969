<p class="col-12">All Fields marked with * are mandatory</p>

<mat-card class='form-wrapper'>

  <!-- <app-tab-container-molecule></app-tab-container-molecule> -->

    <form [formGroup]="form" *ngIf="formData">
      <div *ngIf="formName!='stockForm'">
        <div *ngFor="let data of formData" class="form-row">
            <app-dynamic-form-field-molecule [fieldData]="data" [form]="form"></app-dynamic-form-field-molecule>
        </div>
      </div>
        <div *ngIf="formName=='stockForm'">
        <app-tab-container-molecule [formData]="formData" [form]="form"></app-tab-container-molecule>
      </div>
        <div class="form-row">
            <button mat-raised-button color='primary' type="submit" (click)="save()" [disabled]="!form.valid">Save</button>
        </div>
    </form>

</mat-card>

