import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { api } from '../properties/endpoints';

@Injectable({
  providedIn: 'root'
})
export class SCMManagementService {

  constructor(private http: HttpClient) { }

  getSCMForm() {
    // const api = '../../assets/mock/allMetaData.json';
    const url = `${api.host}/planning/production_intent/metadata`;
    return this.http.get(url);
  }

  getSCMByCode(code) {
    // const api = '../../assets/mock/getMaterialByCode.json';
    const url = `${api.host}/planning/production_intent/${code}`
    return this.http.get(url);
  }
  saveMaterial(formValues, formData, addFlag, id) {
    const url = `${api.host}/planning/production_intent/save`
    let resultJson = {};
    resultJson['id'] = id;
    formData.map((eachFormData) => {
      resultJson[eachFormData.identifier] = formValues[eachFormData.identifier].value;
    });
    if (!addFlag)
      return this.http.post(url, resultJson);
    return this.http.put(url, resultJson);
  }
}
