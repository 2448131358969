import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "currencyFormat",
})
export class CurrencyFormatPipe implements PipeTransform {
  currency_symbols = {
    USD: "$",
    EUR: "€",
    GBP: "£",
    ILS: "₪",
    INR: "₹",
    JPY: "¥",
    KRW: "₩",
    NGN: "₦",
    PHP: "₱",
    PLN: "zł",
    PYG: "₲",
    THB: "฿",
    UAH: "₴",
    VND: "₫",
  };
  transform(value: number, currency: string): unknown {
    const val = Math.abs(value);
    if (val >= 10000000)
      return `${this.currency_symbols[currency]} ${(value / 10000000).toFixed(
        2
      )} `;
    if (val >= 100000)
      return `${this.currency_symbols[currency]} ${(value / 100000).toFixed(2)}`;
    return `${this.currency_symbols[currency]} ${value}`;
  }
}
