import { Component, OnInit } from "@angular/core";
import { FilterBayService } from "src/app/services/filter-bay.service";
import { DatePipe } from "@angular/common";
@Component({
  selector: "app-business-intelligence-organsim",
  templateUrl: "./business-intelligence-organsim.component.html",
  styleUrls: ["./business-intelligence-organsim.component.scss"],
})
export class BusinessIntelligenceOrgansimComponent {
  changesFromParent: any;
  showOverlay: boolean;
  addFilters: any;
  filtersLength: number;
  changesDetected: boolean;
  public datePipe = new DatePipe("en-US");
  constructor(private filterBayService: FilterBayService) {}

  ngOnInit(): void {
    if(!this.changesFromParent){
      this.changesFromParent = this.getDefaultDateRanges();
    }
  }
  selectedFilters(event) {
    this.changesFromParent = event;
    this.showOverlay = false;
    // this.changesDetected = true;
  }

  getDefaultDateRanges(): any {
    let payload: any = {};
    if (payload == null && payload.startDate == null) {
      let temp = new Date();
      temp.setDate(temp.getDate() - 30);
      payload.startDate = this.datePipe.transform(temp, "yyyy-MM-dd");
    }

    if (payload == null && payload.endDate == null) {
      payload.endDate = this.datePipe.transform(
        new Date(),
        "yyyy-MM-dd"
      );
    }
    return payload;
  }

  // removeFilters(filter) {
  //   this.changesDetected = !this.changesDetected;
  //   this.filterBayService.setFilters(filter);
  // }

  toggleOverlay() {
    this.showOverlay = !this.showOverlay;
  }
  getFilters(event) {
    this.addFilters = event;
    this.filtersLength = Object.keys(this.addFilters).length;
  }
}
