const getOrCreateLegendList = (chart) => {
    let { canvas, chartArea } = chart;
    let { height, width } = chartArea;
    let listContainer;
    if (canvas.nextSibling) {
        listContainer = canvas.nextSibling;
    } else {
        listContainer = document.createElement('ul');
        canvas.nextSibling = listContainer;
    }
        listContainer.style.display = 'flex';
        listContainer.style.flexFlow = 'row wrap';
        listContainer.style.margin = "5px auto";
        listContainer.style.padding = "0px";
        listContainer.style.maxWidth = `${width * .85}px`;
        listContainer.style.maxHeight = '6em';
        listContainer.style.overflowY = "auto";
    return listContainer;
};

export const htmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate(chart, args, options) {
        const ul = getOrCreateLegendList(chart);
        // Remove old legend items
        while (ul.firstChild) {
            ul.firstChild.remove();
        }

        // Reuse the built-in legendItems generator
        const items = chart.options.plugins.legend.labels.generateLabels(chart);

        items.forEach(item => {
            const li = document.createElement('li');
            li.style.alignItems = 'center';
            li.style.cursor = 'pointer';
            li.style.display = 'flex';
            li.style.flexDirection = 'row';
            li.style.marginLeft = '10px';
            li.style.width = 'max-content';

            li.onclick = () => {
                const { type } = chart.config;
                if (type === 'pie' || type === 'doughnut') {
                    // Pie and doughnut charts only have a single dataset and visibility is per item
                    chart.toggleDataVisibility(item.index);
                } else {
                    chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                }
                chart.update();
            };

            // Color box
            const boxSpan = document.createElement('span');
            boxSpan.style.background = item.fillStyle;
            boxSpan.style.borderColor = item.strokeStyle;
            boxSpan.style.borderWidth = item.lineWidth + 'px';
            boxSpan.style.display = 'inline-block';
            boxSpan.style.flexShrink = "0";
            boxSpan.style.height = '10px';
            boxSpan.style.marginRight = '4px';
            boxSpan.style.width = '30px';

            // Text
            const textContainer = document.createElement('p');
            textContainer.style.color = item.fontColor;
            textContainer.style.margin = "0px";
            textContainer.style.padding = "0px";
            textContainer.style.width = "max-content";
            textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

            const text = document.createTextNode(item.text);
            textContainer.appendChild(text);

            li.appendChild(boxSpan);
            li.appendChild(textContainer);
            ul.appendChild(li);
        });
    }
};

