import { Component } from '@angular/core';

@Component({
  selector: 'app-trends-and-reports-organsim',
  templateUrl: './trends-and-reports-organsim.component.html',
  styleUrls: ['./trends-and-reports-organsim.component.scss']
})
export class TrendsAndReportsOrgansimComponent {

}
