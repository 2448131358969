<div class="pt-2 pl-2">
  <button mat-button mat-raised-button color="primary" routerLink='/WorkOrders'>
      <span class="material-icons">
          arrow_back
      </span>
      Back
  </button>
</div>

<mat-toolbar>SCM Management</mat-toolbar>
<app-dynamic-form-molecule *ngIf="formData" [formData]='formData' [addFlag]='true' (submitForm)="submitForm($event)"></app-dynamic-form-molecule>
