<mat-toolbar class="justify-content-between">
  <span class="mat-headline fs-margin-0">Sales Distribution</span>
  <div class="tools-container">
    <div class="visual-switcher" *ngIf="visualizationDataList">
      <span class="icon" (click)="setTableView(false)"><mat-icon class="tool-tip" matTooltip="View as chart" [ngClass]="{
      'selected': !showTableView,
      'not-selected' : showTableView
    }">bar_chart</mat-icon></span>
      <span class="icon" (click)="setTableView(true)"><mat-icon class="tool-tip" matTooltip="View as table" [ngClass]="{
      'selected': showTableView,
      'not-selected' : !showTableView
    }">table_chart</mat-icon></span>
    </div>
    <mat-form-field *ngIf="pivotFields" class="fs-padding-0-1 mat-body-1">
      <mat-label>{{selectedPivot.name}}</mat-label>
      <mat-select [(value)]="selectedPivot" [multiple]="false" panelWidth="max-content"
        (selectionChange)="onOptionSelection($event)">
        <mat-option *ngFor="let item of pivotFields" [value]="item.code">{{
          item.name
          }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-toolbar>
<div class="fs-margin-1-0 charts-container" style="height:max-content">
  <div *ngIf="loadComplete && !visualizationDataList">
    <h3 style="text-align: center;">No Data Available</h3>
  </div>
  <div class="charts-box" *ngIf="!loadComplete">
    <div class="fs-padding-1 chart-section">
      <p class="flowsmart-placeholder placeholder-template fs-margin-2 fs-padding-1-0"></p>
      <p class="flowsmart-placeholder placeholder-template fs-padding-2"></p>
      <p class="flowsmart-placeholder placeholder-template fs-margin-2 fs-padding-1-0"></p>
    </div>
    <div class="fs-padding-1 table-section" style="margin-top: 0px;">
      <p class="flowsmart-placeholder placeholder-template fs-margin-2 fs-padding-1-0"></p>
      <p class="flowsmart-placeholder placeholder-template fs-padding-2"></p>
      <p class="flowsmart-placeholder placeholder-template fs-margin-2 fs-padding-1-0"></p>
    </div>
  </div>
  <div class="fs-margin-1" *ngIf="loadComplete && visualizationDataList" style="height:max-content">
    <div class="chart-section" *ngFor="let visualizationData of visualizationDataList">
      <app-dashboard-chatjs-molecule enableZoom="true" [header]="visualizationData.displayName" *ngIf="!showTableView"
        [chartData]="visualizationData"></app-dashboard-chatjs-molecule>
    </div>
    <div class="table-section">
      <div *ngIf="tableData && showTableView">
        <app-dashboard-grids-molecule class="chartjs-charts" [header]="tableData.header" [chartData]="tableData"
          [frozenColumns]="1"></app-dashboard-grids-molecule>
      </div>
    </div>
  </div>
</div>