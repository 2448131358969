import { Injectable, ElementRef, Renderer2, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';


@Injectable()
export class CommonUtilityService {
    constructor(
        @Inject(PLATFORM_ID) private plateformId: object,
        private http: HttpClient,
        private cookieService: CookieService,
        private router: Router) { }

    /**
     * @param input object to be validated
     * @returns true if object is undefined or empty, otherwise false
     */
    isUndefinedOrEmpty(input: any) {
        if (undefined !== input && '' !== input) {
            return false;
        } else {
            return true;
        }
    }

    /**
     * @param input object to be validated
     * @returns true if object is undefined or null, otherwise false
     */
    isNullOrUndefined(input: any) {
        if (undefined !== input && null !== input) {
            return false;
        } else {
            return true;
        }
    }

    /**
     * Checks if application is running in browser
     */
    isBrowser(): boolean {
        if (isPlatformBrowser(this.plateformId)) {
            return true;
        } else {
            return false;
        }
    }

    generateRandomColorsFromBaseColor(baseColor: string, numColors: number): string[] {
        // Convert the base color to RGB components
        const baseRgb: number[] = baseColor.match(/\w\w/g)?.map(hex => parseInt(hex, 16)) || [0, 0, 0];
    
        const colors: string[] = [];
        for (let i = 0; i < numColors; i++) {
            // Generate random offsets for each RGB component
            const rOffset: number = Math.floor(Math.random() * 51) - 25; // Random value between -25 and 25
            const gOffset: number = Math.floor(Math.random() * 51) - 25;
            const bOffset: number = Math.floor(Math.random() * 51) - 25;
    
            // Apply offsets to base RGB values (clamped to 0-255)
            const r: number = Math.max(0, Math.min(255, baseRgb[0] + rOffset));
            const g: number = Math.max(0, Math.min(255, baseRgb[1] + gOffset));
            const b: number = Math.max(0, Math.min(255, baseRgb[2] + bOffset));
    
            // Construct the new color
            const color: string = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
            colors.push(color);
        }
        return colors;
    }





    setCookie(key: any, value: any) {
        if (this.isBrowser()) {
            this.cookieService.put(
                key,
                value,
                {
                    path: '/',
                    domain: this.isBrowser() ? window.location.hostname : ''
                })
        }
    }

    checkLoggedInOrRedirect() {
        if (this.getCookie('isAuthenticated')) {
            // logged in so return true
            return true;
        }
        // not logged in so redirect to login page with the return url and return false
        const routeState = this.router.routerState.snapshot;
        this.router.navigate(['login'], { queryParams: { returnUrl: routeState.url } });
        return false;
    }

    getCookie(key) {
        if (this.isBrowser()) {
            return this.cookieService.get(key);
        }
        return;
    }

    removeCookie(key: any) {
        if (this.isBrowser()) {
            this.cookieService.remove(
                key,
                {
                    path: '/',
                    domain: this.isBrowser() ? window.location.hostname : ''
                })
        }
    }
}

