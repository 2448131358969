export const dynamicNormReview = [
  {
    "field": "materialCode",
    "headerText": "Material",
    "allowFiltering": true,
    "width": "150",
    "filter": { "type": "Menu" }
  },
  {
    "field": "parentProductCode",
    "headerText": "Style",
    "allowFiltering": true,
    "width": "150",
    "filter": { "type": "Menu" }
  },
  {
    "field": "name",
    "headerText": "Description",
    "allowFiltering": true,
    "width": "250",
    "filter": {
      "type": "Menu"
    }
  },
  {
    "field": "imageUrl",
    "headerText": "Image",
    "width": "120",
    "image": true
  },
  {
    "field": "locationCode",
    "headerText": "Location",
    "allowFiltering": true,
    "width": "150",
    "filter": { "type": "CheckBox" }
  },
  {
    "field": "replenishmentLeadTimeDays",
    "headerText": " Days of Stock (Lead Time)",
    "allowFiltering": false,
    "width": "200"
  },
  {
    "field": "targetSafetyStock",
    "headerText": "Current Norm",
    "allowFiltering": false,
    "width": "150"
  },
  {
    "field": "currentConsumptionRate",
    "headerText": "Current Consumption Rate",
    "allowFiltering": false,
    "width": "200"
  },
  {
    "field": "proposedConsumptionRate",
    "headerText": "Proposed Consumption Rate",
    "allowFiltering": false,
    "width": "220"
  },
  {
    "field": "suggestedNormValue",
    "headerText": "Proposed Norm",
    "allowFiltering": false,
    "width": "150"
  },
  {
    "field": "lastUpdated",
    "headerText": "Last Updated",
    "allowFiltering": false,
    "width": "200"
  },
  {
    "field": "customNormValue",
    "headerText": "Custom Norm",
    "allowFiltering": false,
    "width": "150",
  },
  {
    "field": "action",
    "headerText": "Action",
    "allowFiltering": false,
    "width": "150",
  }
]
