import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-home-page-template',
  templateUrl: './home-page-template.component.html',
  styleUrls: ['./home-page-template.component.scss']
})
export class HomePageTemplateComponent implements OnInit {
  constructor(private authenticationService: AuthenticationService,private router: Router) { }

  ngOnInit(): void {
    let getRole = setInterval(() => {
      if(this.authenticationService.getRole()) {
       clearInterval(getRole);
       if(this.authenticationService.getRole() == 'Supplier')
       {
         this.router.navigate(["/grid"], {
           queryParams: { reportName: "Supplier_Dispatch_Report"},
         });
       }
      }
   }, 100)
  }

}
