import { FormBase } from '../interfaces/formInterface';
import { UntypedFormArray } from '@angular/forms';
export class TextboxFieldArray extends FormBase<string> {
  controlType = 'Array';
  type: UntypedFormArray;


  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
  }
}
