export const planning_Stock = [
  {
    "field": "materialCode",
    "headerText": "Material",
    "allowFiltering": true,
    "width": "100",
    "filter": { "type": "Menu" }
  }
  ,
  {
    "field": "parentProductCode",
    "headerText": "Style",
    "allowFiltering": true,
    "width": "90",
    "filter": {
      "type": "Menu"
    }
  },
  {
    "field": "materialName",
    "headerText": "Description",
    "allowFiltering": false,
    "width": "150"
  }
  ,
  {
    "field": "imageUrl",
    "headerText": "Image",
    "allowFiltering": false,
    "width": "70",
    "image": true
  },
  {
    "field": "locationCode",
    "headerText": "Location",
    "allowFiltering": true,
    "width": "90",
    "filter": {
      "type": "CheckBox"
    },
  },

  {
    "field": "locationName",
    "headerText": "Name",
    "allowFiltering": false,
    "width": "90"
  },
  {
    "field": "status",
    "headerText": "Status",
    "allowFiltering": true,
    "width": "90",
    "filter": { "type": "CheckBox" },
    "state": true
  }
]
