import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { api } from '../properties/endpoints';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) { }

  getReportDetails(reportName) {
    const url = `${api.host}/reports/` + reportName;
    return this.http.get(url);
  }

  getBillMaterialAvailability(sku, locationCode) {
    if (sku != null && locationCode != null) {
      const url = `${api.host}/reports/bom/allocation/sku/${sku}?location=${locationCode}`;
      return this.http.get(url);
    } else {
      const url = `${api.host}/reports/bom/demand`;
      return this.http.get(url);
    }
  }

  getDashboardTails() {
    const url = `${api.host}/reports/get/tiles`
    return this.http.get(url);
  }
  getLocationFilter(url) {
    return this.http.get(url);
  }

  getURLFromChartId(chartId, selectedFilters) {
    const url = `${api.host}/reports/url/` + chartId;
    return this.http.post<any>(url, selectedFilters);
  }

  downLoadReport(chartId, dataSource, reportName) {
    const currentUser = this.authenticationService.getTokenDetails();
    const authToken = currentUser && currentUser.token;
    const urlAPI = `${api.host}/reports/download/${chartId}/datasource/${dataSource}?token=${authToken}`;


    this.http.get(urlAPI, { responseType: 'blob' as 'blob' })
      .subscribe(response => this.downLoadFile(response, reportName, "application/ms-excel"));
  }

  downLoadFile(data: any, fileName: any, type: string) {
    let dateObj = new Date();
    let month = String(dateObj.getMonth() + 1).padStart(2, '0');
    let day = String(dateObj.getDate()).padStart(2, '0');
    let year = dateObj.getFullYear();
    let output = day + '-' + month + '-' + year;
    fileName += '_' + output;
    const blob: any = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    let link = document.createElement("a");
    if (link.download !== undefined) {
      let url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

}
