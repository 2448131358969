import { Component, OnInit } from '@angular/core';
import { dynamicNormReview } from 'src/app/properties/dynamic-review-headers';
import { DynamicReviewService } from 'src/app/services/dynamic-review.service';
import { FormControlService } from 'src/app/services/form-control.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-dynamic-norm-review-organism',
  templateUrl: './dynamic-norm-review-organism.component.html',
  styleUrls: ['./dynamic-norm-review-organism.component.scss']
})
export class DynamicNormReviewOrganismComponent implements OnInit {
  gridData: any;
  dynamicHeaders: any;
  displayName: any;
  dynamicNormForm: any;
  today: Date = new Date();

  constructor(private dynamicReviewServices: DynamicReviewService, private formControlService: FormControlService,
    private authenticationservice: AuthenticationService, private snack: MatSnackBar) { }
  ngOnInit(): void {
    this.dynamicReviewServices.getDynamicReviewData().subscribe(res => {
      if (res) {
        this.displayName = res['displayName'];
        this.dynamicHeaders = dynamicNormReview;
        this.gridData = res['items'];
      }
      this.dynamicNormForm = this.formControlsForTextFields();
    })
  }

  actionEvent(event) {
    const user = this.authenticationservice.getUserName();
    let payload: {}
    payload = {
      id: event.id,
      updatedBy: user,
      status: event.status.toUpperCase()
    }
    if (event.status === 'MODIFIED') {
      payload = {
        id: event.id,
        updatedBy: user,
        newNormValue: event.newNormValue,
        status: event.status.toUpperCase(),
      }
    }
    this.dynamicReviewServices.updateSuggestions(payload).subscribe((res) => {
      if (res) {
        const message = `${payload['status']} successfully`;
        this.snack.open(message, 'close', {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
      }
    }, (error) => {
      const message = `Operation failed please try again later`;
      this.snack.open(message, 'close', {
        duration: 4000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }, () => {
      this.gridData = this.gridData.filter(item => item.id != event.id);
    })
  }
  private formControlsForTextFields() {
    const updatedData = this.gridData.map(item => ({
      ...item,
      controlType: 'String',
      value: item.customNormValue,
      identifier: item.id,
    }));
    const result = this.formControlService.toFormGroup(updatedData);
    return result;
  }
}
