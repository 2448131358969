import { DatePipe } from "@angular/common";
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { RowDataBoundEventArgs } from "@syncfusion/ej2-grids";
import {
  FilterSearchBeginEventArgs,
  FilterSettingsModel,
  GridComponent,
  TextWrapSettingsModel,
  DataResult,
  DataStateChangeEventArgs
} from "@syncfusion/ej2-angular-grids";
import { DialogComponent } from "@syncfusion/ej2-angular-popups";
import { EmitType } from "@syncfusion/ej2-base";
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import { ClickStreamAnalyticsService, EventType } from "src/app/services/clickstream-analytics-service";
import { SyncfusionService } from "src/app/services/syncfusion.service";
import { Observable } from "rxjs";
import { AppSettingService } from "src/app/services/app-setting.service";

@Component({
  selector: "app-syncfusion-grid",
  templateUrl: "./syncfusion-grid.component.html",
  styleUrls: ["./syncfusion-grid.component.scss"],
})
export class SyncfusionGridComponent implements OnInit {
  showImage: boolean = false;
  dialogVisible: boolean = false;
  infoData: any;
  ELEMENT_DATA: any;
  imgUrl: any;
  unavailableData: any;
  unavailableFlag: boolean;
  imgFlag: boolean;
  skuLineGraphFlag: boolean = false;
  chartData: any;
  labels: any;
  formData: any;
  addFlag: boolean;
  selected_bomdata: any;
  bomDialogVisible: boolean;
  pagination: boolean = true;
  public targetElement!: HTMLElement;
  public loadingIndicator;
  public toolbar: string[];
  public isInitial: Boolean = true;
  public pageSettings: object;
  public filterOption: FilterSettingsModel = { type: "Excel" };
  public datePipe = new DatePipe("en-US");
  @ViewChild("grid", { static: false })
  public grid: GridComponent;
  @ViewChild("ejDialog")
  ejDialog!: DialogComponent;
  @ViewChild("container", { read: ElementRef, static: true })
  container!: ElementRef;
  dataSourcePurchaseOrder = new MatTableDataSource<any>([]);
  @Input() isOptionRequired: boolean = false;
  @Input() reportName: any;
  @Input() dynamic_headers: any;
  @Input() count: any;
  @Input() data: any;
  @Input() dynamicNormForm: FormGroup;
  @Input() returnsAnalyzerOptions: boolean;
  @Input() freezeColumns: number = 2;
  @Output() navigateGrid = new EventEmitter<DataStateChangeEventArgs>();
  @Output() dataEmitted = new EventEmitter<string>();
  @Output() dataEmitForSalesEvent = new EventEmitter<{}>();
  @Output() dataEmitForDynamicNormReview = new EventEmitter<{}>();
  @Output() dataEmitForPOPUP = new EventEmitter<{}>();
  skuStockDetail: any;
  showOrderQTY: { code: string; shortage: number }[];
  objectQTYHeader: string[];
  userWorkOrders: number;
  createWorkOrdersFromUser: {
    materialCode: string;
    locationCode: string;
    qty: number;
    fulfilledQty?: string;
  };
  public editSettings;
  getConfigDataForSpecificRow: { pendingQty: any; piCreatedToday: number; piToBeCreated: number; };
  verifyToEnableIconColor: { id: string } = { id: '' }
  public wrapSettings: TextWrapSettingsModel = { wrapMode: 'Header' };
  customOperators = {
    stringOperator: [
      { value: 'startswith', text: 'Starts with' },
      { value: 'equal', text: 'Equal' },
      { value: 'contains', text: 'Contains' }
    ],
    numberOperator: [
      { value: 'equalto', text: 'Equal to' },
      { value: 'notequalto', text: 'Not Equal to' },
      { value: 'greaterthan', text: 'Greater than' },
      { value: 'lessthan', text: 'Less than' },
      { value: 'greaterthanorequal', text: 'Greater than Or Equal' },
      { value: 'lessthanorequal', text: 'Less than Or Equal' }
    ]
  };
  constructor(private syncfusion: SyncfusionService, private clickStream: ClickStreamAnalyticsService, private snack: MatSnackBar, private settingService: AppSettingService) { }
  ngOnInit() {
    this.editSettings = {
      allowEditing: true,
    };
    this.addFlag = true;
    if (this.dynamic_headers && this.data) {
      this.dynamic_headers.map((r) => {
        if (
          r.field == "imageUrl" ||
          r.field == "unavailableSkuCodes" ||
          r.field == "skuStockDetail"
        ) {
          if (this.data instanceof Array) {
            this.data.map((row) => {
              row.showImage = false;
              row.showSkus = false;
              row.showSkuDetails = false;
              row.showOrders = false;
            });
          } else if (this.data && this.data.result) {
            this.data.result.map((row) => {
              row.showImage = false;
              row.showSkus = false;
              row.showSkuDetails = false;
              row.showOrders = false;
            });
          }
        }
        if (r.field === "createWorkOrder") {
          if (this.data instanceof Array) {
            this.data.map((row) => {
              const suggestedWorkOrderTextBox =
                row.piToBeCreated - row.piCreatedToday;
              row.userWorkOrders =
                suggestedWorkOrderTextBox > 0 ? suggestedWorkOrderTextBox : 0;
              return row;
            });
          } else if (this.data && this.data.result) {
            this.data.result.map((row) => {
              const suggestedWorkOrderTextBox =
                row.piToBeCreated - row.piCreatedToday;
              row.userWorkOrders =
                suggestedWorkOrderTextBox > 0 ? suggestedWorkOrderTextBox : 0;
              return row;
            });
          }
        }
      });
    } else {
      // Handle the case where either this.dynamic_headers or this.data is undefined
      console.error("Dynamic headers or data is undefined");
    }
    let settings = this.settingService.getAppSetting('grid.pageSizes', [50, 100]);
    this.pageSettings = {
      pageSizes: settings,
      pageSize: settings[0],
      locale: "en-US"
    };
    // this.toolbar = ["ExcelExport", "CsvExport", "ColumnChooser"];
    this.updateToolbarBasedOnGridMode();
    this.loadingIndicator = { indicatorType: "Shimmer" };
    if (this.isOptionRequired) {
      this.pageSettings = {};
      this.toolbar = [];
      this.skuLineGraphFlag = true;
      this.pagination = false;
    }
    if (this.isOptionRequired) {
      this.pageSettings = {};
    }
    if (this.returnsAnalyzerOptions) {
      this.isOptionRequired = true;
    }
  }
  updateToolbarBasedOnGridMode(): void {
    const isRemote = this.settingService.isRemoteGridMode();
    this.toolbar = isRemote ? ['ExcelExport', 'ColumnChooser'] : ['ExcelExport', 'CsvExport', 'ColumnChooser'];
  }
  public dataStateChange(state: DataStateChangeEventArgs): void {
    this.navigateGrid.emit(state);
  }
  actionBegin(args: FilterSearchBeginEventArgs) {
    if (args.requestType != 'filterchoicerequest') {
      this.clickStream.publishGAEvent(EventType.GRID_COLUMN_ACTION, {
        actionType: args.requestType,
        value: args.columnName,
        section: this.reportName
      })
    }
    if (
      args.requestType === "filterchoicerequest" ||
      args.requestType === "filtersearchbegin"
    ) {
      args.filterChoiceCount = this.count;
    }
    if (args.requestType === "filterbeforeopen") {
      setTimeout(() => {
        const filterDialog = (args as any).filterModel?.dlgDiv;
        if (filterDialog) {
          const filterElement = filterDialog.querySelector('.e-autocomplete');
          if (filterElement && filterElement.ej2_instances) {
            filterElement.ej2_instances[0].actionBegin = (args) => {
              args.cancel = true;
            }
          };
        }
      }, 0);
      if(this.settingService.isRemoteGridMode()){
        if (args.filterModel['flMuiObj'] && args.filterModel['flMuiObj'].customFilterOperators) {
          args.filterModel['flMuiObj'].customFilterOperators.stringOperator = this.customOperators.stringOperator;
          args.filterModel['flMuiObj'].customFilterOperators.numberOperator = this.customOperators.numberOperator;
        }
        if(args.filterModel['cBox']){
          const div =  args.filterModel['cBox'];
          div.style.display = 'none';
        }
      }

    }
  }
  toolbarClick(args: ClickEventArgs): void {
    this.clickStream.publishGAEvent(EventType.GRID_COLUMN_ACTION, {
      actionType: args.item.text,
      section: this.reportName
    })
    switch (args.item.text) {
      case "Excel Export":
        // this.grid.excelExport(this.getExcelExportProperties());
        if(this.settingService.isRemoteGridMode()){
          this.triggerExcelExportApi();
        }
        else
        {
          this.grid.excelExport(this.getExcelExportProperties());
        }
        break;
      case "CSV Export":
        this.grid.csvExport(this.getCSVExportProperties());
        break;
    }
  }
  private triggerExcelExportApi(): void {
    this.syncfusion?.exportData$?.subscribe((exportData) => {
      if (exportData !== null && exportData?.headerContent && exportData?.pageData) {
        const headerContent = JSON.parse(exportData.headerContent);
        const columnsMapping = headerContent.reduce((acc: { [key: string]: string }, item: any) => {
          if (item.field && item.headerText) {
            acc[item.field] = item.headerText;
          }
          return acc;
        }, {});

        const data = {
          ...exportData.pageData,
          columns: columnsMapping,
          downloadEntity: false
        };
        this.syncfusion.exportDataToExcel(data,this.reportName).subscribe((response) => {
          if (response.status === 307) {
            this.grid.excelExport(this.getExcelExportProperties());
          } else {
            // Handle successful response
            this.snack.open("Download triggered Successfully", "close", {
              duration: 4000,
              horizontalPosition: "center",
              verticalPosition: "top",
            });
          }
        }, (error) => {
          console.error('Error in Excel Export:', error);
        });
      }else{
        this.grid.excelExport(this.getExcelExportProperties());
      }
    });
  }


  private getExcelExportProperties(): any {
    let currentDateTime = this.datePipe.transform(
      new Date(),
      "MM_dd_yy_hh:mm:ss"
    ); 
    return {
      fileName: this.reportName + "_" + currentDateTime + ".xlsx",
    };
  }
  private getCSVExportProperties(): any {
    let currentDateTime = this.datePipe.transform(
      new Date(),
      "MM_dd_yy_hh:mm:ss"
    );
    return {
      fileName: this.reportName + "_" + currentDateTime + ".csv",
    };
  }

  public initilaizeTarget: EmitType<object> = () => {
    this.targetElement = this.container.nativeElement.parentElement;
  };
  public onOpenDialog = (event: any, input): void => {
    if (input === "openPOQty") {
      this.infoData = event;
      this.syncfusion
        .getWithCache(
          event.materialCode,
          event.locationCode,
          event.supplierCode
        )
        .then((response) => {
          this.ELEMENT_DATA = response;
          this.dataSourcePurchaseOrder = new MatTableDataSource<any>(
            this.ELEMENT_DATA
          );
          this.dialogVisible = true;
        })
        .catch((error) => {
          this.dialogVisible = false;
        });
    } else if (input === "unavailable") {
      if (event.unavailableSkuCodes.includes(",")) {
        this.dialogVisible = true;
        this.unavailableFlag = true;
        this.unavailableData = event.unavailableSkuCodes.split(",");
      } else {
        this.unavailableData = event.unavailableSkuCodes;
      }
    } else if (input === "canOrderQty") {
      this.showOrderQTY = JSON.parse(event.shortage_json);
      this.objectQTYHeader = Object.keys(this.showOrderQTY);
      event.showOrders = true;
    } else {
      this.imgUrl = event.imageUrl;
      this.imgFlag = true;
      this.dialogVisible = true;
    }
  };
  public onOverlayClick: EmitType<object> = () => {
    this.infoData = [];
    this.dataSourcePurchaseOrder = new MatTableDataSource<any>([]);
    this.unavailableFlag = false;
    this.dialogVisible = false;
    this.bomDialogVisible = false;
    this.imgFlag = false;
    this.createWorkOrdersFromUser ? delete this.createWorkOrdersFromUser : '';
  };

  onRowSelected(event) {
    this.dataEmitted.emit(event);
  }
  closeImagePopup() {
    this.dialogVisible = false;
  }
  SKUs(event) {
    if (event?.unavailableSkuCodes?.includes(",")) {
      this.unavailableData = event.unavailableSkuCodes.split(",");
    } else if (event?.skuStockDetail) {
      this.skuStockDetail = JSON.parse(event.skuStockDetail);
    } else {
      this.unavailableData = event.unavailableSkuCodes;
    }
  }
  remove() {
    this.unavailableData = [];
    this.skuStockDetail = null;
  }
  showMore(data, column?) {
    if (this.skuLineGraphFlag) {
      this.dataEmitForSalesEvent.emit({ type: "sku", data: data });
    } else if (column) {
      this.dataEmitForSalesEvent.emit({ type: null, data: data });
    } else {
      this.dataEmitForSalesEvent.emit({ type: "product", data: data });
    }
  }

  action(actionType, controlId) {
    if (actionType === "update") {
      this.dataEmitForDynamicNormReview.emit({
        status: "MODIFIED",
        id: controlId,
        newNormValue: this.dynamicNormForm.get(controlId).value,
      });
    } else {
      this.dataEmitForDynamicNormReview.emit({
        status: actionType,
        id: controlId,
      });
    }
  }

  showBOMStatus(data) {
    this.bomDialogVisible = true;
    this.selected_bomdata = data;
  }
  handleConfirmation(data) {
    this.createWorkOrdersFromUser = {
      materialCode: data.materialCode,
      locationCode: data.locationCode,
      qty: +data.userWorkOrders,
    };

    this.getConfigDataForSpecificRow = {
      pendingQty: data.pendingQty,
      piCreatedToday: +data.piCreatedToday,
      piToBeCreated: +data.piToBeCreated
    }
    const noNeedPopUP = data.piToBeCreated >= +(Number(data.piCreatedToday) + Number(data.userWorkOrders));
    if (noNeedPopUP) {
      if (data.userWorkOrders > 0) {
        this.verifyToEnableIconColor.id = data.materialCode;
        this.handleCreateWorkOrders()
        setTimeout(() => {
          this.verifyToEnableIconColor.id = "";
        }, 1000);
      }
    }
    else {
      if (data.userWorkOrders > 0) {
        this.dialogVisible = true;
      }
    }

  }
  handleCreateWorkOrders() {
    this.syncfusion.createWorkOrders(this.createWorkOrdersFromUser).subscribe(
      (res) => {
        const material = this.createWorkOrdersFromUser.materialCode;
        const pendingQty = +this.createWorkOrdersFromUser.qty + this.getConfigDataForSpecificRow.pendingQty;
        const piCreatedToday = +this.createWorkOrdersFromUser.qty + this.getConfigDataForSpecificRow.piCreatedToday;
        const suggestedWorkOrderTextBox = this.getConfigDataForSpecificRow.piToBeCreated - piCreatedToday;
        const userWorkOrders = suggestedWorkOrderTextBox > 0 ? suggestedWorkOrderTextBox : 0;
        this.data.map(m => {
          if (m.materialCode === this.createWorkOrdersFromUser.materialCode) {
            m.userWorkOrders = userWorkOrders;
          }
          return m;
        })

        this.grid.setCellValue(material, 'piCreatedToday', piCreatedToday);
        this.grid.setCellValue(material, 'pendingQty', pendingQty);
        this.grid.setCellValue(material, 'createWorkOrder', userWorkOrders);
        const message = `Created Successfully`;
        this.snack.open(message, "close", {
          duration: 4000,
          horizontalPosition: "center",
          verticalPosition: "top",
        });
      },
      (error) => {
        const message = `${error.error.error}`;
        this.snack.open(message, "close", {
          duration: 4000,
          horizontalPosition: "center",
          verticalPosition: "top",
        });
      },
      () => {
        delete this.createWorkOrdersFromUser;
      }
    );
    this.dialogVisible = false;
  }

  @HostListener("document:keydown.escape", ["$event"])
  handleEscKey(event: KeyboardEvent) {
    if (this.dialogVisible) {
      this.dialogVisible = false;
    }
    if (this.bomDialogVisible) {
      this.bomDialogVisible = false;
    }
  }

  handlePOPUP(fieldData, header) {
    this.dataEmitForPOPUP.emit({ header, fieldData });
  }

  modifyName(name) {
    if (name == "qty") {
      return "Quantity";
    } else if (name == "code") {
      return "Order Number";
    } else if (name == "purchaseOrderDate") {
      return "Order Date";
    } else {
      return "Supplier";
    }
  }
  public rowDataBound(args: RowDataBoundEventArgs) {
    args.rowHeight = 48;
  }
}
