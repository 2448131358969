import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { EmitType } from "@syncfusion/ej2-base";

@Component({
  selector: "app-popup-reuse-organsim",
  templateUrl: "./popup-reuse-organsim.component.html",
  styleUrls: ["./popup-reuse-organsim.component.scss"],
})
export class POPUPReuseOrgansimComponent implements OnInit, OnChanges {
  ngOnChanges(changes: SimpleChanges): void {
    this.dialogVisible = this.showPOPUP;
  }
  ngOnInit(): void {
    this.dialogVisible = this.showPOPUP;
  }
  @Input() showPOPUP;
  @Input() fieldData;
  @Input() headerData;
  @Output() POPUPFeedback = new EventEmitter<boolean>();
  dialogVisible: boolean;

  public onOverlayClick: EmitType<object> = () => {
    this.dialogVisible = false;
    this.POPUPFeedback.emit(false);
  };

  @HostListener("document:keydown.escape", ["$event"])
  handleEscKey(event: KeyboardEvent) {
    if (this.dialogVisible) {
      this.dialogVisible = false;
      this.POPUPFeedback.emit(false);
    }
  }
  handlePOPUP(event) {
    this.dialogVisible = true;
  }
}
