import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FilterUiService } from 'src/app/services/filter-ui.service';
import { subHeadings } from "src/app/properties/map_sub_heading";
import { SyncfusionService } from 'src/app/services/syncfusion.service';
import { AppSettingService } from "src/app/services/app-setting.service";
import { DataResult, DataStateChangeEventArgs } from '@syncfusion/ej2-angular-grids';
import { SyncFusionExternalFilterBaseComponent } from '../sync-fusion-external-filter-organism/sync-fusion-external-filter-organism.component';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-production-intent-planning-organisms',
  templateUrl: './production-intent-planning-organisms.component.html',
  styleUrls: ['./production-intent-planning-organisms.component.scss']
})
export class ProductionIntentPlanningOrganismsComponent extends SyncFusionExternalFilterBaseComponent{
  
  constructor(protected filterServices: FilterUiService, protected route: ActivatedRoute, protected syncfusion: SyncfusionService,
    protected settingsService: AppSettingService) {
    super(filterServices,route,syncfusion,settingsService);
    this.reloadPage();
  }

  


}
