import { Injectable } from '@angular/core';
import {api} from '../properties/endpoints';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class DataProcessingService {

  constructor(private http: HttpClient) {
  }
  getUploadedBy(){
    const url=`${api.host}/integration/all`
    return this.http.get<any>(url)
  }
downloadTemplate(template){
  const url=`${api.host}/integration/template/download/${template}`
  this.http.get(url, { responseType: 'text' }).subscribe(
    (data: string) => {
      const blob = new Blob([data], { type: 'text/csv' });
      saveAs(blob, template+".csv");
    },
    (error) => {
      console.error('Error downloading the CSV file:', error);
    }
  );
}
  uploadFiles(file,user,type){
    const formData: FormData = new FormData();

    formData.append('file', file);
    formData.append('uploadedBy', user);
    formData.append('type',type)
    const url=`${api.host}/integration/upload`
    return this.http.post(url,formData, {responseType: 'text'})
  }

  downloadFile(filename: string): Observable<{ blob: Blob, filename: string }>  {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/octet-stream'
    });

    return this.http.get(`${api.host}/integration/download?filename=${filename}`, {
      headers: headers,
      responseType: 'blob'
    }).pipe(
      map((blob: Blob) => {
        return {
          blob: blob,
          filename: filename
        };
      })
    );


  }
}

